import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';

import MdmsApi from '../../../mdms_api';
import Theme from '../../../themes/index';

class OnBehalfOfMultiselectWidget extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      listedUsers: [],
    };
  }

  getUserOptions = () =>
    MdmsApi.secure_get('/forms/ccd_on_behalf_user_options')
      .then(response => response.json())
      .then(data => data);

  updateListedUsers = userList => {
    this.setState(
      {
        listedUsers: userList,
      },
      () =>
        this.props.onChange(this.stringEmailsTogether(this.state.listedUsers))
    );
  };

  stringEmailsTogether = userList => {
    const emails = userList.map(user => user.value);
    return emails.join(',');
  };

  render() {
    return (
      <AsyncSelect
        loadOptions={this.getUserOptions}
        defaultOptions
        styles={{
          control: (provided, state) => {
            const borderColor = state.isFocused
              ? Theme.colors.brand
              : '#585858';
            // if (!validated) borderColor = "#940420";

            return {
              ...provided,
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: `${borderColor} !important`,
              boxShadow: 'none',
              borderRadius: 0,
            };
          },
          valueContainer: provided => ({
            ...provided,
            padding: '10px 8px 9px',
            color: '#585858',
          }),
          indicatorsContainer: provided => ({
            ...provided,
            cursor: 'pointer',
          }),
          dropdownIndicator: provided => ({
            ...provided,
            width: 48,
          }),
          menu: provided => ({
            ...provided,
            borderRadius: 0,
            marginTop: 0,
            borderWidth: 0,
            boxShadow: 'none !important',
            filter: 'drop-shadow(0px 12px 15px rgba(0, 0, 0, 0.2))',
            zIndex: 9999999,
          }),
          menuList: provided => ({
            ...provided,
            padding: 0,
          }),
          option: (provided, state) => ({
            ...provided,
            color: '#000',
            opacity: state.isDisabled ? 0.5 : 1,
            cursor: state.isDisabled ? 'not-allowed' : 'pointer',
            padding: '12px 11px',
            backgroundColor: state.isSelected ? '#E6E6E6' : '#FFF',
            transition: 'all 0.2s',
            ':hover': {
              backgroundColor: '#f0f0f0',
            },
          }),
        }}
        isMulti
        placeholder={null}
        matchPos="any"
        ignoreCase
        value={this.state.listedUsers}
        simpleValue={false}
        joinValues={false}
        onChange={this.updateListedUsers}
      />
    );
  }
}

export default OnBehalfOfMultiselectWidget;
