import { css } from '@emotion/react';
import { useMemo } from 'react';

const Dots = (({ actions, reference, children, ...props }) => {
  const openCSS = useMemo(() => css`
    background-color: #585858;
    background-image: url(${ UI.Images.EllipsisWhite });
  `, []);

  const ellipsisCSS = useMemo(() => css`
    background-color: #FFFFFF;
    background-image: url(${ UI.Images.EllipsisGrey });
    background-position: center 2px;
    background-repeat: no-repeat;
    background-size: auto 9px;
    border-radius: 50%;
    border: 1px solid #585858;
    cursor: pointer;
    height: 16px;
    user-select: none;
    width: 16px;

    &:hover {
      background-color: #585858;
      background-image: url(${ UI.Images.EllipsisWhite });
    }
  `, []);

  const dropdownCSS = useMemo(() => css`
    left: auto;
    right: 0;

    > div {
      align-items: center;
      cursor: pointer;
      display: flex;
      gap: 8px;
      padding: 8px 16px;
    }
  `, []);

  return (
    <UI.Belt
      actions={ actions }
      reference={ reference }
      beltCSS={ dropdownCSS }
      openCSS={ openCSS }
      { ...props }
    >
      <div css={ ellipsisCSS }/>
      { children }
    </UI.Belt>
  );
});

export default Dots;
