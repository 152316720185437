import { define, ui, s } from '@owenscorning/pcb.alpha';
import createSizes from "../../../../OC/PageBuilder/utilities/sizes";
import styled from "@emotion/styled";
import { Screens } from "../../../../PageBuilder/editing/Viewport";
import Text from '../../Text/Parsing';

const Sizes = createSizes();

const BannerWrapper = styled.div`
display: flex;
justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const BannerContainer = styled.div`
  margin: 0;
  width: ${({ width }) => `${ width.Mobile}px` };
  @media (min-width: ${ Screens.Tablet }px) { width: ${ ({ width }) => `${ width.Tablet }px`}; }
  @media (min-width: ${ Screens.Desktop }px) { width: ${ ({ width }) => `${ width.Desktop }px`}; }
`;

export default define`Social Media Share Banner`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/SocialMediaShareBanner`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value: { channels = {}, content = {}, background = { color: 'dark' }, containerSettings = {size: 'xl', alignment: 'Left'} } = {} }) => {
    const { size, alignment } = containerSettings;
    const Width = Sizes[(size || 'xl')];

    return (
      <BannerWrapper alignment={alignment}>
        <BannerContainer size={size} width={Width}>
          <UI.Row back={{ dark: 'black', light: 'white' }[background.color]} padding-2 gap-2 center-xy>
            {content.text && <UI.Box><Text content={content.text} />:</UI.Box>}
            <UI.Row gap-3>
              {channels.facebook && <UI.Share.Facebook message={content.shareMessage} />}
              {channels.twitter && <UI.Share.Twitter message={content.shareMessage} />}
              {channels.linkedin && <UI.Share.Linkedin message={content.shareMessage} />}
              {channels.pinterest && <UI.Share.Pinterest message={content.shareMessage} />}
            </UI.Row>
          </UI.Row>
        </BannerContainer>
      </BannerWrapper>
    )
  },
  edit: {
    channels: ui`Form`.of({
      facebook: ui`Switch`({ label: 'Facebook', default: true }),
      twitter: ui`Switch`({ label: 'Twitter', default: true }),
      pinterest: ui`Switch`({ label: 'Pinterest', default: true }),
      linkedin: ui`Switch`({ label: 'Linkedin', default: true })
    })({ label: 'Select Social Media Channels' }),
    background: ui`Form`.of({
      color: ui`Choices`.of({
        dark: 'Black',
        light: 'White'
      })({
        label: 'Select the Background Color',
        default: 'dark'
      })
    })({ label: 'Background' }),
    content: ui`Form`.of({
      text: ui`Text`({ label: 'Main Heading', default: 'Share this page on' }),
      shareMessage: ui`Text`({ label: 'Default Share Message', default: `Check out what I found on ${PB_SITE_NAME}!` }),
      [s._]: ui`Tip`.of('The default share message is what will appear in the content share message when a user clicks to share on one of the social channels listed.')
    })({ label: 'Content' }),
    containerSettings: ui`Form`.of({
      size: ui`Choices`.of({
        'xl': 'XL',
        'l': 'Large',
        'm': 'Medium',
      })({
        label: 'Size',
        default: 'xl',
        mode: ui`Choices/Mode/Dropdown`
      }),
      alignment: ui`Choices`.of(['Left', 'Center'])({
        label: 'Alignment',
        default: 'Left'
      }),
    })({
      label: "Container"
    }),
  },
});
