import { css } from '@emotion/react';

export default css`
  font-family: OCRoboto, Roboto, sans-serif;
  font-feature-settings: 'liga' off, 'clig' off;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .rating-layout {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .rating-title {
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
  }

  .rating-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .rating-section_delimiter {
    display: none;
  }

  .rating-section_data {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .rating-values {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .rating-average-value {
    font-family: 'OCOswald', Oswald, 'OCRoboto', Roboto, sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 34px;
  }

  .rating-average-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .rating-button {
    text-transform: capitalize;
    border: none;
    color: #fff;
    background: #D40F7D;
    padding: 10px 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
  }

  .rating-button:hover {
    background: #9C0A64;
  }

  .rating-share {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .rating-share-description {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 0;
  }

  @media (width >= 768px) {
    .rating-section {
      flex-direction: row;
      gap: 0;
    }

    .rating-section_delimiter {
      display: block;
      width: 1px;
      margin: 0 40px;
    }

    .rating-section_delimiter::before {
      content: '';
      display: block;
      background: #BCBCBC;
      width: 1px;
      height: 80%;
      margin-top: 25px;
    }

    .rating-section_data {
      flex: 1 0 0;
    }

    .rating-share {
      flex: 1 0 0;
      justify-content: center;
    }
  }

  @media (width >= 1024px) {
    .rating-section {
      gap: 100px;
    }

    .rating-section_delimiter {
      display: none;
    }

    .rating-section_data {
      max-width: 360px;
    }
  }
}
`;
