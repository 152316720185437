import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { React, useContext } from 'react';

// Components
import LocatorContext from './context';
import IconMap from '../../../ComponentLibrary/icons/icon-map';
import LocationModalOpener from '../../../ComponentLibrary/oc-location-modal-opener';
import Tab from '../../../ComponentLibrary/oc-tab';
import TabList from '../../../ComponentLibrary/oc-tabs';
import { H3 } from '../../../ComponentLibrary/text-elements/oc-h';
import { interpolateString } from '../../../PageBuilder/helpers/interpolate';

// Styled Components
const HeadingContainer = styled.div`
  align-items: center;
  display: inline-flex;
  position: relative;
  width: 100%;

  @media screen and (min-width: 600px) {
    width: auto;
  }

  // Temp solution for mobile styling tabs
  + .tabs-list {
    @media screen and (max-width: 600px) {
      margin-top: 0.5em;
    }
  }
`;

const WrappedH3 = styled(H3)`
  margin-bottom: 0;
  margin-right: 0.5em;
`;

// Temp solution for mobile styling tabs; may need to revist after MVP
// when tabbed views (+ map) are ready to go live.
const WrappedTabs = styled(TabList)`
  margin-bottom: 0;
`;

const ContentHeader = ({
  count,
  activatePrimary,
  activateSecondary,
  filterScope,
}) => {
  const { valid, city, region, t, tabs, status, resultsHeading, zip } = useContext(
    LocatorContext
  );
  const { VIEWS } = tabs;

  const headingText = interpolateString(
    count === 1
      ? resultsHeading.singular
      : count > 1
      ? resultsHeading.plural
      : resultsHeading.none,
    { count, city, region, zip }
  );
  return (
    <>
      {status === 'loading' ? (
        <HeadingContainer>
          <WrappedH3 tag="h2" font="body">
            {t('generic_locator.loading')}
          </WrappedH3>
        </HeadingContainer>
      ) : (
        <HeadingContainer>
          {valid && (
            <WrappedH3 tag="h2" font="body">
              <span>{headingText}</span>
            </WrappedH3>
          )}
          {!valid && (
            <WrappedH3 tag="h2" font="body">
              {t('generic_locator.invalid_zip')}
            </WrappedH3>
          )}
          <LocationModalOpener
            ariaLabel="change zipcode"
            dataTrack="change-zip-open"
            dataTrackModalName="Region Selector"
          />
        </HeadingContainer>
      )}

      <WrappedTabs className="tabs-list">
        <Tab
          label={t('generic_locator.map_view')}
          active={tabs.activeView({ filterScope }) === VIEWS.MAP}
          onClick={() => activateSecondary()}
          iconImage={<IconMap width="13px" fill="currentColor" />}
        />
        <Tab
          label={t('generic_locator.list_view')}
          active={tabs.activeView({ filterScope }) === VIEWS.LIST}
          onClick={() => activatePrimary()}
          icon="List"
        />
      </WrappedTabs>
    </>
  );
};

ContentHeader.propTypes = {
  count: PropTypes.number.isRequired,
  activatePrimary: PropTypes.func.isRequired,
  activateSecondary: PropTypes.func.isRequired,
  filterScope: PropTypes.string,
};

ContentHeader.defaultProps = {
  filterScope: undefined,
};

export default ContentHeader;
