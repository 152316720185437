import { s, ui } from "@owenscorning/pcb.alpha";
import _ from "lodash";
import { constructField, deepResolveOfs } from "./Types/AttributeSet";
import { useEffect, useState } from "react";
import { filterEntity, isItemVisible } from "../../PageBuilder/helpers/content";
import cms_api from "../../../cms_api";

const constructChoicesFields = (basePath, baseLabel, filter) => {
  const thisFieldPath = [basePath, filter.field].filter(x=>x).join('.');
  const thisLabelPath = [baseLabel, filter[filter.type].ofLabel].filter(x=>x).join(': ');
  if (filter.type === 'choices') {
    const parameters = filter[filter.type]?.parameters || {};
    const component = {
      of: filter[filter.type].of,
      singular: [baseLabel, filter[filter.type].ofSingular].filter(x=>x).join(': '),
      plural: [baseLabel, filter[filter.type].ofPlural].filter(x=>x).join(': '),
      label: thisLabelPath,
      //sublabel: filter.sublabel,
      ...parameters
    };
    return { path: thisFieldPath, component }
  } else if (filter.type === 'form') {
    return (filter[filter.type].of || []).map(filter => constructChoicesFields(thisFieldPath, thisLabelPath, filter))
  }
  return null;
}

export const digChoicesFromData = async (basePath, data) => {
  const items = data?.contents?.items;

  if (!items) {
    console.error("Error: Invalid data structure");
    return {};
  }

  const resolvedItems = await deepResolveOfs(_.cloneDeep(items));
  const allChoices = resolvedItems.map(filter => constructChoicesFields(basePath, '', filter)).flat().filter(x => x);

  return Object.fromEntries(
    allChoices.map(choice =>
      ([
        choice.path,
        choice.component
      ])
    )
  );
}

export const digItemAttributesFromData = (data) => {
  const items = data?.contents?.items;

  if (!items) {
    console.error('Error: Invalid data structure');
    return {};
  }

  return items
    .filter(i => !i.templated)
    .map(i => ({ value: i.field, label: i.label }));
};

const formContentsFromData = async (data, connectedObject = null, controlled = false) => {
  const items = data?.items;

  if (!Array.isArray(items)) {
    console.error("Error: Invalid data structure");
    return null; // or handle the error in a way that makes sense for your application
  }

  const resolvedItems = await deepResolveOfs(_.cloneDeep(items));
  return Object.fromEntries(
    resolvedItems.map(filter =>
      ([
        filter.field,
        constructField(filter, connectedObject, controlled)
      ])
    )
  );
}

const getRouteFromPathParam = (path) => {
  if (!path) {
    return '/';
  }
  return path?.[0] === '/' ? path : `/${path}`;
};

const AttributeSet = (props) => {
  const [filterState, setFilterState] = useState({});

  let { query: { name, path } = {}, linked_object = null, controlled = false } = props || { query: {} };
  useEffect(() => {
    cms_api.get_single_contents({
      filter: {
        type: 'Cms::Content::AttributeSet',
        language_iso_code: Board.build.language,
        route: getRouteFromPathParam(path),
        name,
      },
      fields: {
        '*': 'contents',
      },
    })
      .then(r => {
        if (r)
          return formContentsFromData(filterEntity(r, isItemVisible), linked_object, controlled)
        return { name, error: 'not found' }
      })
      .then(r => setFilterState(r))
  }, [name, path]);

  return filterState ?
    filterState.error ?
      <Subschema>{{
        [s._]: ui`Tip`.of(`${filterState.name} could not be loaded: ${filterState.error}`)
      }}</Subschema> :
      <Subschema>{[
        ui`Form`.of(filterState),
        {}
      ]}</Subschema> :
    <Subschema>{{
      [s._]: ui`Tip`.of('Loading...')
    }}</Subschema>;

}

export default AttributeSet;
