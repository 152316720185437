import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from './oc-link';
import WrapperLink from './oc-wrapper-link';
import Theme from '../../themes/index';

const StyledLink = styled(WrapperLink)`
	display: inline-flex;
  text-decoration: none;
	justify-content: space-between;
	align-items: center;

  .wrapper-link:hover &,
	&:hover {
		text-decoration: none !important;
    color: ${Theme.colors.brand};
    cursor: pointer;
	}
`

const Chevron = styled.span`
  display: inline-flex;
  align-self: center;
  margin-left: 10px;
  transition: margin .35s;
  text-decoration: none;

  .wrapper-link:hover &,
  a:hover > & {
    margin-left: 25px;
    color: ${Theme.colors.brand};
  }

  &:after {
    font-family: "fontawesome";
    content: "\\f054";
    position: relative;
  }
`;

const ChevronLink = ({ url, children, ...props }) => {
  return (
    <StyledLink className="chevron-link" url={url} element="span" {...props}>{children} <Chevron/></StyledLink>
  )
};

export default ChevronLink;
