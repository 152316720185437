// Configuration needed in order to cloudinary correctly assemble the image
// shingleColor comes from the Shingle API (mdms)
// heroImage comes from the Page Builder (ColorPairing)
export const imageOverlayConfig = ({
  shingleColor = {},
  heroImage = {},
  textStyle = 'DearJoefour.otf',
}) => {
  const baseLayer = {
    mobile: {
      src: { file: heroImage?.mobile?.file },
      cropSettings: { width: 0.4, height: 0.7, gravity: 'center' },
    },
    tablet: {
      src: { file: heroImage?.tablet?.file },
    },
    desktop: {
      src: { file: heroImage?.desktop?.file },
    },
  };
  const textOverlay = {
    isText: true,
    txt: shingleColor?.name,
    gravity: 'north_west',
    position: { x: 435, y: 70 },
    fontSize: 32,
    textStyle,
  };
  const desktopOverlays = [
    {
      src: { file: shingleColor?.style_board_swatch_desktop },
      position: { x: -22, y: 120 },
      gravity: 'north',
      isUnderlay: true,
    },
    {
      ...textOverlay,
    },
  ];
  const tabletOverlays = [
    {
      src: { file: shingleColor?.style_board_swatch_tablet },
      position: { x: -20, y: 120 },
      gravity: 'north',
      isUnderlay: true,
    },
    {
      ...textOverlay,
      fontSize: shingleColor?.name?.length > 13 ? 34 : 42,
      position: { x: 435, y: 60 },
    },
  ];
  const mobileOverlays = [
    {
      src: { file: shingleColor?.style_board_swatch_mobile },
      height: 235,
      position: { x: 18, y: 121 },
      gravity: 'north',
      isUnderlay: true,
    },
    {
      ...textOverlay,
      fontSize: 32,
      position: { x: 190, y: 68 },
    },
  ];

  return {
    baseLayer,
    overlays: {
      desktop: desktopOverlays,
      tablet: tabletOverlays,
      mobile: mobileOverlays,
    },
  };
};
