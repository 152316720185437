import _ from 'lodash';

// TODO: we NEED to migrate old pages at rest and get rid of all this massaging
const migratePageArrayContents = ({ contents, metadata }) => {
  // we may have to move this later, but after PAR-516 first golive, we may have items saved in localStorage
  // that were saved directly, but we are trying to reload through the "read" method
  if (!Array.isArray(contents)) {
    return { contents, metadata };
  }

  // get rid of uuids
  const newMeta = _.cloneDeep(metadata) || {};
  newMeta.layouts = newMeta.layouts || {};
  newMeta.layouts.Header = newMeta.layouts.Header || {};
  newMeta.layouts.Header.modules = newMeta.layouts.Header.modules || {};
  if (newMeta.layouts.Header.modules.Hero) {
    delete newMeta.layouts.Header.modules.Hero.__uuid;
  }
  if (newMeta.layouts.Header.modules.StickyNav) {
    delete newMeta.layouts.Header.modules.StickyNav.__uuid;
    // figure out if sticky should be shown or not
    // if it's explicitly set, leave it
    // if undefined, it should be shown if any content, else hidden
    if (
      typeof newMeta.layouts.Header.modules.StickyNav.__hidden === 'undefined'
    ) {
      const shownUrl =
        (newMeta.layouts.Header.modules.StickyNav.cta?.url || '#') !== '#';
      const ctaIsModal =
        (newMeta.layouts.Header.modules.StickyNav.cta?.destination ||
          'link') === 'modal';
      const shownSections =
        (contents || []).filter(
          section => section?.sticky?.name && section?.sticky?.show
        ).length > 0;
      newMeta.layouts.Header.modules.StickyNav.__hidden = !(
        shownUrl ||
        ctaIsModal ||
        shownSections
      );
    }
  } else {
    newMeta.layouts.Header.modules.StickyNav = { __hidden: true };
  }

  return {
    contents: {
      layout: { Header: newMeta?.layouts?.Header?.modules },
      sections: (contents || []).map(section => ({
        ..._.omit(section, ['children', '__uuid']),
        modules: (section.children || []).map(module => ({
          type: module.component,
          __hidden: module.__hidden,
          __visibility: module.__visibility,
          data: _.omit(module, [
            'component',
            '__uuid',
            '__hidden',
            '__visibility',
          ]),
        })),
      })),
    },
    metadata: {
      ..._.omit(newMeta, 'layouts'),
    },
  };
};

export default migratePageArrayContents;
