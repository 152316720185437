import classNames from 'classnames';
import { useState } from 'react';

import Styles from './styles';
import BazaarvoiceTrustmark from '../icons/bazaarvoice-trustmark.svg';

const PopupIcon = () => {
  const [active, setActive] = useState(false);

  return (
    <div css={Styles}>
      <button
        type="button"
        className="bazaarvoice-popup-icon"
        onClick={() => setActive(!active)}
      >
        <img src={BazaarvoiceTrustmark} alt="Bazaarvoice Trustmark" />
      </button>
      <div
        className={classNames('bazaarvoice-popup-icon__tooltip', {
          'bazaarvoice-popup-icon__tooltip--active': active,
        })}
      >
        <button
          type="button"
          className="bazaarvoice-popup-icon__tooltip-close"
          onClick={() => setActive(false)}
        >
          x
        </button>
        <span className="bazaarvoice-popup-icon__tooltip-arrow" />
        <p className="bazaarvoice-popup-icon__tooltip-text">
          These reviews are managed by Bazaarvoice and comply with the
          Bazaarvoice Authenticity Policy, which is supported by anti-fraud
          technology and human analysis. Details at{' '}
          <a
            href="https://www.bazaarvoice.com/trustmark/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.bazaarvoice.com/trustmark/
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PopupIcon;
