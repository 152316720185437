import { css } from '@emotion/react';

export default ({ onClick, styling, children }) => (
  <div
    css={[
      css`
        display: inline-block;
        white-space: nowrap;
        user-select: none;
        width: 100%;

        &:hover {
          background-color: #E6E6E6;
        }
      `,
      ..._.castArray(styling)
    ]}
    onClick={ () => onClick?.() }
  >
    { children }
  </div>
);
