import styled from '@emotion/styled';
import _ from 'lodash';
import qs from 'qs';

import Theme from '../../../../../themes';
import { apiGet } from '../../../../PageBuilder/helpers/api';
import { formatDatetime } from '../../../../PageBuilder/helpers/time';
import { localeSort, parentLocales } from '../../../../location/locales';

let searchAbortController;

const StyledLink = styled.a`
  &:hover {
    color: ${Theme.colors.brand};
  }
`;

const commonSearchMethod = (
  type,
  currentLanguageOnly,
  considerCurrentLanguage,
  dataMapper
) => async ({ filter } = {}) => {
  // if (!filter) {
  //   return null;
  // }
  if (searchAbortController) searchAbortController.abort();
  searchAbortController = new AbortController();
  const query = {
    filter: {
      type: `Cms::Content::${type}`,
      published: true,
    },
    fields: {
      '*': 'name,metadata,language_summary,content_uuid',
    },
    q: filter,
    include: 'users',
  };
  // eslint-disable-next-line no-undef, prefer-destructuring
  const language = Board.build.language;
  if (currentLanguageOnly) {
    query.filter.language_iso_code = language;
  }
  const languages = Array.wrap(language).concat(parentLocales[language]);
  if (considerCurrentLanguage) {
    query.filter.language_iso_code = languages;
  }
  const response = await apiGet(
    // eslint-disable-next-line no-undef
    `/api/v2/cms/sites/${PB_SITE}/contents?${qs.stringify(query, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
    })}`,
    { signal: searchAbortController.signal }
  );
  const metaUsers = _.reduce(
    response.included?.filter(i => i.type === 'user'),
    (result, item) => {
      // eslint-disable-next-line no-param-reassign
      result[item.id] = item;
      return result;
    },
    {}
  );
  const output = [];
  response.data.forEach(result => {
    Array.wrap(dataMapper(result, response, metaUsers, languages)).forEach(r =>
      output.push(r)
    );
  });
  return output;
};

const GenericContentSearch = options => ({
  /* options:
    {
      search: {
        name: 'Attribute Sets',
        type: 'AttributeSet',
        currentLanguageOnly: true,
        preview: result => {}
      },
    }
  */
  Search: {
    name: options.search.name,
    meta: {},
    search: options.search.currentLanguageOnly
      ? commonSearchMethod(
          options.search.type,
          true,
          false,
          (r, response, metaUsers) => ({
            content_uuid: r.id,
            'Content Name': r.name,
            'Last Published': (
              <span>
                {metaUsers[
                  // eslint-disable-next-line no-undef
                  r.language_summary[Board.build.language]?.published_by
                ]?.name || 'Unknown'}
                <br />
                {formatDatetime(
                  // eslint-disable-next-line no-undef
                  r.language_summary[Board.build.language]?.published_at
                )}
              </span>
            ),
            // eslint-disable-next-line no-undef
            Location: r.language_summary[Board.build.language]?.published_route,
          })
        )
      : null,
    tabs: options.search.currentLanguageOnly
      ? null
      : [
          {
            label: 'Current Language',
            search: commonSearchMethod(
              options.search.type,
              false,
              true,
              (r, response, metaUsers, languages) => {
                const matchingLanguages = _.intersection(
                  languages,
                  Object.keys(r.language_summary).filter(
                    l => r.language_summary[l].published_at
                  )
                ).sort((a, b) => localeSort({ code: a }, { code: b }));
                const usedLanguage = _.last(matchingLanguages);
                return {
                  ref: { type: options.search.type, id: r.id },
                  Name: r.name,
                  id: r.id,
                  'Last Published': (
                    <span>
                      {metaUsers[r.language_summary[usedLanguage]?.published_by]
                        ?.name || 'Unknown'}
                      <br />
                      {formatDatetime(
                        r.language_summary[usedLanguage]?.published_at
                      )}
                    </span>
                  ),
                };
              }
            ),
          },
          {
            label: 'All Languages',
            search: commonSearchMethod(
              options.search.type,
              false,
              false,
              (r, response, metaUsers) => {
                const matchingLanguages = Object.keys(r.language_summary)
                  .filter(l => r.language_summary[l].published_at)
                  .sort((a, b) => localeSort({ code: a }, { code: b }));
                return matchingLanguages.map(l => ({
                  ref: { type: options.search.type, id: r.id, language: l },
                  Name: r.name,
                  Language: l,
                  id: r.id,
                  'Last Published': (
                    <span>
                      {metaUsers[r.language_summary[l]?.published_by]?.name ||
                        'Unknown'}
                      <br />
                      {formatDatetime(r.language_summary[l]?.published_at)}
                    </span>
                  ),
                }));
              }
            ),
          },
        ],
    preview:
      options.search.preview ||
      (result =>
        result && (
          <p>
            {result.name} |{' '}
            <StyledLink
              target="_blank"
              href={`/en-us/cms/contents/edit/${
                result.content_uuid
                // eslint-disable-next-line no-undef
              }/${Board.build.language.toLowerCase()}`}
            >
              Edit Master
            </StyledLink>
          </p>
        )),
  },
});

export default GenericContentSearch;
