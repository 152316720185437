// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'polyfills';

import ReactOnRails from 'react-on-rails';

import Switcher from "../components/location/Switcher";
import View from "../components/Builder/View";
import FlexBanner from "../components/OC/oc-flex-banner";
import ToggleFooter from "../components/ToggleFooter";
import Footer from "../components/OC/footer"
import RichText from '../components/OC/oc-rich-text';
import Text from '../components/OC/oc-text';

import CmsStore from "../components/PageBuilder/redux/store/cms_view";

import FeatureFlagAlert from "../components/FeatureFlag/FeatureFlagAlert";

ReactOnRails.registerStore({
  CmsStore
});

// This is how react_on_rails can see the named components in react_component helper
ReactOnRails.register({
  ToggleFooter,
  'Location::Switcher': Switcher,
  'FeatureFlag::Featureflagalert': FeatureFlagAlert,
  'Builder::View': View,
  'OC::Oc-flex-banner': FlexBanner,
  'OC::Footer': Footer,
  'OC::Richtext': RichText,
  'OC::Text': Text,
});

