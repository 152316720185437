import { useRef } from 'react';
import styled from '@emotion/styled'
import Theme from '../../themes';

import { useAccessibleInteractivity } from "../../hooks";

const TabButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #6D6D6D;
  color: #6D6D6D;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  justify-content: center;
  min-width: 100px;
  padding: 8px;

  ${props => props.icon && `
    &:before {
      content: '\\f00b';
      font-family: 'fontawesome';
      padding-right: 8px;
    }
  `}

  &:hover {
    background-color: ${Theme.colors.brand};
    color: #FFFFFF;
  }

  &:focus, &:focus-visible {
    outline: #D40F7D solid 2px;
    outline-offset: 0;
  }

  &:focus:not(:focus-visible) {
    outline: none !important;
  }

  &:focus-visible {
    z-index: 1;
  }

  &.active {
    background: #FFFFFF;
    border-bottom: 2px solid ${Theme.colors.brand};
    color: ${Theme.colors.brand};
    padding-bottom: 5px;
  }

  span {
    width: 100%;
  }
`;

const Icon = styled.i`
  margin-right: 8px;
`;

const Tab = ({ label, active, onClick, icon, iconImage }) => {
  const tabButton = useRef();
  useAccessibleInteractivity(tabButton, onClick);

  return (
    <TabButton
      aria-checked={ active }
      className={active ? 'active' : ''}
      data-track="view-change"
      data-track-tab-name={ label }
      data-track-view={ label }
      icon={icon}
      onClick={ onClick }
      ref={ tabButton }
      role="tab"
    >
      { iconImage && <Icon>{iconImage}</Icon> } { label }
    </TabButton>
  );
}

export default Tab;
