import { define, select, ui, when } from '@owenscorning/pcb.alpha';
import { apiPut } from '../../PageBuilder/helpers/api';
import styled from '@emotion/styled';

// Styled Components
const ModalBody = styled.div`
  display: inline-block;
  padding-top: 14px;
  position: relative;
  width: 100%;
`;

const ModalCopy = styled.p`
  margin-bottom: 8px;
`;

const ConfirmationModalBody = () => (
  <ModalBody>
    <UI.AlertMessage title="Warning" message="By connecting this PDP to a PIM product be aware that some of the current PDP content will be overwritten, and current unsaved changes will be lost." type="other" />
    <ModalCopy>Are you sure you want to connect to PIM data?</ModalCopy>
  </ModalBody>
);

const DisconnectionModalBody = () => (
  <ModalBody>
    <UI.AlertMessage title="Warning" message="By disconnecting this product from PIM, any content that is pulling in directly from the PIM system will no longer populate in the PDP." type="other" />
    <ModalCopy>Do you want to disconnect?</ModalCopy>
  </ModalBody>
);

const reloadWindow = () => {
  localStorage.setItem("preventAutosaveRestore", true);
  window.skipUnloadWarning = true;
  Board.reset();
};

const ConfirmationModal = {
  title: "PIM Product Connection",
  body: ConfirmationModalBody,
  buttons: {
    Confirm: () => {
      let contentId = Board.Self.props.content_id;
      let pimValues = Board.Value.columns.sidebar.settings.pimConnection;
      let params = {
        linked_external_id: pimValues.linked_external_id,
        linked_source: pimValues.linked_source
      };

      apiPut(`/api/v2/cms/sites/${PB_SITE}/contents/${contentId}`, params).then(response => {
        reloadWindow();
      });
    },
    Cancel: () => Board.modal.close()
  }
};

const DisconnectionModal = {
  title: "PIM Product Connection",
  body: DisconnectionModalBody,
  buttons: {
    Confirm: () => {
      let contentId = Board.Self.props.content_id;
      let params = { linked_external_id: null, linked_source: null };

      apiPut(`/api/v2/cms/sites/${PB_SITE}/contents/${contentId}`, params).then(response => {
        reloadWindow();
      });
    },
    Cancel: () => Board.modal.close()
  }
};

export default define`PimConnection`('0.0.1')({
  view: (props) => (
    <></>
  ),
  edit: ({ label, pimSource }) => {
    return (
      <UI.Form label={label}>{{
        linked_product: ui`Build/LinkPIM`({
          label: false,
          language: select`../language`,
          onChangeProduct: (product, path) => {
            Board.Change(product?.name || "", _.concat(_.initial(path), 'name'));
          },
          pimSource: pimSource,
          product: when`~linked_object`.is.present.then(select`~linked_object`),
          displayChangeLinks: when`~linked_object`.is.present.then(false).otherwise(true)
        }),
        linked_source: ui`Hidden`({
          noPadding: true,
          value: when`../linked_product`.is.present.then(pimSource).otherwise(undefined)
        }),
        linked_external_id: ui`Hidden`({
          noPadding: true,
          value: when`../linked_product`.is.present.then(select`../linked_product/id`).otherwise(undefined)
        }),
        confirmConnection: when`../linked_product`.is.present.and.when`~linked_object`.isnt.present.then([
          () => <UI.CTA
            text="Connect"
            onClick={() => Board.modal.open(ConfirmationModal)}
          />,
          { label: false }
        ]),
        removeConnection: when`~linked_object`.is.present.then([
          () => <UI.CTA
            text="Disconnect"
            onClick={() => Board.modal.open(DisconnectionModal)}
          />,
          { label: false }
        ]),
      }}</UI.Form>
    );
  }
});
