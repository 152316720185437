import React from 'react';

import PfcItem from './helpers/PfcItem';
// eslint-disable-next-line import/no-cycle
import ProductItemList from './helpers/ProductItemList';
import handleExternalUrl from './helpers/handle_external_url';
import pathToContent from './helpers/path_to_content';
import useReference from '../../../../hooks/use_reference';
import { connectLocation } from '../../../location/LocationConnectors';

const getDocuments = mediaObjData => {
  if (!mediaObjData) return [];

  let documents = [];
  if (mediaObjData.type?.source === 'data') {
    documents = mediaObjData.data?.parameters?.documents?.items || [];
  } else if (mediaObjData.type?.source === 'custom') {
    documents = (mediaObjData.mediaObjects || []).map(
      mediaObj =>
        mediaObj?.text?.ops?.find(
          op => typeof op.insert === 'object' && op.insert.document
        )?.insert?.document
    );
  }

  return documents
    .filter(Boolean)
    .map(doc => {
      const { results } = useReference(doc?.document);
      return { ...results };
    })
    .filter(
      doc =>
        doc.binary_url && doc.title?.toLowerCase()?.includes('design listing')
    )[0];
};

const parseToNumerical = textCollection => {
  const hourMapping = {
    'one-hour': 1,
    'two-hours': 2,
    'three-hours': 3,
  };
  return textCollection.map(text => hourMapping[text] || null).filter(Boolean);
};

const ProductListItem = connectLocation(({ ...product }) => {
  const { metadata, contents } = product;
  const generalContent = metadata?.settings?.general || {};
  const teaserCopy =
    generalContent.long_description || generalContent.short_description;
  const availability = metadata?.settings?.availability || {};
  const productUrl =
    availability.status === 'external'
      ? handleExternalUrl(availability.externalUrl)
      : pathToContent(product);

  const documentSection = contents.sections.find(
    section => section?.details?.name?.toLowerCase() === 'documents'
  );

  const document =
    // eslint-disable-next-line no-underscore-dangle
    documentSection && !documentSection.__hidden
      ? getDocuments(documentSection.modules?.[0]?.data)
      : null;

  const smokeSealantComponent = (
    metadata?.settings?.attributes?.['smoke-sealant-component'] || []
  ).map(item => item.toUpperCase());

  const hourlyFComponent = parseToNumerical(
    metadata?.settings?.attributes?.['hourly-f-rating'] || []
  );

  return (
    <PfcItem
      key={product.uid}
      heading={generalContent.proper_name}
      text={teaserCopy}
      url={productUrl}
      document={document}
      smokeSealantComponent={smokeSealantComponent}
      hourlyFComponent={hourlyFComponent}
    />
  );
});

export default ProductItemList({
  common: {
    type: 'Product::PfcSelector',
  },
  search: {
    name: 'PFC Selector Products',
    preview: result => ({
      heading: result.metadata?.settings?.general?.proper_name || result.name,
    }),
  },
  list: {
    availableIn: ['www.owenscorning.com'],
    name: 'Products - PFC Selector',
    attributeSet: {
      name: 'PFC Selector Attributes',
      path: '/',
    },
    dataset: 'products_pfc',
    view: ProductListItem,
    enableSearch: true,
  },
});
