import styled from '@emotion/styled';
import { useState, useRef } from 'react';

import { useAccessibleInteractivity } from '../../hooks';
import { FilterHeading } from './text-elements/filters';
import { H2 } from './text-elements/oc-h';
import Theme from '../../themes';
import Text from '../OC/PageBuilder/utilities/Text';

const ContentGroupContainer = styled.div`
  border-top: 1px solid #000000;
  display: inline-block;
  padding-bottom: 2em;
  padding-top: 2em;
  position: relative;
  width: 100%;
`;

const Label = styled.div`
  align-items: center;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  position: relative;

  &:hover {
    cursor: ${props => (props.collapsible ? 'pointer' : 'default')};
  }

  &:focus {
    outline: ${props =>
      props.collapsible ? `${Theme.colors.brand} solid 2px` : '0'};
    outline-offset: 0;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: ${props =>
      props.collapsible ? `${Theme.colors.brand} solid 2px` : '0'};
    outline-offset: 0;
  }
`;

const H2Label = H2.withComponent('label');
const LargeLabel = styled(H2Label)`
  margin-bottom: 10px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 15px;
  }
`;

const Chevron = styled.span`
  display: inline-block;
  position: relative;
  min-width: 1.125em;
  line-height: 1;
  min-height: 0.75em;

  &:before {
    font-family: 'fontawesome';
    content: '\\f054';
    font-size: 1.125em;
    left: 3px;
    line-height: 1;
    position: absolute;
    top: -3px;
    transform: ${props =>
      props.collapsed ? 'rotate(90deg)' : 'rotate(-90deg)'};
  }
`;

const Content = styled.div`
  display: ${props => (props.collapsed ? 'none' : 'inline-block')};
  padding-top: 1em;
  position: relative;
  width: 100%;
`;

const ContentGroupDropdown = ({
  collapsible = true,
  collapsed = false,
  labelSizeLg = false,
  label,
  labelFor,
  children,
}) => {
  const contentDropdown = useRef();
  const [expanded, setExpanded] = useState(collapsed !== true);

  const toggleDropdown = () => setExpanded(!expanded);

  if (collapsible) {
    useAccessibleInteractivity(contentDropdown, toggleDropdown);
  }

  return (
    <ContentGroupContainer>
      <Label
        aria-expanded={expanded ? 'true' : 'false'}
        collapsible={collapsible}
        ref={contentDropdown}
        role="button"
        tabIndex={collapsible ? 0 : -1}
      >
        {labelSizeLg ? (
          <LargeLabel font="body" for={labelFor}>
            <Text content={label} />
          </LargeLabel>
        ) : (
          <FilterHeading for={labelFor}>
            <Text content={label} />
          </FilterHeading>
        )}

        {collapsible && (
          <Chevron collapsed={expanded === false ? (collapsed = true) : null} />
        )}
      </Label>

      <Content collapsed={expanded === false ? (collapsed = true) : null}>
        {children}
      </Content>
    </ContentGroupContainer>
  );
};

export default ContentGroupDropdown;
