import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import PropTypes from 'prop-types';

const TabListContainer = styled.div`
  position: relative;

  ${props => (props.tabSize != 'small' && props.tabCount > 4) && css`
    -webkit-mask-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,1) 18% );
    mask-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,1) 18% );
  `};
`;

const TabList = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  justify-content: ${props => (props.tabSize != 'small' && props.tabCount > 4) ? 'left' : props.tabsAlign == 'center' ? 'center' : props.tabsAlign == 'left' ? 'left' : 'right' };
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  @media(min-width: 768px) {
    justify-content: ${props => props.tabsAlign == 'center' ? 'center' : props.tabsAlign == 'left' ? 'left' : 'right'};
  }
`;

const TabGroup = ({ tabsAlign='center', children, className, tabSize, tabCount }) => (
  <TabListContainer tabSize={tabSize} tabCount={tabCount} >
    <TabList tabsAlign={tabsAlign} tabCount={tabCount} className={className} role="tablist" aria-label="Choose your view">
      { children }
    </TabList>
  </TabListContainer>
)

export default TabGroup;

TabGroup.propTypes = {
  /** You can align your tabs left, center or right on the page. Default is center. */
  tabsAlign: PropTypes.string,
  /** Set the content of the Tab Group */
  children: PropTypes.any,
  /** Set the class name of the Tab Group */
  className: PropTypes.string
}