import { Component, createRef } from 'react';
import { jsx, css } from '@emotion/react';
import styled from "@emotion/styled";

import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import Keyboard from './Keyboard';
import Blots from './Blots';
import Theme from './Theme';

import Editor, { Quill } from 'react-quill';
import Actions from './Actions';
import PlainClipboard from "./PlainClipboard";

import { ui, define, when } from '@owenscorning/pcb.alpha';

import DeltaRenderer from '../../../../../helpers/delta_renderer';

const Delta = Quill.import('delta');

const Wrapper = styled.div`
  ${ (props) => props.inline ? css` p { margin: 0; } ` : ( props.singular ? css`` : css` margin: 0.96em 0; ` ) }
  .ql-container {
    background-color: transparent !important;
    border: none !important;
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
  }

  .ql-editor {
    font-size: inherit !important;
    line-height: inherit !important;
    max-height: none;
    overflow-y: visible !important;
    padding: 0 !important;
  }

  .ql-editor > * {
    cursor: default;
  }
`;

export const isDeltaEmpty = (delta) => typeof(delta) === 'undefined' ? true : delta?.ops?.reduce((empty, op) => (
  ( _.isString(op?.insert) && op.insert.trim().replace('↵', '').replace('\n', '').replace('\\n', '') != '' ) ||
  ( !_.isString(op?.insert) && !op?.insert?.breaker )
  ? false : empty
), true);

class Edit extends Component {
  constructor(props) {
    super(props);

    const editor = this;

    this.ref = createRef();
    this.identifier = uuid();

    this.formats = [
      'bold',
      'italic',
      'list',
      'indent',
      'link',
      'script',
      'pink',
      ...Blots.Inlines.map((blot) => blot.blotName),
      ...Blots.Blocks.map((blot) => blot.blotName),
      ...Blots.Embeds.map((blot) => blot.blotName),
      ...Blots.BlockEmbeds.map((blot) => blot.blotName)
    ];

    this.modules = {
      toolbar: {
        container: `#toolbar-${ this.identifier }`,
        handlers: {
          document: function() {
            editor.props.Board.modal.open(editor.modals.document, this.quill);
          },
          ...Actions
        }
      },
      ...Keyboard
    };

    this.tools = props?.tools || [
      'styles',
      'spacer',
      'bold',
      'italic',
      'superscript',
      'subscript',
      'pink',
      'spacer',
      'ul',
      'ol',
      'unindent',
      'indent',
      'lists',
      'spacer',
      'link',
      'document'
    ];

    this.modals = {
      document: {
        __id: 'RichTextDocumentSelector',
        title: 'Add Document Link',
        body: ui`Form`.of({
          selection: ui`Data/Parameters`.of('RichText')({ dataset: ['www.owenscorning.com', 'www.vidawool.com'].includes(PB_SITE) ? 'documents' : 'document_builder', unwrapped: true }),
          button: ui`CTA|view`.of('Submit')({
            onClick: () => {
              const value = Board.Value.modals['RichTextDocumentSelector'];
              if (value && value.selection) {
                const quill = Board.modal.reference;
                const range = quill.getSelection(true);
                quill.insertEmbed(range.index, ['www.owenscorning.com', 'www.vidawool.com'].includes(PB_SITE) ? 'document' : 'document_builder', value.selection, Quill.sources.USER);
                quill.setSelection(range.index + 1, Quill.sources.SILENT);
              }
              editor.props.Board.modal.close();
            },
            visible: when`../selection/document`.is.present()
          })
        }),
      }
    };
  }

  get controlled() { return this.props.controlled; }

  componentDidMount() { if(this.props.startSelected) this.ref.current.editor.setSelection(0, 999999); }

  render() {
    const { bare, Toolbar=UI.Text.Rich.Toolbar } = this.props;

    let disabled = this.props?.disabled;
    let value = this.props?.value;
    if (value && value instanceof Delta) value = { ops: value.ops };

    if (value && !_.isPlainObject(value)) {
      let blocks = null;
      try { blocks = JSON.parse(value)?.blocks; }
      catch(error) { if (_.isString(value)) value = { ops: [ { insert: value } ] }; }
      if (blocks) value = blocks.map((block) => ({ insert: `${ block.text }\n` }));
    }

    let attributes = {}
    if (this.controlled) {
      attributes.value = value
    }

    return (
      <div css={ [Theme.base, Theme.list, !bare && Theme.full] } >
        { Toolbar && <Toolbar
          identifier={ this.identifier }
          tools={ this.tools }
        /> }
        <div css={[
          this.props?.inline && css` p { margin: 0; } `,
          disabled && css` .ql-editor { background-color: rgba(239, 239, 239, 0.3); } `
        ]}>
          <Editor
            ref={ this.ref }
            theme={ null }
            modules={ this.modules }
            formats={ this.formats }
            { ...attributes }
            defaultValue={ value }
            onChange={ (content, delta, source, editor) => {
              if (!this.throttle) this.throttle = setTimeout(
                () => { this.throttle = null; this.props.onChange?.(editor.getContents()); },
                UI.Text.Throttle
              );
            } }
            readOnly={disabled}
          />
        </div>
      </div>
    );
  }
};

class View extends Component {
  constructor(props) {
    super(props);
    this.delta = this.delta.bind(this);
    this.draft = this.draft.bind(this);
    this.html = this.html.bind(this);
  }

  get content() { return this.props.content; }
  get inline() { return this.props.inline; }
  get singular() { return this.props.singular; }

  delta(content) {
    if (!_.isPlainObject(content)) return null;
    if (isDeltaEmpty(content)) return <></>;
    return <>
      <Wrapper singular={ this.singular } inline={ this.inline } css={ [Theme.base, Theme.list] }>
        <UI.Text.Parsing content={ new DeltaRenderer(content).render() } />
      </Wrapper>
    </>;
  }

  draft(content) {
    try {
      const parsedContent = JSON.parse(content);
      if (_.isPlainObject(parsedContent)) { return <DraftText content={ content } inline={ this.inline } />; }
    } catch(e) { }
    return null;
  }

  html(content) {
    if (!_.isString(content)) return false;
    return (
      <Wrapper singular={ this.singular } inline={ this.inline } css={ [Theme.base, Theme.list] }>
        <UI.Text.Parsing content={ (content || '').match(/<body[^>]*?>([\s\S]*)<\/body>/)?.[1] || content || '' } />
      </Wrapper>
    );
  }

  render() {
    return _.reduce(
      [ this.delta, this.draft, this.html ],
      (result, format) => {
        if (result) return result;
        return format(this.content);
      },
    null);
  }
};

export default define`Rich`('0.0.1')({
  edit: (props) => <Edit { ...props } />,
  view: (props) => <View { ...props } />
});
