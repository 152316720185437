import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import Text from './oc-text';
import Theme from '../../themes/index';
import { HeadingContext } from '../Builder/Library/Section';

/*
  Heading components are named for their visual appearance, and will default to using the same tag for their actual HTML output.
  * tag: [h1 - h6] - allows override of HTML tag used in output
  * font: [body, heading] - specifies whether body or heading font should be used

  Heading levels must be imported individually, eg:
  import { h2, h3 } from './oc-h';
*/

const generateInternalComponent = defaultTag => {
  const component = React.forwardRef(
    ({ tag = defaultTag, id, className, prehead, children, dangerouslySetInnerHTML }, ref) => {
      const Tag = tag;
      if (children && dangerouslySetInnerHTML) {
        console.error(
          'You cannot use both the "children" and "dangerouslySetInnerHTML" props on a Heading component'
        );
      }
      if (dangerouslySetInnerHTML) {
        return (
          <Tag
            id={id}
            ref={ref}
            className={className}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
          />
        );
      }
      return (
        <Tag id={id} ref={ref} className={className}>
          {prehead && (
            <Prehead className="prehead">
              <Text content={prehead} />
            </Prehead>
          )}
          {children}
        </Tag>
      );
    }
  );
  component.displayName = `Internal${defaultTag.toUpperCase()}`;
  return component;
};

const preheadMobile = props => css`
  font-size: ${props.font === 'body' ? '12px' : '14px'};
  line-height: ${props.font === 'body' ? '14px' : '16px'};
  text-transform: ${props.font === 'body' ? 'none' : 'uppercase'};
`;

const preheadDesktop = props => css`
  font-size: ${props.font === 'body' ? '14px' : '16px'};
  line-height: ${props.font === 'body' ? '16px' : '18px'};
  text-transform: ${props.font === 'body' ? 'none' : 'uppercase'};
`;

const Prehead = styled.span`
  color: #333;
  font-weight: normal;
  font-family: ${Theme.fonts.body};
  margin-bottom: 4px;
  letter-spacing: 0;
`;

const StyledH1 = styled(generateInternalComponent('h1'))`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${props =>
      props.font === 'body' ? Theme.fonts.body : Theme.fonts.heading};
    text-transform: ${Theme.text.headingTextTransform};

    &.tier3 {
      ${Prehead} {
        ${preheadMobile}
      };

      @media (min-width: 1200px) {
        ${Prehead} {
          ${preheadDesktop}
        };
      }
    }

    .highlight {
      color: ${Theme.colors.brand};
    }
  }
`;

const StyledH2 = styled(generateInternalComponent('h2'))`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${props =>
      props.font === 'body' ? Theme.fonts.body : Theme.fonts.heading};
    font-weight: ${props => (props.font === 'body' ? 700 : 500)};
    text-transform: ${props =>
      props.font === 'body' ? 'none' : Theme.text.headingTextTransform};
    line-height: ${props => (props.font === 'body' ? '30px' : '34px')};
    font-size: ${props => (props.font === 'body' ? '30px' : '32px')};

    ${Prehead} {
      ${preheadMobile}
    };

    @media (min-width: 1200px) {
      line-height: ${props => (props.font === 'body' ? '32px' : '42px')};
      font-size: ${props => (props.font === 'body' ? '32px' : '40px')};

      ${Prehead} {
        ${preheadDesktop}
      };
    }
  }

  html:not([lang^='en']) & {
    font-size: ${props => (props.font === 'body' ? '21px' : '28px')};
    line-height: ${props => (props.font === 'body' ? '24px' : '36px')};

    @media (min-width: 1200px) {
      font-size: ${props => (props.font === 'body' ? '24px' : '34px')};
      line-height: ${props => (props.font === 'body' ? '26px' : '42px')};
    }
  }
`;

const StyledH3 = styled(generateInternalComponent('h3'))`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${props =>
      props.font === 'body' ? Theme.fonts.body : Theme.fonts.heading};
    font-weight: ${props => (props.font === 'body' ? 700 : 500)};
    text-transform: ${props =>
      props.font === 'body' ? 'none' : Theme.text.headingTextTransform};
    line-height: ${props => (props.font === 'body' ? '24px' : '26px')};
    font-size: 24px;

    ${Prehead} {
      ${preheadMobile}
    };

    @media (min-width: 1200px) {
      line-height: ${props => (props.font === 'body' ? '28px' : '30px')};
      font-size: 28px;

      ${Prehead} {
        ${preheadDesktop}
      };
    }
  }

  html:not([lang^='en']) & {
    line-height: ${props => (props.font === 'body' ? '22px' : '30px')};

    @media (min-width: 1200px) {
      font-size: ${props => (props.font === 'body' ? '21px' : '26px')};
      line-height: ${props => (props.font === 'body' ? '23px' : '30px')};
    }
  }
`;

const StyledH4 = styled(generateInternalComponent('h4'))`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${Theme.fonts.body};
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 5px;
    font-weight: 700;
    color: black;

    ${Prehead} {
      ${preheadMobile}
    };

    @media (min-width: 1200px) {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 10px;

      ${Prehead} {
        ${preheadDesktop}
      };
    }
  }
`;

const StyledH5 = styled(generateInternalComponent('h5'))`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${Theme.fonts.body};
    color: black;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: none;

    @media (min-width: 1200px) {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

const StyledH6 = styled(generateInternalComponent('h6'))`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${Theme.fonts.body};
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: black;
    font-size: 16px;
    line-height: 16px;
  }
`;

const styledComponents = {
  1: StyledH1,
  2: StyledH2,
  3: StyledH3,
  4: StyledH4,
  5: StyledH5,
  6: StyledH6,
};

const generateAdjustedComponent = base => {
  const component = React.forwardRef(({ tag, ...props }, ref) => {
    const tagLevel = tag ? Number(tag[1]) : base;
    if (ref) {
      // eslint-disable-next-line no-param-reassign
      props = { ...props, ref };
    }
    return (
      <HeadingContext.Consumer>
        {({ headingInUse } = {}) => {
          const headingInUseTagNumber =
            headingInUse === 'h2' ? 2 : headingInUse === 'h3' ? 3 : 0;
          const Component = styledComponents[base];
          const tagNumber =
            tagLevel <= headingInUseTagNumber
              ? headingInUseTagNumber + 1
              : tagLevel;
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Component tag={`h${tagNumber}`} {...props} />;
        }}
      </HeadingContext.Consumer>
    );
  });
  component.displayName = `H${base}`;
  return component;
};

export const H1 = generateAdjustedComponent(1);
export const H2 = generateAdjustedComponent(2);
export const H3 = generateAdjustedComponent(3);
export const H4 = generateAdjustedComponent(4);
export const H5 = generateAdjustedComponent(5);
export const H6 = generateAdjustedComponent(6);
