import { css } from '@emotion/react';

export default css`
  position: relative;
  width: 100%;

  .bazaarvoice-popup-icon {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: fit-content;
  }

  .bazaarvoice-popup-icon__tooltip {
    display: none;
    position: absolute;
    bottom: -60px;
    left: 140px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding: 16px;
    width: 350px;
    height: fit-content;
    z-index: 100;

    @media (max-width: 768px) {
      width: 100%;
      top: 65px;
      bottom: 0;
      left: 0;
    }
  }

  .bazaarvoice-popup-icon__tooltip--active {
    display: block;
  }

  .bazaarvoice-popup-icon__tooltip-arrow {
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: -15px;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    transform: rotate(90deg);

    @media (max-width: 768px) {
      top: -10px;
      left: 90px;
      margin-top: 0;
      border-top: 0;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid #fff;
      transform: rotate(0);
    }
  }

  .bazaarvoice-popup-icon__tooltip-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .bazaarvoice-popup-icon__tooltip-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    word-break: break-word;
  }

  .bazaarvoice-popup-icon__tooltip-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(88, 88, 88, 1);
    font-size: 16px;
  }

  .bazaarvoice-popup-icon__tooltip-close:hover {
    color: #d40f7d;
  }
`;
