import styled from '@emotion/styled';
import React, { useState, useEffect, useRef } from 'react';

// Components
import CalculatorForm from './ParocCo2Form';
import CalculatorResults from './ParocCo2Results';
import useParocData from './useParocData';
import { connectLocation } from '../../location/LocationConnectors';
import { setParams, getParams } from "../../helpers/params";

const CalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const CalcFormContainer = styled.div`
  background: #e6e6e6;
  background-size: 250px;
  padding: 15px 15px 15px;
  width: 100%;
  height: fit-content;

  @media (min-width: 768px) {
    width: 33%;
    background-size: 150px;
    padding: 15px 15px 15px;
  }

  @media (min-width: 1200px) {
    background-size: 250px;
  }
`;

const CalcResultsContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 67%;
  }
`;

const ParocCo2Calculator = connectLocation(({ t, locale: { code } }) => {
  const [sku, setSku] = useState(getParams().sku);
  const [thickness, setThickness] = useState(getParams().thickness);
  const [displayedProduct, setDisplayedProduct] = useState({});
  const [resultsVisible, setResultsVisible] = useState(false);
  const scrollToDiv = useRef();
  const { products } = useParocData(code);

  const scrollHandler = () => {
    if (
      window.pageYOffset + window.innerHeight >=
      scrollToDiv.current.offsetTop
    ) {
      setResultsVisible(true);
    } else {
      setResultsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  function findHashBySkuAndThickness({
    skuVal = sku,
    thicknessVal = thickness,
  }) {
    if (skuVal && thicknessVal) {
      return products.find(
        item => item.sku === skuVal && item.thickness === Number(thicknessVal)
      );
    }
    return null;
  }

  const inputChange = e => {
    const { name, value } = e.target;
    let product = null;
    if (name === 'thickness') {
      setThickness(value);
      product = findHashBySkuAndThickness({ thicknessVal: value });
    }
    if (name === 'sku') {
      setSku(value);
      product = findHashBySkuAndThickness({ skuVal: value });
    }
    if (product) {
      setDisplayedProduct(product);
    } else {
      setDisplayedProduct({});
    }
  };

  const formSubmit = e => {
    scrollToDiv.current.scrollIntoView({ behavior: 'smooth' });
    e.preventDefault();

    const product = findHashBySkuAndThickness({});

    if (product) {
      setDisplayedProduct(product);

      const element = scrollToDiv.current;
      const headerOffset = 110;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      !resultsVisible &&
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
    } else {
      setDisplayedProduct({});
    }
  };

  return (
    <CalculatorContainer>
      <CalcFormContainer>
        <CalculatorForm
          inputChange={inputChange}
          sku={sku}
          thickness={thickness}
          formSubmit={formSubmit}
          t={t}
          code={code}
          products={products}
        />
      </CalcFormContainer>

      <CalcResultsContainer ref={scrollToDiv}>
        <CalculatorResults
          displayedProduct={displayedProduct}
          t={t}
          code={code}
        />
      </CalcResultsContainer>
    </CalculatorContainer>
  );
});

export default ParocCo2Calculator;