import { Component, createRef } from 'react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import AlertMessage from '../../OC/oc-alert-message';

const BannerMessage = styled(AlertMessage)`
  justify-content: center;
`

class Viewport extends Component {
  static Device = {
    Desktop: 'Desktop',
    Tablet: 'Tablet',
    Mobile: 'Mobile'
  };

  static Default = Viewport.Device.Desktop;
  static Screens = {
    [ Viewport.Device.Desktop ]: 1200,
    [ Viewport.Device.Tablet ]: 768,
    [ Viewport.Device.Mobile ]: 375
  };

  static Gutters = {
    [ Viewport.Device.Desktop ]: 85,
    [ Viewport.Device.Tablet ]: 15,
    [ Viewport.Device.Mobile ]: 15
  };

  static Width(device) { return ( Viewport.Screens[device] ) + ( Viewport.Gutters[device] * 2 ); }

  constructor(props) {
    super(props);

    this.state = { ratio: 1.0, height: 100 };
    this.resize = this.resize.bind(this);

    this.container = {
      reference: createRef(),
      width: () => this.container.reference?.current?.getBoundingClientRect()?.width
    };

    this.frame = {
      reference: createRef(),
      width: () => Viewport.Width(this.device)
    };
  }

  componentDidMount() {
    this.bridge = Board.viewport.attach(this.frame.reference);

    this.bridge.on('height', (height) => this.setState({ height }));
    this.bridge.on('position', (position) => {
      if (_.isNumber(position)) this.container.reference.current?.scroll({
        top: (position - 32) * Math.min(this.state.ratio, 1.0),
        behavior: 'smooth'
      });
    });

    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() { this.bridge.detach(); window.removeEventListener('resize', this.resize); }
  componentDidUpdate(oldProps) { if (oldProps.device != this.props.device) this.resize(); }

  get queryParams() {
    const params = {}
    if (this.props.usePublished)
      params["use_published"] = true;
    if (this.props.isComparison)
      params["is_comparison"] = true;
    return new URLSearchParams(params).toString();
  }
  get device() { return this.props.device || Viewport.Default; }

  get source() {
    // eslint-disable-next-line no-undef
    return `/en-us/cms/contents/${PB_TYPE}/edit/${
      this.props.content
    }/${this.props.language.toLowerCase()}/view/${this.props.instance}?${
      this.queryParams
    }`;
  }
  get bannerMessage() { return this.props.bannerMessage; }
  get bannerType() { return this.props.bannerType; }

  resize() { this.setState({ ratio: this.container.width() ? this.container.width() / this.frame.width() : 1.0 }); }

  render() {
    return (
      <div css={css`
        padding: 30px 24px ${ this.props.usePublished ? '0' : '0 0' };
      `} >
        <div
          ref={ this.container.reference }
          css={css`
            height: 100%;
            min-width: 462px;
            min-height: 154px;
            border: 1px solid #959595;
            background-color: #f8f8f8;
            position: relative;
            z-index: 20;
            overflow-x: hidden;
            overflow-y: auto;
          `}
        >
          <div>
            <div
              css={css`
                text-align: center;
                line-height: 0px;

                iframe {
                  width: ${ this.frame.width() }px;
                  min-height: calc(100vh - 218px);
                  height: ${ this.state.height }px;
                  display: inline-block;
                  overflow: hidden;
                  border: 0;
                }

                ${ this.state.ratio < 1.0 && css`
                  height: 0px;

                  iframe {
                    display: block;
                    min-height: 0;
                    transform-origin: 0 0;
                    transform: scale(${ this.state.ratio });
                  }
                `}
              `}
            >
              {this.bannerMessage &&
                <BannerMessage message={this.bannerMessage} dismissable={false} type={this.bannerType}/>
              }
              <iframe
                src={ this.source }
                ref={ this.frame.reference }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const Queries = (query='min', offset=0) => _.mapValues(Viewport.Screens, (width, device) => `${ query }-width: ${ width + offset }px`);

Viewport.from = Queries();
Viewport.to = Queries('max', -1);

export default Viewport;
