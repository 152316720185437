import * as React from 'react';
import { connectAnalytics } from '../OC/utilities/analytics';
import _ from 'lodash';

/*
This component should be thought of solely as a generic "link."
You probably shouldn't be writing any styles in this component.
*/

const RawLink = ({ href, onClick, children, ...props }) => {
  return (
    <a
      href={ onClick ? null : href }
      onClick={ onClick ? (e) => onClick?.(e) : null }
      { ...props }
    >{ children }</a>
  )
};

const connectPageBuilderMetadata = (Component) => (
  class extends React.Component {
    render() {
      let { analytics, href, ...props } = this.props;
      analytics = _.merge({}, PB_LINK_META[href]?.analytics, analytics ? analytics : {});
      return <Component analytics={ analytics } href={ href } { ...props } />;
    }
  }
);

const Link = connectPageBuilderMetadata(connectAnalytics(RawLink));

export default Link;
