import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import Product from './Product';

const defaultCategories = [
  'Data Sheets',
  'Technical Bulletins',
  'Code Compliance',
  'Brochures',
  'Guide Specifications',
  'Product Selection Guide',
  'Install Guides'
]

const Enclosure = Product(
  'Product::Enclosure',
  ui`AttributeSet`({
    query: {
      name: 'Enclosure Solutions',
      path: '/insulation/commercial/enclosure',
    },
  }),
  (build) => [
    { title: 'Specifications & Literature', initial: [{ type: 'SpecificationsAndLiterature', data: { categories: defaultCategories?.map((x) => ({ title: x, content: x })) } }] }
  ],
  {},
  {
    version: '0.1',
    displayName: 'Enclosure PDP',
    paths: ['insulation/commercial/enclosure'],
  },
  [
    {
      title: 'Intro',
      unmovables: [true],
      modules: ['BasicContent', 'ContentAndImage', 'AttentionBar', 'Reusable'],
      initial: ['ContentAndImage'],
      showInSticky: false
    },
    {
      title: 'Specifications & Literature',
      modules: ['BasicContent', 'MediaObjectSet', 'Reusable'],
      initial: ['SpecificationsAndLiterature'],
      showHeading: true,
      translationKey: "pdp.roofing.sections.specifications_and_literature"
    },
    {
      title: 'Products In This Solution',
      modules: ['BasicContent', 'CardSet', 'Reusable', 'MediaObjectSet'],
      initial: [
        'ProductsInSolution',
        'BasicContent'
      ],
      showHeading: true
    },
    {
      title: 'Performance Attributes',
      modules: ['BasicContent', 'CardSet', 'MediaObjectSet', 'Reusable', 'ContentAndImage'],
      initial: [{
        type: 'MediaObjectSet',
        data: {
          copy: {
            prehead: '',
            heading: '',
            bodyCopy: null
          },
          settings: {
            itemsPerRow: '2'
          },
        }
      },
      'ContentAndImage',
      'ContentAndImage',
      {
        type: 'MediaObjectSet',
        data: {
          copy: {
            prehead: '',
            heading: '',
            bodyCopy: null
          },
          settings: {
            itemsPerRow: '2'
          },
        }
      },
    ],
      showHeading: true,
      background: 'grey',
    },
    {
      title: 'Drawings',
      modules: ['BasicContent', 'MediaObjectSet', 'BlockLinkSet', 'Reusable'],
      initial: ['CardSet', 'CardSet'],
      showHeading: true,
    },
    {
      title: 'Case Studies & Projects',
      modules: ['BasicContent', 'CardSet', 'Reusable'],
      initial: ['CardSet'],
      showHeading: true
    },
    {
      title: 'Contact Us',
      modules: [],
      initial: ['ContactUs']
    },
  ],
  '/insulation/commercial/enclosure/types',
  true,
)
export default Enclosure;
