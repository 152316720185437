import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { navigate } from '../helpers/sticky_nav';
import { css } from '@emotion/react';
import Theme from '../../themes/index';
import Link from './oc-link';

const ctaStyles = css`
  align-items: center;
  background: ${Theme.colors.brand};
  border: 1px solid ${Theme.colors.brand};
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: ${Theme.fonts.body}, sans-serif;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  min-width: 170px;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: 0.25s ease-in-out;
  z-index: 1;

  // Prevent transition on touch devices
  @media (hover: none), (-moz-touch-enabled: 1) {
    transition: none;
  }

  &:before {
    background-color: #FFFFFF;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    transition: 0.25s ease-in-out;
    z-index: -1;

    /* Prevent transition on touch devices*/
    @media (hover: none), (-moz-touch-enabled: 1) {
      transition: none;
    }
  }

  &:hover,
  &:focus {
    color: ${Theme.colors.brand};
    outline: 0;
    text-decoration: none;

    &:before {
      /* display: block; */
      right: 0;
      transform: translateX(0);
      transition: 0.25s ease-in-out;

      /* Prevent transition on touch devices */
      @media (hover: none), (-moz-touch-enabled: 1) {
        transition: none;
      }
    }
  }
`;

var ctaColors = {
  black: css`
    background: black;
    border-color: white;
    color: white;

    &:before {
      background-color: white;
    }

    &:hover, &:focus {
      color: ${Theme.colors.brand}!important;
    }
  `,
  white: css`
    background: white;
    border-color: black;
    color: black;

    &:before {
      background-color: black;
    }

    &:hover, &:focus {
      color: white!important;
    }
  `,

  grey: css`
    background: #ddd;
    border-color: #333;
    color: black;

    &:before {
      background-color: black;
    }

    &:hover, &:focus {
      color: white;
    }
  `,

  transparent: css`
    background: transparent;
    border-color: white;
    color: white;

    &:before {
      background-color: white;
    }

    &:hover, &:focus {
      color: black!important;
    }
  `,

  borderless: css`
    background: transparent;
    border-color: transparent;
    color: black;

    &:before {
      background-color: black;
    }

    &:hover, &:focus {
      color: white!important;
    }
  `,
};

const disabledStyle = css`
  opacity: 0.5;
  pointer-events: none;

  &:before {
    transition: none;
  }
`;

const Cta = ({ href, navigateTo, children, color, disabled, forwardRef, ...props }) => {
  const history = useHistory();
  const scrollTo = (e, key) => {
    if (key) {
      navigate(key);
      history.push(`#${key}`, {});
      e.preventDefault();
    }
  };

  return (
    <Link
      css={[ctaStyles, color ? ctaColors[color] : '', disabled ? disabledStyle : '']}
      onClick={navigateTo ? ((e) => scrollTo(e, navigateTo)) : null}
      forwardRef={forwardRef}
      href={href}
      {...props}
    >
      {children}
    </Link>
  );
};

export default Cta;
