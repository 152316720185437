import { DEFAULT_PRODUCT_FILTERS } from './constants';
import { useFetchBazzarvoiceReviews } from './useFetchBazzarvoiceReviews';
import { reviewContentItemMapper } from './utils';
import Checkbox from '../../ComponentLibrary/input-elements/oc-checkbox';
import { MoreReviewContentButton } from '../MoreReviewContentButton';
import { ProductFilter } from '../ProductFilter';
import { Rating } from '../Rating';
import { RatingFilter } from '../RatingFilter';
import { ReviewContent } from '../ReviewContent';
import { SchemaMarkup } from '../SchemaMarkup';
import { SortBy } from '../SortBy';

// eslint-disable-next-line react/prop-types
const BazzarvoiceReviews = () => {
  const {
    filters,
    setFilters,
    sort,
    setSort,
    data,
    loading,
    error,
    nextPage,
  } = useFetchBazzarvoiceReviews();

  const loaded = !loading;
  const allPagesData = data?.data || {};
  const reviewContentItems = Object.values(allPagesData).flat() || [];
  const reviewContentMeta = data?.meta || {};

  const ratingAverageValue =
    reviewContentMeta.summary?.average_star_rating || 0;

  const starRatingCounts = reviewContentMeta.summary?.star_rating_counts || {};
  const starRatingCountsDefault = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };

  const starRating =
    Object.keys(starRatingCounts).length > 0
      ? starRatingCounts
      : starRatingCountsDefault;

  const toggleRatingFilter = value => {
    const newFilters = { ...filters };

    if (filters.stars?.includes(value)) {
      newFilters.stars = filters.stars.filter(rating => rating !== value);
    } else {
      newFilters.stars.push(value);
    }

    setFilters(newFilters);
  };

  const changeProductFilter = value => {
    const newFilters = { ...filters };
    newFilters.products = value ? [value] : [...DEFAULT_PRODUCT_FILTERS];
    setFilters(newFilters);
  };

  const onlyPhotosChangeHandler = e => {
    setFilters({ ...filters, only_photos: e.target.checked });
  };

  if (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching Bazaarvoice Reviews:', error);

    return (
      <p>Bazzarvoice Reviews: Something went wrong. Please try again later.</p>
    );
  }

  return (
    <>
      <SchemaMarkup reviewContentItems={reviewContentItems} />
      <Rating ratingAverageValue={ratingAverageValue} starRating={starRating} />

      <div className="product-review-landing__filters">
        <div>
          <RatingFilter
            ratingsSelected={filters.stars}
            setRatingsSelected={toggleRatingFilter}
          />
          <ProductFilter
            productSelected={
              filters.products.length === 1 ? filters.products[0] : ''
            }
            setProductSelected={changeProductFilter}
          />
        </div>
        <SortBy sort={sort} setSort={setSort} />
      </div>

      <Checkbox
        checked={filters.only_photos}
        onChange={onlyPhotosChangeHandler}
      >
        <b>Show only reviews that include photos</b>
      </Checkbox>

      <div className="product-review-landing__review-layout">
        {loaded && reviewContentItems.length === 0 && (
          <div>No reviews found.</div>
        )}

        {reviewContentItems.length > 0 && (
          <>
            {reviewContentItems.map((reviewContentItem, index) => (
              <ReviewContent
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...reviewContentItemMapper(reviewContentItem)}
                // eslint-disable-next-line react/no-array-index-key
                key={`review-content-${index}`}
              />
            ))}
          </>
        )}

        {loading && (
          <p className="product-review-landing__review-layout--loading">
            Loading Bazaarvoice Reviews...
          </p>
        )}
      </div>

      <div>
        <MoreReviewContentButton onClickHandler={nextPage} />
      </div>
    </>
  );
};

export { BazzarvoiceReviews };
