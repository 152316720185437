import React from 'react';

import RadioGroup from './RadioGroup';
import {
  cavityTextOrProductOptions,
  cavityInsulationTypeOptions,
  exteriorTextOrThicknessOptions,
  studWoodOptions,
  studSpacingOptions,
} from './constants';
import { useRValueCalculator } from './context';
import { InputsContainer } from './styled_components';
import Button from '../../../ComponentLibrary/input-elements/oc-form-button';
import NumberInput from '../../../ComponentLibrary/input-elements/oc-numeric-input';
import Select from '../../../ComponentLibrary/input-elements/oc-select-dropdown';

const RValueInput = ({ label, value, onChange }) => (
  <div className="rinput">
    <NumberInput
      label={label}
      value={value}
      onChange={onChange}
      hideSpinButton
      min={0}
      max={38}
    />
  </div>
);

const CavityInsulation = () => {
  const {
    cavityTextOrProduct,
    setCavityTextOrProduct,
    cavityRValueText,
    setCavityRValueText,
    cavityInsulationType,
    setCavityInsulationType,
    cavityThicknessOptions,
    cavityThickness,
    setCavityThickness,
  } = useRValueCalculator();
  const manualInputJsx = (
    <RValueInput
      label="Enter Cavity R-Value"
      value={cavityRValueText}
      onChange={setCavityRValueText}
    />
  );
  const productPickerJsx = (
    <>
      <Select
        label="Insulation Type"
        options={cavityInsulationTypeOptions}
        onChange={setCavityInsulationType}
        value={cavityInsulationType}
      />
      <Select
        label="Thickness"
        options={cavityThicknessOptions}
        onChange={setCavityThickness}
        value={cavityThickness}
      />
    </>
  );
  return (
    <>
      <h5>Cavity Insulation</h5>
      <p>
        Choose to enter a cavity insulation R-value, or choose an insulation
        type and thickness to calculate.
      </p>
      <RadioGroup
        name="cavity_picker"
        options={cavityTextOrProductOptions}
        onChange={setCavityTextOrProduct}
        value={cavityTextOrProduct}
      />
      {cavityTextOrProduct === 'text' ? manualInputJsx : productPickerJsx}
    </>
  );
};

const ExteriorInsulation = () => {
  const {
    exteriorTextOrThickness,
    setExteriorTextOrThickness,
    exteriorThickness,
    setExteriorThickness,
    exteriorThicknessOptions,
    exteriorRValueText,
    setExteriorRValueText,
  } = useRValueCalculator();
  const manualInputJsx = (
    <RValueInput
      label="Enter Exterior R-Value"
      value={exteriorRValueText}
      onChange={setExteriorRValueText}
    />
  );
  const productPickerJsx = (
    <Select
      label="Thickness"
      options={exteriorThicknessOptions}
      onChange={setExteriorThickness}
      value={exteriorThickness}
    />
  );
  return (
    <>
      <h5>Exterior Insulation</h5>
      <p>
        Choose to enter an exterior insulation R-value, or choose an XPS
        Insulation thickness to calculate.
      </p>
      <RadioGroup
        name="exterior_picker"
        options={exteriorTextOrThicknessOptions}
        onChange={setExteriorTextOrThickness}
        value={exteriorTextOrThickness}
      />
      {exteriorTextOrThickness === 'text' ? manualInputJsx : productPickerJsx}
    </>
  );
};

const Inputs = () => {
  const {
    studWood,
    setStudWood,
    studSpacing,
    setStudSpacing,
    totalRValue,
    scrollToResults,
    reset,
  } = useRValueCalculator();
  return (
    <InputsContainer>
      <h3>Calculating Inputs</h3>
      <hr className="underline" />
      <RadioGroup
        label="Stud-Wood"
        options={studWoodOptions}
        onChange={setStudWood}
        value={studWood}
      />
      <RadioGroup
        label="Stud Spacing"
        options={studSpacingOptions}
        onChange={setStudSpacing}
        value={studSpacing}
      />
      <hr className="divider" />
      <ExteriorInsulation />
      <hr className="divider" />
      <CavityInsulation />
      <Button
        textTransform="none"
        disabled={!totalRValue}
        onClick={scrollToResults}
      >
        Calculate R-Value
      </Button>
      <Button textTransform="none" color="white" onClick={reset}>
        Reset
      </Button>
    </InputsContainer>
  );
};

export default Inputs;
