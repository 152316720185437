import { wrapRef } from '../../../../../../data';
import { anchor as stickyNavAnchor } from '../../../../../helpers/sticky_nav';

const reducedBannerDefaults = ( t, language) => {
  return {
    title: t('product.reduced_banner.title'),
    message: {
      ops: [
        {
          insert: t('product.reduced_banner.message'),
        },
        {
          insert: t('product.reduced_banner.explore_other_products'),
          attributes: {
            link: `${language}/products`,
          }
        },
        {
          "insert": "\n"
        },
      ]
    },
  }
}

export default (build, t) => ({
  contents: {
    layout: {
      Header: {
        Hero: {
          preset: 'custom',
          type: 'tier_3',
          showHeroImage: 'small',
          contentOrder: {
            desktop: 'first',
            mobile: 'first',
          },
        },
        StickyNav: {
          cta: {
            url: `${stickyNavAnchor(t("product.paroc.specifications_and_literature"))}`,
            text: t('product.paroc.download_documents'),
            color: 'standard',
            destination: 'link',
            modalSource: 'form',
          },
        },
      },
    },
    sections: {
      template: {
        technical_information: {
          sticky: {
            name: t('product.paroc.technical_information'),
            show: true,
          },
          details: {
            name: t('product.paroc.technical_information'),
            separator: 'auto',
            background: 'white',
            useNameAsHeading: true,
            initialHeadingLevel: 'h2',
            forceHeadingHierarchy: true,
          },
          modules: {
            template: {
              tabbed_content: {
                data: {
                  settings: {
                    iconImage: 'text',
                    tabSize: 'small',
                    tabsAlign: 'left',
                    title: '',
                  },
                  copy: {
                    crop: {
                      x: 0,
                      y: 0,
                      width: 1438,
                      height: 900,
                    },
                    ctas: [],
                    banner: {},
                    heading: {
                      ops: [
                        {
                          insert: '\n',
                        },
                      ],
                    },
                    prehead: '',
                    __hidden: true,
                    bodyCopy: {
                      ops: [
                        {
                          insert: '\n',
                        },
                      ],
                    },
                    bodyFont: true,
                  },
                  tabs: [
                    {
                      heading: t('product.paroc.product_dimensions'),
                      defaultTab: false,
                      tabContent: [
                        {
                          data: {
                            copy: {
                              heading: {
                                ops: [
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyCopy: {
                                ops: [
                                  { insert: t('product.paroc.dimensions') },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                ],
                              },
                              bodyFont: true,
                            },
                            after: {
                              ops: [{ insert: 'Lorem ipsum optional footnote' }],
                            },
                            table: [
                              {
                                rows: [
                                  [
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.width_x_length')} (MM)\t\n` }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.thickness')} (mm)\t\n` }],
                                      },
                                    },
                                  ],
                                ],
                                type: 'Head',
                              },
                              {
                                rows: [
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'XXX x XXX\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'XX, XX, XX, XX, XXX\n' }],
                                      },
                                    },
                                  ],
                                  [
                                    { content: 'XXX x XXX' },
                                    { content: 'XX, XX, XX, XX, XXX' },
                                  ],
                                  [
                                    { content: 'XXX x XXX' },
                                    { content: 'XX, XX, XX, XX, XXX' },
                                  ],
                                  [
                                    { content: 'XXX x XXX' },
                                    { content: 'XX, XX, XX, XX, XXX' },
                                  ],
                                  [
                                    { content: 'XXX x XXX' },
                                    { content: 'XX, XX, XX, XX, XXX' },
                                  ],
                                ],
                                type: 'Body',
                              },
                            ],
                            alignment: 'Left',
                            containerSize: { selection: 'l' },
                          },
                          type: 'Table',
                          __hidden: false,
                        },

                        {
                          data: {
                            copy: {
                              heading: {
                                ops: [
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyCopy: {
                                ops: [
                                  { insert: t('product.paroc.dimensions') },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                ],
                              },
                              bodyFont: true,
                            },
                            after: {
                              ops: [
                                {
                                  insert: `${t('product.paroc.nominal_density')}: `,
                                  attributes: { bold: true },
                                },
                                { insert: 'XXX kg/m' },
                                {
                                  insert: '3',
                                  attributes: { script: 'super' },
                                },
                                { insert: '\n' },

                                {
                                  insert: `${t('product.paroc.wetting_impairment_note')}: `,
                                  attributes: { bold: true },
                                },
                                { insert: 'X' },
                                { insert: '\n' },

                                {
                                  insert:
                                    `${t('product.paroc.dimensional_stability_at_specified_temperature_ds')}(70): `,
                                  attributes: { bold: true },
                                },
                                {
                                  insert: '≤ X%\n',
                                },

                                {
                                  insert: `${t('product.paroc.certification_number')}: `,
                                  attributes: { bold: true },
                                },
                                { insert: 'X' },
                                { insert: '\n' },

                                {
                                  insert: `${t('product.paroc.package_type')}: `,
                                  attributes: { bold: true },
                                },
                                { insert: 'Lorem ipsum dolor sit amet.\n' },
                              ],
                            },
                            table: [
                              {
                                rows: [
                                  [
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.inner_diameter')} (mm)\t\n` }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.thickness')} (mm)\n` }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.length')} (mm)\n` }],
                                      },
                                    },
                                  ],
                                ],
                                type: 'Head',
                              },
                              {
                                rows: [
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'XX - XXXX\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'XX - XXX\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'XXXX\n' }],
                                      },
                                    },
                                  ],
                                  [
                                    { content: 'XX - XXXX' },
                                    {
                                      content: {
                                        ops: [{ insert: 'XX - XXX' }],
                                      },
                                    },
                                    { content: 'XXXX' },
                                  ],
                                  [
                                    { content: 'XX - XXXX' },
                                    {
                                      content: {
                                        ops: [{ insert: 'XX - XXX\n' }],
                                      },
                                    },
                                    { content: 'XXXX' },
                                  ],
                                ],
                                type: 'Body',
                              },
                            ],
                            alignment: 'Left',
                            containerSize: { selection: 'l' },
                          },
                          type: 'Table',
                          __hidden: false,
                        },
                      ],
                      moduleSeparator: false,
                    },

                    {
                      heading: t('product.paroc.product_characteristics'),
                      defaultTab: false,
                      tabContent: [
                        {
                          data: {
                            copy: {
                              heading: {
                                ops: [
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyCopy: {
                                ops: [
                                  { insert: t('product.paroc.product_characteristics'), },
                                  {
                                    insert: '',
                                    attributes: { header_three_roboto: true },
                                  },
                                ],
                              },
                              bodyFont: true,
                            },
                            after: {
                              ops: [{ insert: '\n' }],
                            },
                            table: [
                              {
                                rows: [
                                  [
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: t('product.paroc.product_characteristics'), }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.value')}\n` }],
                                      },
                                    },
                                  ],
                                ],
                                type: 'Head',
                              },
                              {
                                rows: [
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Characteristic name' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'Value here\n' }],
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Characteristic name\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'Value here\n' }],
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Characteristic name\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'Value here\n' }],
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Characteristic name\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'Value here\n' }],
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Characteristic name\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'Value here\n' }],
                                      },
                                    },
                                  ],
                                ],
                                type: 'Body',
                              },
                            ],
                            alignment: 'Left',
                            containerSize: { selection: 'xl' },
                          },
                          type: 'Table',
                        },

                        {
                          data: {
                            copy: {
                              heading: {
                                ops: [
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyCopy: {
                                ops: [
                                  {
                                    insert: 'Application Density & Thermal Conductivity',
                                  },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                ],
                              },
                              bodyFont: true,
                            },
                            after: {
                              ops: [{ insert: '\n' }],
                            },
                            table: [
                              {
                                rows: [
                                  [
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.application')}\n` }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [
                                          { insert: `${t('product.paroc.installed_density')}, kg/m` },
                                          {
                                            insert: '3',
                                            attributes: { script: 'super' },
                                          },
                                          { insert: '\n' },
                                        ],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.thermal_conductivity')}, W/mK\n` }],
                                      },
                                    },
                                  ],
                                ],
                                type: 'Head',
                              },
                              {
                                rows: [
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Application name\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: '≥ XX\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'X,XX\n' }],
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Application name\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: '≥ XX\n' }],
                                      },
                                    },
                                    { content: 'X,XX' },
                                  ],
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Application name\n' }],
                                      },
                                    },
                                    { content: '≥ XX' },
                                    { content: 'X,XX' },
                                  ],
                                ],
                                type: 'Body',
                              },
                            ],
                            alignment: 'Left',
                            containerSize: { selection: 'xl' },
                          },
                          type: 'Table',
                        },

                        {
                          data: {
                            copy: null,
                            after: {
                              ops: [
                                {
                                  insert: '\n',
                                  attributes: { list: 'ordered' },
                                },
                              ],
                            },
                            table: [
                              {
                                rows: [
                                  [
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: 'Temperature °C \n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '0\t\t\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '10\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '40\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '50\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '100\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '150\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '200\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '300\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '400\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '500\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '600\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [
                                          { insert: '700' },
                                          { insert: { breaker: true } },
                                          { insert: '\n' },
                                        ],
                                      },
                                    },
                                  ],
                                ],
                                type: 'Head',
                              },
                              {
                                rows: [
                                  [
                                    {
                                      style: { align: 'left' },
                                      content: 'λD W/ mK',
                                    },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                  ],
                                ],
                                type: 'Body',
                              },
                            ],
                            alignment: 'Left',
                            containerSize: { selection: 'xl' },
                          },
                          type: 'Table',
                        },
                      ],
                      moduleSeparator: false,
                    },
                    {
                      heading: t('product.paroc.product_properties'),
                      defaultTab: false,
                      tabContent: [
                        {
                          data: {
                            copy: {
                              heading: {
                                ops: [
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyCopy: {
                                ops: [
                                  { insert: t('product.paroc.fire_properties') },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                ],
                              },
                              bodyFont: true,
                            },
                            after: {
                              ops: [{ insert: 'Notes and details here as needed\n' }],
                            },
                            table: [
                              {
                                rows: [
                                  [
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.fire_properties')}\t\n` }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: `${t('product.paroc.value')}\n` }],
                                      },
                                    },
                                  ],
                                ],
                                type: 'Head',
                              },
                              {
                                rows: [
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Property name\t\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'Value here\n' }],
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Property name\t\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'Value here\n' }],
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      content: {
                                        ops: [{ insert: 'Property name\t\n' }],
                                      },
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'Value here\n' }],
                                      },
                                    },
                                  ],
                                ],
                                type: 'Body',
                              },
                            ],
                            alignment: 'Left',
                            containerSize: { selection: 'xl' },
                          },
                          type: 'Table',
                        },

                        {
                          data: {
                            copy: {
                              heading: {
                                ops: [
                                  {
                                    insert: {
                                      breaker: true,
                                    },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyCopy: {
                                ops: [
                                  { insert: t('product.paroc.thermal_properties') },
                                  {
                                    insert: '',
                                    attributes: {
                                      header_three_roboto: true,
                                    },
                                  },
                                  // eslint-disable-next-line no-multi-str
                                  { insert: 'Thermal Resistance RD = d/λD' },
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: 'λD = 0,038\n' },
                                ],
                              },
                              bodyFont: true,
                            },
                            after: {
                              ops: [{ insert: 'Notes and details here as needed\n' }],
                            },
                            table: [
                              {
                                rows: [
                                  [
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: 'Temperature °C\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '0\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '10\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '40\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '50\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '100\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '150\t\n' }],
                                      },
                                    },
                                    {
                                      style: {
                                        align: 'left',
                                      },
                                      content: {
                                        ops: [
                                          {
                                            insert: '200\t\n',
                                          },
                                        ],
                                      },
                                    },
                                    {
                                      style: {
                                        align: 'left',
                                      },
                                      content: {
                                        ops: [
                                          {
                                            insert: '300\t\n',
                                          },
                                        ],
                                      },
                                    },
                                    {
                                      style: {
                                        align: 'left',
                                      },
                                      content: {
                                        ops: [{ insert: '400\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '500\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '600\t\n' }],
                                      },
                                    },
                                    {
                                      style: { align: 'left' },
                                      content: {
                                        ops: [{ insert: '700\n' }],
                                      },
                                    },
                                  ],
                                ],
                                type: 'Head',
                              },
                              {
                                rows: [
                                  [
                                    {
                                      content: 'Thermal Resistance RD = d/λD',
                                    },
                                    {
                                      content: {
                                        ops: [{ insert: 'X,XX\n' }],
                                      },
                                    },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                    { content: 'X,XX' },
                                  ],
                                ],
                                type: 'Body',
                              },
                            ],
                            alignment: 'Left',
                            containerSize: { selection: 'xl' },
                          },
                          type: 'Table',
                        },

                        {
                          data: {
                            content: {
                              heading: {
                                ops: [
                                  {
                                    insert: {
                                      breaker: true,
                                    },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyCopy: {
                                ops: [
                                  { insert: t('product.paroc.moisture_properties') },
                                  {
                                    insert: '',
                                    attributes: { header_three_roboto: true },
                                  },
                                  {
                                    insert:
                                      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facere laborum vel earum placeat tempora dolorem doloribus.\n',
                                  },
                                ],
                              },
                              bodyFont: true,
                            },
                          },
                          type: 'BasicContent',
                        },
                        {
                          data: {
                            content: {
                              heading: {
                                ops: [
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyCopy: {
                                ops: [
                                  {
                                    insert: t('product.paroc.sound_properties'),
                                  },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                  {
                                    insert: 'Air Permeability: ',
                                    attributes: { bold: true },
                                  },
                                  { insert: 'XX m' },
                                  {
                                    insert: '3',
                                    attributes: { script: 'super' },
                                  },
                                  { insert: '/m' },
                                  {
                                    insert: '2',
                                    attributes: { script: 'super' },
                                  },
                                  { insert: '*s*Pa\n' },
                                  {
                                    insert: 'Sound Properites Note: ',
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert:
                                      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facere laborum vel earum placeat tempora dolorem doloribus.\n',
                                  },
                                ],
                              },
                              bodyFont: true,
                            },
                          },
                          type: 'BasicContent',
                        },

                        {
                          data: {
                            content: {
                              heading: {
                                ops: [
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyCopy: {
                                ops: [
                                  { insert: t('product.paroc.environmental_properties') },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                  {
                                    insert: 'Global Warming Potential (GWP) R=1:',
                                    attributes: { bold: true },
                                  },
                                  { insert: ' X,XXkg CO\n' },
                                  {
                                    insert: 'Biosolubility',
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert:
                                      ': Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facere laborum vel earum placeat tempora dolorem doloribus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facere laborum vel earum placeat tempora dolorem doloribus.\n',
                                  },
                                  {
                                    insert: 'Indoor Emissions Air Class:',
                                    attributes: { bold: true },
                                  },
                                  { insert: ' XX\n' },
                                  {
                                    insert: 'Emmisions Note: ',
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert:
                                      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facere laborum vel earum placeat tempora dolorem doloribus.\n',
                                  },
                                  {
                                    insert: 'Recycling and end-of-life Note: ',
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert:
                                      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facere laborum vel earum placeat tempora dolorem doloribus.',
                                  },
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                              bodyFont: true,
                            },
                          },
                          type: 'BasicContent',
                        },
                      ],
                      moduleSeparator: true,
                    },

                    {
                      heading: t('product.paroc.appearance'),
                      defaultTab: false,
                      tabContent: [
                        {
                          data: {
                            content: {
                              heading: {
                                ops: [{ insert: '\n' }],
                              },
                              bodyCopy: {
                                ops: [
                                  { insert: t('product.paroc.appearance') },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                  {
                                    insert: `${t('product.paroc.facing_material')}: `,
                                    attributes: { bold: true },
                                  },
                                  { insert: 'Lorem, Ipsum, Dolor' },
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '' },
                                  {
                                    insert: `${t('product.paroc.colours')}: `,
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert: 'Lorem, Ipsum, Dolor',
                                  },
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                            },
                          },
                          type: 'BasicContent',
                        },
                      ],
                      moduleSeparator: false,
                    },
                    {
                      heading: t('product.paroc.handling'),
                      defaultTab: false,
                      tabContent: [
                        {
                          data: {
                            content: {
                              heading: {
                                ops: [{ insert: '\t' }],
                              },
                              bodyCopy: {
                                ops: [
                                  {
                                    insert: t('product.paroc.installation'),
                                    attributes: {
                                      bold: true,
                                    },
                                  },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                  {
                                    insert:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et eros sed orci congue pulvinar. Praesent scelerisque neque eu iaculis porta. Sed efficitur eleifend nulla, sed sagittis neque sollicitudin a. Sed gravida velit ante. Quisque volutpat ante arcu, quis vulputate diam maximus ut. Nulla nunc turpis, varius non aliquam nec, vestibulum nec nulla. Praesent felis justo, convallis a augue quis, dapibus auctor erat. In nec pretium turpis, ac malesuada metus. Vivamus accumsan laoreet ante, non dapibus felis ullamcorper accumsan. Sed accumsan ipsum a neque laoreet porttitor. Nunc sollicitudin in ligula laoreet egestas.\n\n',
                                  },
                                  {
                                    insert: t('product.paroc.handling_and_storage'),
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                  {
                                    insert:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et eros sed orci congue pulvinar. Praesent scelerisque neque eu iaculis porta. Sed efficitur eleifend nulla, sed sagittis neque sollicitudin a. Sed gravida velit ante. Quisque volutpat ante arcu, quis vulputate diam maximus ut. Nulla nunc turpis, varius non aliquam nec, vestibulum nec nulla. Praesent felis justo, convallis a augue quis, dapibus auctor erat. In nec pretium turpis, ac malesuada metus. Vivamus accumsan laoreet ante, non dapibus felis ullamcorper accumsan. Sed accumsan ipsum a neque laoreet porttitor. Nunc sollicitudin in ligula laoreet egestas.\n',
                                  },
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                  {
                                    insert: t('product.paroc.more_information'),
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                  {
                                    insert:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et eros sed orci congue pulvinar. Praesent scelerisque neque eu iaculis porta. Sed efficitur eleifend nulla, sed sagittis neque sollicitudin a. Sed gravida velit ante. Quisque volutpat ante arcu, quis vulputate diam maximus ut. Nulla nunc turpis, varius non aliquam nec, vestibulum nec nulla. Praesent felis justo, convallis a augue quis, dapibus auctor erat. In nec pretium turpis, ac malesuada metus. Vivamus accumsan laoreet ante, non dapibus felis ullamcorper accumsan. Sed accumsan ipsum a neque laoreet porttitor. Nunc sollicitudin in ligula laoreet egestas.',
                                  },
                                  {
                                    insert: { breaker: true },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                            },
                          },
                          type: 'BasicContent',
                        },
                      ],
                      moduleSeparator: false,
                    },

                    {
                      heading: t('product.paroc.certifications_and_standards'),
                      defaultTab: false,
                      tabContent: [
                        {
                          data: {
                            content: {
                              heading: {
                                ops: [{ insert: '\n' }],
                              },
                              bodyCopy: {
                                ops: [
                                  {
                                    insert: t('product.paroc.certifications_and_standards'),
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert: '\n',
                                    attributes: { header_three_roboto: true },
                                  },
                                  {
                                    insert: `${t('product.paroc.certifications_and_standards')},: `,
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert:
                                      'XXXX-XXX-XXXX / Eurofins Expert Services Ltd, P.O. Box 1001, FI-02044 VTT, Finland\n',
                                  },
                                  {
                                    insert: 'Certificate of Confirmity:',
                                    attributes: { bold: true },
                                  },
                                  { insert: ' XXXX-XXX-XXXX\n' },
                                  {
                                    insert:
                                      'European Technical Assessment (ETA) Fire Protection of Load-Bearing Steel Structures:',
                                    attributes: { bold: true },
                                  },
                                  { insert: ' XX/XXXX\n' },
                                  {
                                    insert: `${t('product.paroc.wetting_impairment_note')}: `,
                                    attributes: { bold: true },
                                  },
                                  {
                                    insert:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et eros sed orci congue pulvinar. Praesent scelerisque neque eu iaculis porta. Sed efficitur eleifend nulla, sed sagittis neque sollicitudin a. Sed gravida velit ante. Quisque volutpat ante arcu, quis vulputate diam maximus ut.\n',
                                  },
                                  {
                                    insert: `${t('product.paroc.certificates')}: `,
                                    attributes: { bold: true },
                                  },
                                  { insert: '\nLorem ipsum dolor sit amet' },
                                  {
                                    insert: '\n',
                                    attributes: { list: 'bullet' },
                                  },
                                  { insert: 'Lorem ipsum dolor sit amet' },
                                  {
                                    insert: '\n',
                                    attributes: { list: 'bullet' },
                                  },
                                  { insert: 'Lorem ipsum dolor sit amet' },
                                  {
                                    insert: '\n',
                                    attributes: { list: 'bullet' },
                                  },
                                  { insert: 'Lorem ipsum dolor sit amet' },
                                  {
                                    insert: '\n',
                                    attributes: { list: 'bullet' },
                                  },
                                  { insert: 'Lorem ipsum dolor sit amet' },
                                  {
                                    insert: '\n',
                                    attributes: { list: 'bullet' },
                                  },
                                  { insert: 'Lorem ipsum dolor sit amet' },
                                  {
                                    insert: '\n',
                                    attributes: { list: 'bullet' },
                                  },
                                  { insert: '\n' },
                                ],
                              },
                            },
                          },
                          type: 'BasicContent',
                        },
                      ],
                      moduleSeparator: false,
                    },
                  ],
                },
                type: 'TabbedContent',
                templated: true,
              },
            },
            user: {},
            order: [
              'template.tabbed_content'
            ],
          },
          __hidden: true,
        },
        certifications: {
          sticky: {
            name: t('product.paroc.certifications'),
            show: true,
          },
          details: {
            name: t('product.paroc.certifications'),
            separator: 'auto',
            background: 'white',
            useNameAsHeading: true,
            initialHeadingLevel: 'h2',
            forceHeadingHierarchy: true,
          },
          modules: {
            template: {
              card_set: {
                data: {
                  type: {
                    source: 'custom',
                  },
                  cards: [
                    {
                      // cta: [{}],
                      text: {
                        ops: [
                          {
                            insert: '\n',
                          },
                        ],
                      },
                      image: {
                        file:
                          'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/25f450fd-db1b-4973-bf6b-404fff74fe3f/certifications-ce',
                      },
                      heading: 'CE',
                      __hidden: false,
                      ctaSwitch: false,
                    },
                    {
                      // cta: [{}],
                      text: {
                        ops: [
                          {
                            insert: '\n',
                          },
                        ],
                      },
                      image: {
                        file:
                          'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/fdc4d96e-7a2d-4511-9ff9-27270fe1a3cb/certifications-euceb',
                      },
                      heading: 'EUCEB',
                      __hidden: false,
                      ctaSwitch: false,
                    },
                    {
                      // cta: [{}],
                      text: {
                        ops: [
                          {
                            insert: '\n',
                          },
                        ],
                      },
                      image: {
                        file:
                          'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/f4e8e387-5c76-4a2d-983e-886aec4239bc/certifications-m1',
                      },
                      heading: 'M1 Emission Class',
                      __hidden: false,
                      linkText: '',
                      ctaSwitch: false,
                    },
                    {
                      // cta: [{}],
                      text: {
                        ops: [
                          {
                            insert: '\n',
                          },
                        ],
                      },
                      image: {
                        file:
                          'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/86d0070c-007e-4fc4-a572-d8da117267da/certifications-carbon-neutral',
                      },
                      heading: 'Carbon Neutral',
                      __hidden: false,
                      ctaSwitch: false,
                    },
                    {
                      // cta: [{}],
                      text: {
                        ops: [
                          {
                            insert: '\n',
                          },
                        ],
                      },
                      image: {
                        file:
                          'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/2f4531f4-0b6b-40f1-ba82-137cc81b39a4/certifications-2690',
                      },
                      heading: 'Marine IMO',
                      __hidden: false,
                      ctaSwitch: false,
                    },
                    {
                      // cta: [{}],
                      text: {
                        ops: [
                          {
                            insert: '\n',
                          },
                        ],
                      },
                      image: {
                        file:
                          'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/ad94bea0-5246-4e34-b980-319341253240/certifications-8517',
                      },
                      heading: 'UK MER',
                      __hidden: false,
                      ctaSwitch: false,
                    },
                    {
                      // cta: [{}],
                      text: {
                        ops: [
                          {
                            insert: '\n',
                          },
                        ],
                      },
                      image: {
                        file:
                          'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/ba09afcf-c0c2-4991-92f4-4e85766f82a5/certifications-blauer-engel',
                      },
                      heading: 'Blauer Engel',
                      ctaSwitch: false,
                    },
                    {
                      // cta: [{}],
                      text: {
                        ops: [
                          {
                            insert: '\n',
                          },
                        ],
                      },
                      image: {
                        file:
                          'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/cd55d2d4-c33e-415b-af44-3099b3dceadf/certifications-eurofins-indoor-air-comfort',
                      },
                      heading: 'Eurofins Indoor Air Comfort Certified',
                      ctaSwitch: false,
                    },
                  ],
                  settings: {
                    title: 'test',
                    format: 'defined',
                    cardSize: 'xs',
                    aspectRatio: {
                      custom: 1,
                      selection: 'none',
                    },
                    itemsPerRow: '6',
                    containerSize: {
                      selection: 'xl',
                    },
                    textAlignment: 'center',
                    itemsPerRowMobile: '2',
                    itemsPerRowTablet: '4',
                  },
                  socialShare: false,
                },
                type: 'CardSet',
                templated: true,
              },
            },
            user: {},
            order: [
              'template.card_set',
            ],
          },
          "__hidden": true
        },
        promotions: {
          sticky: {
            name: t('product.paroc.promotions'),
            show: true,
          },
          details: {
            name: t('product.paroc.promotions'),
            separator: 'auto',
            background: 'white',
            useNameAsHeading: true,
            initialHeadingLevel: 'h2',
            forceHeadingHierarchy: true,
          },
          modules: {
            template: {
              full_bleed_content: {
                data: {},
                type: 'FullBleedContent',
                templated: true,
              },
              reusable: {
                type: 'Reusable',
                templated: true,
              },
            },
            user: {},
            order: [
              'template.full_bleed_content',
              'template.reusable',
            ],
          },
          __hidden: true
        },
        specifications_and_literature: {
          sticky: {
            name: t('product.paroc.specifications_and_literature'),
            show: true,
          },
          details: {
            name: t('product.paroc.specifications_and_literature'),
            separator: 'auto',
            background: 'white',
            useNameAsHeading: true,
            initialHeadingLevel: 'h2',
            forceHeadingHierarchy: true,
          },
          modules: {
            template: {
              specifications_and_literature: {
                type: 'SpecificationsAndLiterature',
                templated: true,
              },
              co2 : {
                data: {
                  size: 'xl',
                  alignment: 'Left',
                  reference: {
                    __ref: 'Cms::Content|{"type":"Reusable", "path":"", "name":"CO2 Calculator"}',
                  },
                },
                type: 'Reusable',
                templated: false,
              },
              reusable: {
                data: {
                  size: 'xl',
                  alignment: 'Left',
                  reference: {
                    __ref: 'Cms::Content|{"type":"Reusable","path":"","name":"Certificates Content Block"}',
                  },
                },
                type: 'Reusable',
                templated: true,
              },
            },
            user: {},
            order: [
              'template.specifications_and_literature',
              'template.co2',
              'template.reusable',
            ],
          },
          __hidden: false,
        },
        tools_and_calculators: {
          sticky: {
            name: t('product.paroc.tools_and_calculators'),
            show: true,
          },
          details: {
            name: t('product.paroc.tools_and_calculators'),
            separator: 'auto',
            background: 'white',
            useNameAsHeading: false,
            initialHeadingLevel: 'h2',
            forceHeadingHierarchy: true,
          },
          modules: {
            template: {
              paroc_co_2_calculator_promo: {
                type: 'ParocCo2CalculatorPromo',
                templated: true,
              },
            },
            user: {},
            order: ['template.paroc_co_2_calculator_promo'],
          },
          __hidden: false,
        },
        solutions_and_related_products: {
          sticky: {
            name: t('product.paroc.solutions_and_related_products'),
            show: true,
          },
          details: {
            name: t('product.paroc.solutions_and_related_products'),
            separator: 'auto',
            background: 'white',
            useNameAsHeading: true,
            initialHeadingLevel: 'h2',
            forceHeadingHierarchy: true,
          },
          modules: {
            template: {
              tabbed_content: {
                data: {
                  size: 'xl',
                  tabs: [
                    {
                      icon: 'akar-icons:shipping-box-01',
                      heading: t('product.paroc.solutions'),
                      iconImage:
                        'https://imagecdn.owenscorning.com/ocimages/image/upload/w_340,h_190/fl_no_overflow,l_text:Arial_38:340x190/placeholder',
                      defaultTab: false,
                      tabContent: [
                        {
                          data: {
                            copy: null,
                            data: null,
                            type: {
                              source: 'custom',
                              'nullfield_085d9b6b-725b-4332-a629-f40fb706ab58': null,
                            },
                            cards: [
                              {
                                cta: [{}],
                                url: '#',
                                text: {
                                  ops: [
                                    {
                                      insert: '\n',
                                    },
                                  ],
                                },
                                image: {
                                  file:
                                    'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/5be17ca7-b321-47db-a02e-553a9c8a8e76/Placeholder-Solution-Image-wide',
                                },
                                heading: 'Solution Name Here',
                                linkText: '',
                                ctaSwitch: false,
                              },
                              {
                                cta: [{}],
                                url: '#',
                                text: {
                                  ops: [
                                    {
                                      insert: '\n',
                                    },
                                  ],
                                },
                                image: {
                                  file:
                                    'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/5be17ca7-b321-47db-a02e-553a9c8a8e76/Placeholder-Solution-Image-wide',
                                },
                                heading: 'Solution Name Here',
                                linkText: '',
                                ctaSwitch: false,
                              },
                              {
                                cta: [{}],
                                url: '#',
                                text: {
                                  ops: [
                                    {
                                      insert: '\n',
                                    },
                                  ],
                                },
                                image: {
                                  file:
                                    'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/5be17ca7-b321-47db-a02e-553a9c8a8e76/Placeholder-Solution-Image-wide',
                                },
                                heading: 'Solution Name Here',
                                linkText: '',
                                ctaSwitch: false,
                              },
                              {
                                cta: [{}],
                                url: '#',
                                text: {
                                  ops: [
                                    {
                                      insert: '\n',
                                    },
                                  ],
                                },
                                image: {
                                  file:
                                    'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/5be17ca7-b321-47db-a02e-553a9c8a8e76/Placeholder-Solution-Image-wide',
                                },
                                heading: 'Solution Name Here',
                                linkText: '',
                                ctaSwitch: false,
                              },
                              {
                                cta: [{}],
                                url: '#',
                                text: {
                                  ops: [
                                    {
                                      insert: '\n',
                                    },
                                  ],
                                },
                                image: {
                                  file:
                                    'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/5be17ca7-b321-47db-a02e-553a9c8a8e76/Placeholder-Solution-Image-wide',
                                },
                                heading: 'Solution Name Here',
                                linkText: '',
                                ctaSwitch: false,
                              },
                              {
                                cta: [{}],
                                url: '/applications',
                                text: {
                                  ops: [
                                    {
                                      insert: '\n',
                                    },
                                  ],
                                },
                                image: {
                                  file:
                                    'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/4ee1751c-e1de-4826-9b00-ba40248d7e59/Card_-_More',
                                },
                                heading: 'Explore All Solutions',
                                linkText: '',
                                ctaSwitch: false,
                              },
                            ],
                            settings: {
                              format: 'auto',
                              cardSize: 'xl',
                              cardType: 'standard',
                              alignment: 'Left',
                              aspectRatio: {
                                custom: 1.5,
                                selection: 'custom',
                              },
                              itemsPerRow: '6',
                              containerSize: {
                                selection: 'xl',
                              },
                              imageSizeInfo: null,
                              textAlignment: 'left',
                              itemsPerRowMobile: '2',
                              itemsPerRowTablet: '4',
                            },
                            __versions: {
                              copy: '0.0.1',
                              cards: '0.0.1',
                            },
                            socialShare: false,
                            socialShareTip: null,
                          },
                          type: 'CardSet',
                        },
                      ],
                      moduleSeparator: false,
                    },
                    {
                      icon: 'akar-icons:shipping-box-01',
                      heading: t('product.paroc.related_products'),
                      iconImage:
                        'https://imagecdn.owenscorning.com/ocimages/image/upload/w_340,h_190/fl_no_overflow,l_text:Arial_38:340x190/placeholder',
                      defaultTab: false,
                      tabContent: [
                        {
                          data: {
                            copy: null,
                            data: {
                              dataset: 'products_paroc',
                              parameters: {
                                products_paroc: {
                                  items: [
                                    {
                                      heading: 'PAROC eXtra',
                                      prehead: 'General Insulation Slab',
                                      product: {
                                        reference: wrapRef('Product::Paroc',
                                          { type: 'MediaObjectSet',
                                            path: 'products/paroc-extra',
                                            name: 'PAROC Extra'
                                          })
                                      },
                                      linkText: 'View Product',
                                      description:
                                        'Ready-made slab widths for metal (cc600) and wood-framed structures (cc600 / cc 900 / cc1200) minimizes the cuttingwork and waste.',
                                      proper_name: 'PAROC eXtra',
                                    },
                                    {
                                      heading: 'PAROC eXtra plus',
                                      prehead: 'General Insulation Slab',
                                      product: {
                                        reference: wrapRef('Product::Paroc',
                                          { type: 'MediaObjectSet',
                                            path: 'products/paroc-extra-plus-testing',
                                            name: 'Paroc Extra Plus Testing'
                                          })
                                      },
                                      linkText: 'View Product',
                                      description:
                                        'PAROC eXtra plus is a multipurpose insulation slab (lambda 0,034 W/mK) for ventilated facades, pitched roofs and ventilated floors. In ventilated structures, the product is installed as a two-layer solution together with wind protection insulation.',
                                      proper_name: 'PAROC eXtra plus',
                                    },
                                  ],
                                  attributeSet: {
                                    name: 'Product Attributes',
                                    path: '',
                                  },
                                },
                              },
                            },
                            type: {
                              source: 'data',
                              'nullfield_d4b0e4a4-eb89-4fd0-990e-78bbeeb4832a': null,
                            },
                            settings: {
                              imgSize: 'small',
                              iconSize: 'large',
                              alignment: 'left',
                              rowBorder: null,
                              aspectRatio: {
                                custom: 1,
                                selection: '1',
                              },
                              itemsPerRow: '2',
                              containerSize: {
                                selection: 'xl',
                              },
                              itemsPerRowTablet: '2',
                            },
                            __versions: {
                              copy: '0.0.1',
                              mediaObjects: '0.0.1',
                            },
                            mediaObjects: [],
                            imageSizeInfo: null,
                          },
                          type: 'MediaObjectSet',
                        },
                      ],
                      moduleSeparator: false,
                    },
                  ],
                  settings: {
                    tabSize: 'small',
                    iconImage: 'text',
                    tabsAlign: 'left',
                  },
                  alignment: 'center',
                },
                type: 'TabbedContent',
                templated: true,
              },
            },
            user: {},
            order: [
              'template.tabbed_content'
            ],
          },
          __hidden: true,
        },
      },
      user: {},
      order: [
        'template.technical_information',
        'template.certifications',
        'template.promotions',
        'template.specifications_and_literature',
        'template.tools_and_calculators',
        'template.solutions_and_related_products',
      ],
    },
  },
  metadata: {
    settings: {
      general: {
        body_copy: {
          ops: [
            {
              insert:
                'Marketing body lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam enim eros, dignissim eget auctor eu, tincidunt ut mi. Ut blandit odio et lectus finibus ullamcorper. Etiam luctus elit pulvinar, feugiat ante fringilla, posuere nunc. Pellentesque a luctus turpis. In id semper mauris, ut varius odio. Sed ac erat ut orci finibus vehicula sit amet ac diam. In finibus dolor massa, non semper dolor dignissim vel. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In sodales magna justo, sed iaculis ante feugiat cursus. Ut blandit sodales orci, venenatis bibendum metus feugiat at. Ut tempus blandit aliquam.\nUpdated 10 Jan 2024',
            },
            {
              insert: {
                breaker: true,
              },
            },
            {
              insert: '\n',
            },
          ],
        },
        subheading: 'Optional Marketing Subhead',
        proper_name: 'Product Name',
        long_description:
          'Long description lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam enim eros, dignissim eget auctor eu, tincidunt ut mi. Ut blandit odio et lectus finibus ullamcorper. Etiam luctus elit pulvinar, feugiat ante fringilla, posuere.',
        short_description:
          'Short description lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam enim eros, dignissim eget auctor eu, tincidunt ut mi. Ut blandit odio et lectus finibus ullamcorper. Etiam luctus elit pulvinar, feugiat ante fringilla, posuere.',
      },
      images: {
        images: [
          {
            file:
              'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/b0673964-883f-42cc-9471-9db5dc9669de/Placeholder-Product-Image-hero',
          },
        ],
        hero_image: 0,
        list_item_image: 0,
      },
      availability: {
        status: "active",
        visibility: "listed",
        reducedBanner: reducedBannerDefaults(t, build.language)
      },
    },
    version: 2
  },
})
