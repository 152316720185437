export const studWoodOptions = [
  { text: '2” x 4”', value: '2x4' },
  { text: '2” x 6”', value: '2x6' },
];

export const studSpacingOptions = [
  { text: '16in. O.C.', value: '16' },
  { text: '24in. O.C.', value: '24' },
];

export const cavityInsulationTypeOptions = [
  { text: 'Mineral Wool', value: 'mineral-wool-insulation' },
  { text: 'Fiberglass', value: 'fiberglass-insulation' },
  { text: 'Spray Foam', value: 'spray-foam-insulation' },
];

export const TEXT = 'text';

export const cavityTextOrProductOptions = [
  { text: 'Enter Insulation R-Value', value: TEXT },
  { text: 'Choose Insulation Product', value: 'product' },
];

export const exteriorTextOrThicknessOptions = [
  { text: 'Enter Insulation R-Value', value: TEXT },
  { text: 'Choose XPS Insulation Thickness', value: 'product' },
];

export const studRvalues = {
  '2x4': 3.71,
  '2x6': 5.83,
};

export const outsideAirRValue = 0.17;
export const osbRValue = 0.62;
export const gypsumRValue = 0.45;
export const insideAirRValue = 0.68;

export const UPDATE_ACTION = 'UPDATE_INPUT_VALUE';
export const RESET_ACTION = 'RESET';
export const BEGIN_PRODUCT_FETCH_ACTION = 'BEGIN_PRODUCT_FETCH';
export const RETURN_PRODUCT_FETCH_ACTION = 'RETURN_PRODUCT_FETCH';
