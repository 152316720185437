import IconBase from './icon-base';

const IconZipFile = ({ className, fill="#000000", height="28px", hoverColor="#000000", width="24px" }) => (
  <IconBase className={className} fill={fill} height={height} hoverColor={hoverColor} viewBox="0 0 24 28" width={width}>
    <path d="M22.8703 6.13543C22.2754 5.32457 21.4457 4.37486 20.5354 3.46457C19.6251 2.55429 18.6754 1.72457 17.8646 1.12971C16.4829 0.116571 15.8126 0 15.4286 0H2.14286C0.961714 0 0 0.961714 0 2.14286V25.2857C0 26.4669 0.961714 27.4286 2.14286 27.4286H21.8571C23.0383 27.4286 24 26.4669 24 25.2857V8.57143C24 8.18743 23.8834 7.51714 22.8703 6.13543ZM19.3217 4.67829C20.1446 5.50114 20.7891 6.24171 21.2657 6.85714H17.1411V2.73429C17.7566 3.21086 18.5006 3.85543 19.3217 4.67829ZM22.2857 25.2857C22.2857 25.5189 22.0903 25.7143 21.8571 25.7143H2.14286C2.02961 25.713 1.92137 25.6674 1.84129 25.5873C1.7612 25.5072 1.71562 25.399 1.71429 25.2857V2.14286C1.71429 1.91143 1.91143 1.71429 2.14286 1.71429H15.4286V7.71429C15.4286 7.94161 15.5189 8.15963 15.6796 8.32038C15.8404 8.48112 16.0584 8.57143 16.2857 8.57143H22.2857V25.2857Z" fill="currentColor"/>
    <path d="M5.14288 1.71429H8.57145V3.42858H5.14288V1.71429ZM8.57145 3.42858H12V5.14287H8.57145V3.42858ZM5.14288 5.14287H8.57145V6.85715H5.14288V5.14287ZM8.57145 6.85715H12V8.57144H8.57145V6.85715ZM5.14288 8.57144H8.57145V10.2857H5.14288V8.57144ZM8.57145 10.2857H12V12H8.57145V10.2857ZM5.14288 12H8.57145V13.7143H5.14288V12ZM8.57145 13.7143H12V15.4286H8.57145V13.7143ZM5.14288 22.7143C5.14288 23.4206 5.72231 24 6.4286 24H10.7143C11.4206 24 12 23.4206 12 22.7143V18.4286C11.9987 18.088 11.8628 17.7618 11.622 17.5209C11.3811 17.2801 11.0549 17.1442 10.7143 17.1429H8.57145V15.4286H5.14288V22.7143ZM10.2857 20.5714V22.2857H6.85717V20.5714H10.2857Z" fill="currentColor"/>
  </IconBase>
);

export default IconZipFile;
