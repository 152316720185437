/* eslint-disable camelcase */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import querystring from 'qs';
import React, { useState } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

import SchemaTransformer from './Form/SchemaTransformer';
import createSizes from './utilities/sizes';
import useReference from '../../../hooks/use_reference';
import Theme from '../../../themes';
import CTAButton from '../../CTAButton';
import { IFramedForm } from '../../IFramedForm';
import { Screens } from '../../PageBuilder/editing/Viewport';
import { SchemaForm } from '../../forms/InnerSchemaForm';
import { canUseDOM } from '../../helpers/dom';
import { connectLocation } from '../../location/LocationConnectors';

const Sizes = createSizes();

const FormWrapper = styled.div`
  display: flex;
  justify-content: ${props =>
    props.alignment === 'Left' ? 'flex-start' : 'center'};
`;

const FormContainer = styled.div`
  margin: 0;
  width: ${({ width }) => `${width.Mobile}px`};
  @media (min-width: ${Screens.Tablet}px) {
    width: ${({ width }) => `${width.Tablet}px`};
  }
  @media (min-width: ${Screens.Desktop}px) {
    width: ${({ width }) => `${width.Desktop}px`};
  }
`;

export default connectLocation(
  ({
    t,
    locale: { code },
    containerSettings = { size: 'xl', alignment: 'Left' },
    form,
    form_uid,
    form_url,
    success_content = {},
    content,
    sandbox = false,
    test = false,
    success,
    ...props
  }) => {
    const [transformedSchemas, setTransformedSchemas] = useState();
    const { size, alignment } = containerSettings;
    const Width = Sizes[size || 'xl'];
    useDeepCompareEffectNoCheck(() => {
      if (content) {
        new SchemaTransformer(t, content.schema?.form, content.schema?.ui)
          .transformed()
          .then(values => {
            setTransformedSchemas(values);
          });
      }
    }, [content?.schema]);

    // let the form reference take precedence over the prop
    if (form?.reference) {
      // eslint-disable-next-line no-param-reassign
      form_uid = null;
      form_url = null;
    }
    const { results } = useReference(form?.reference);
    if (results) {
      // eslint-disable-next-line no-param-reassign
      form_uid = results?.metadata?.settings?.uid;
    }

    if (!form_uid && !content) return null;

    const { path } = props;
    const params = {
      ...(canUseDOM()
        ? querystring.parse(document.location.search.slice(1))
        : {}),
      initialWidth: 0,
      parentTitle: canUseDOM() ? document.title : '',
      parentUrl: canUseDOM() ? document.location.href : '',
      styling: 'oc-2020',
      lang: code,
      path: path?.join('.'),
      ...(test ? { test: true } : {}),
    };

    const query = `?${querystring.stringify(params)}`;

    if (form_url) {
      return (
        <IFramedForm
          scrollOnSuccess
          key={form_uid}
          // eslint-disable-next-line no-undef
          src={`${MDMS_URL}${form_url}${query}`}
        />
      );
    }
    const cssThemeOverrides = css`
      background: ${Theme.colors.brand};
      border: 1px solid ${Theme.colors.brand};
      font-family: ${Theme.fonts.body};

      &:hover,
      &:focus {
        color: ${Theme.colors.brand};
      }
    `;

    const additionalProps = {};
    if (content && transformedSchemas) {
      if (typeof Board !== 'undefined') {
        additionalProps.uid = [
          /* eslint-disable no-undef */
          Board.build.site,
          Board.build.language.toLowerCase(),
          Board.build.path,
          /* eslint-enable no-undef */
        ].join('/');
      }
      additionalProps.schema = transformedSchemas.schema;
      additionalProps.uiSchema = transformedSchemas.uiSchema;
      additionalProps.successContent = content.success_content;
    }

    return (
      <FormWrapper alignment={alignment}>
        <FormContainer width={Width} size={size}>
          <SchemaForm
            key={form_uid}
            uid={form_uid}
            search={query}
            success_content={success_content}
            // eslint-disable-next-line no-undef
            UI={UI}
            sandbox={sandbox}
            test={test}
            success={success}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...additionalProps}
          >
            <CTAButton
              className="oc-cta-button"
              css={cssThemeOverrides}
              element="button"
              text={t('submit')}
              type="submit"
            />
          </SchemaForm>
        </FormContainer>
      </FormWrapper>
    );
  }
);
/* eslint-enable camelcase */
