import styled from '@emotion/styled';
import { useMemo } from 'react';

import { truncateText } from '../../../../../helpers/text_truncate_helper';
import Theme from '../../../../../themes/index'
import RichText from '../../../../OC/PageBuilder/RichText';
import Text from '../../../../OC/PageBuilder/utilities/Text';
// eslint-disable-next-line import/order
import { H4 } from '../../../../OC/oc-h';

// helpers
import getFileExtension from '../../../../helpers/get_file_extension';
import getFileSize from '../../../../helpers/get_file_size';
import { Column, ColumnedSection, Info } from '../../../../helpers/grid_view';

// Add styled component to the top
const Container = styled.a`
  display: inline-block;
  position: relative;
  text-decoration: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:hover {
    > * {
      color: initial;
    }

    h4 {
      color: ${Theme.colors.brand};
    }
  }
`;

const DocAnchor = styled.a`
  text-decoration: none;
  display: block;
`;

const DocInfo = styled.span`
  color: #6d6d6d !important;
  font-size: 12px;
`;

const DocIcon = styled.i`
  color: ${props => props.color};
  padding-right: 8px;
`;

const DocLink = ({ document }) => {
  if (!document?.binary_url) return null;

  const { format, color, iconClass } = useMemo(() => {
    const format = getFileExtension(document.binary_url).toUpperCase();
    const color = format === 'PDF' ? '#CE1126' : '#000';
    const iconClass = format === 'PDF' ? 'fa fa-file-pdf-o' : 'fa-thin fa-file';
    return { format, color, iconClass };
  }, [document.binary_url]);

  return (
    <DocAnchor href={document.binary_url}>
      <DocIcon className={iconClass} color={color} />
      <span>{document.title}</span>{' '}
      <DocInfo>
        {format && document.size_bytes
          ? `${format} | ${getFileSize(document.size_bytes)}`
          : format || getFileSize(document.size_bytes) || ""}
      </DocInfo>
    </DocAnchor>
  );
};

const PfcItem = ({
  category,
  preheading,
  heading,
  text,
  url,
  document,
  smokeSealantComponent,
  hourlyFComponent,
}) => {
  return (
    <Container
      data-track="view-product"
      data-product-name={heading}
      href={url}
      title={heading}
    >
      {category}
      {heading && (
        <H4 prehead={preheading} tag="h4" font="body">
          <Text content={heading} />
        </H4>
      )}

      {document && <DocLink document={document} />}

      <RichText content={truncateText(text, 320)} />

      <ColumnedSection>
        <Column>
          {smokeSealantComponent.length > 0 && (
            <Info>
              <b>SSM</b> {smokeSealantComponent.join(', ')}
            </Info>
          )}
          {hourlyFComponent.length > 0 && (
            <Info>
              <b>Hourly Rating</b> {hourlyFComponent.join(', ')}
            </Info>
          )}
        </Column>
      </ColumnedSection>
    </Container>
  );
};

export default PfcItem;
