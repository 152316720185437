import { ui } from '@owenscorning/pcb.alpha';

import Product from './Product';
import { wrapRef } from '../../../../../data';

const PfcSelector = Product(
  'Product::PfcSelector',
  ui`AttributeSet`({
    query: {
      name: 'PFC Selector Attributes',
      path: '/',
    },
  }),
  {},
  {},
  {
    version: '0.1',
    displayName: 'PFC Selector',
    paths: ['insulation/commercial/pfc'],
  },
  [
    {
      title: 'Intro',
      unmovables: [true],
      modules: ['BasicContent', 'AttentionBar', 'Reusable'],
      initial: [
        {
          type: 'Reusable',
          data: {
            reference: wrapRef('Cms::Content', {
              type: 'Reusable',
              path: '',
              name: 'Thermafiber Insolutions Attention Bar',
            }),
          },
        },
        'BasicContent',
      ],
      showInSticky: false,
    },
    {
      title: 'Assembly Details',
      modules: ['Table', 'Figure', 'Accordion', 'BasicContent', 'Reusable'],
      initial: [
        {
          type: 'Table',
          data: {
            table: [
              {
                rows: [
                  [
                    {
                      content: {
                        ops: [
                          {
                            attributes: { bold: true },
                            insert: 'F Rating',
                          },
                        ],
                      },
                    },
                    {
                      content: {
                        ops: [
                          {
                            insert: '',
                          },
                        ],
                      },
                    },
                  ],
                  [
                    {
                      content: {
                        ops: [
                          {
                            attributes: { bold: true },
                            insert: 'T Rating',
                          },
                        ],
                      },
                    },
                    {
                      content: {
                        ops: [
                          {
                            insert: '',
                          },
                        ],
                      },
                    },
                  ],
                  [
                    {
                      content: {
                        ops: [
                          {
                            attributes: { bold: true },
                            insert: 'Linear Opening Width',
                          },
                        ],
                      },
                    },
                    {
                      content: {
                        ops: [
                          {
                            insert: '',
                          },
                        ],
                      },
                    },
                  ],
                  [
                    {
                      content: {
                        ops: [
                          {
                            attributes: { bold: true },
                            insert: 'L Rating at Ambient',
                          },
                        ],
                      },
                    },
                    {
                      content: {
                        ops: [
                          {
                            insert: '',
                          },
                        ],
                      },
                    },
                  ],
                  [
                    {
                      content: {
                        ops: [
                          {
                            attributes: { bold: true },
                            insert: 'L Rating at 400 F',
                          },
                        ],
                      },
                    },
                    {
                      content: {
                        ops: [
                          {
                            insert: '',
                          },
                        ],
                      },
                    },
                  ],
                ],
                type: 'Body',
              },
            ],
            alignment: 'Center',
            containerSize: {
              selection: 'm',
            },
          },
        },
        {
          type: 'Figure',
          data: {
            size: 'm',
          },
        },
        {
          type: 'Accordion',
          data: {
            alignment: 'Center',
            accordions: [
              {
                title: 'Floor Assembly',
              },
              {
                title: 'Curtain Wall Assembly',
              },
              {
                title: 'Safing System',
              },
            ],
            containerSize: {
              selection: 'l',
            },
          },
        },
      ],
      showHeading: true,
    },
    {
      title: 'Documents',
      modules: ['BasicContent', 'Accordion', 'Reusable', 'MediaObjectSet'],
      initial: ['MediaObjectSet'],
      showHeading: true,
    },
    {
      title: 'Contact Us',
      modules: ['BasicContent'],
      initial: [
        {
          type: 'Reusable',
          data: {
            reference: wrapRef('Cms::Content', {
              type: 'Reusable',
              path: '',
              name: 'PFC Selector Contact',
            }),
          },
        },
      ],
    },
  ],
  '',
  true
);
export default PfcSelector;
