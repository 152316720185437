import { css } from '@emotion/react';
import styled from '@emotion/styled';

const BareToolbar = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;

const StyledToolbar = styled(BareToolbar)`
  background-color: #FFFFFFF;
  border: 1px solid #585858;
  justify-content: flex-start;
  padding: 4px;
  position: sticky;
  top: 57px;
  z-index: 999;
`;

const Spacer = styled.span`
  background-color: #D1D1D1;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 1px;
`;

const MenuTool = styled.span`
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  padding: 2px 4px;
  position: relative;
  user-select: none;

  &:hover {
    background-color: #E6E6E6;
  }
`;

const ImageIcon = styled.div`
  background-image: url(${ (props) => props.icon });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 24px;
  width: 100%;
`;

const textColorIcon = (UI) => {
  let icon = UI.Theme.icons?.tools?.textColor || "Pink";
  return UI.Images.Tools[icon];
};

const Toolbar = ({ identifier, tools, bare }) => {
  const Component = bare ? BareToolbar : StyledToolbar;
  return (
    <Component id={`toolbar-${ identifier }`}>
      { tools.map((tool) => Toolbar.Tools(UI)[tool]) }
    </Component>
  );
};

Toolbar.Tool = ({ action, type, title, children }) => (
  <button
    className={ `ql-${ action }` }
    value={ type }
    title={title}
    css={css`
      align-items: center;
      border-radius: 2px;
      color: #000000;
      cursor: pointer;
      display: inline-block;
      height: 24px;
      justify-content: center;
      padding: 0;
      position: relative;
      text-align: center;
      width: 24px;

      * {
        pointer-events: none;
        user-select: none;
      }
    `}
  >
    { children }
  </button>
);

Toolbar.Tools = (UI) => ({
  spacer: <Spacer />,
  styles: (
    <UI.Belt
      hides
      openCSS={css` background-color: #585858; `}
      beltCSS={css`
        left: -4px;
        top: 28px;
      `}
    >
      <MenuTool>
        <img css={{ height: 12 }} src={ UI.Images.Tools.Styles } /> <UI.Icon type="angle-down" />
      </MenuTool>
      <div>
        {
          _.map({
            clean: <div css={css` font-size: 16px; `} >Body Copy</div>,
            'header_two_oswald': <h2>H2 Oswald</h2>,
            'header_two_roboto': <h2 className="body-font" >H2 Roboto</h2>,
            'header_three_oswald': <h3>H3 Oswald</h3>,
            'header_three_roboto': <h3 className="body-font" >H3 Roboto</h3>,
            'header_four_roboto': <h4>H4 Roboto</h4>,
            'header_five_roboto': <h5>H5 Roboto</h5>,
            'header_six_roboto': <h6>H6 Roboto</h6>,
            smaller: <div className="smaller" >Small Body Copy</div>,
            disclaimer: <div className="disclaimer" >Disclaimer</div>
          }, (label, name) => (
            <UI.Belt.Action styling={css`
              * {
                display: block;
                margin: 0 !important;
              }

              button {
                align-items: center;
                cursor: pointer;
                display: flex;
                padding: 8px 16px;
                text-align: left;
                width: 100%;
              }
            `}>
              <button className={ `ql-${ name }` } >{ label }</button>
            </UI.Belt.Action>
          ))
        }
      </div>
    </UI.Belt>
  ),
  bold: <Toolbar.Tool action="bold" title="Bold"><ImageIcon icon={UI.Images.Tools.Bold} /></Toolbar.Tool>,
  italic: <Toolbar.Tool action="italic" title="Italic"><ImageIcon icon={UI.Images.Tools.Italic} /></Toolbar.Tool>,
  superscript: <Toolbar.Tool action="script" type="super" title="Super Script"><ImageIcon icon={UI.Images.Tools.Superscript} /></Toolbar.Tool>,
  subscript: <Toolbar.Tool action="script" type="sub" title="Sub Script"><ImageIcon icon={UI.Images.Tools.Subscript} /></Toolbar.Tool>,
  pink: <Toolbar.Tool action="pink" title="Brand Color"><ImageIcon icon={ textColorIcon(UI) } /></Toolbar.Tool>,
  lists: (
    <UI.Belt
      beltCSS={css`
        left: -4px;
        top: 28px;
      `}
      hides
      openCSS={css`
        background-color: #585858;
      `}
    >
      <MenuTool>
        <UI.Icon type="list-ul" />
      </MenuTool>
      <div>
        {_.map([
          { action: "list", type: "bullet", title: "Unordered List", label: <><UI.Icon type="list-ul" /> Unordered</> },
          { action: "list", type: "ordered", title: "Ordered List", label: <><UI.Icon type="list-ol" /> Ordered</> },
          { action: "indent", type: "+1", title: "Indent", label: <><UI.Icon type="indent" /> Indent</> },
          { action: "indent", type: "-1", title: "Unindent", label: <><UI.Icon type="outdent" /> Unindent</> }
        ], (object) => (
          <UI.Belt.Action styling={css`
            button {
              align-items: center;
              cursor: pointer;
              display: flex;
              font-size: 16px;
              height: auto;
              justify-content: flex-start;
              padding: 8px 16px;
              width: 100%;

              span {
                margin-right: 8px;
              }
            }
          `}>
            <Toolbar.Tool action={object.action} type={object.type} title={object.title}>{object.label}</Toolbar.Tool>
          </UI.Belt.Action>
        ))}
      </div>
    </UI.Belt>
  ),
  link: <Toolbar.Tool action="link" title="Link"><ImageIcon icon={ UI.Images.Tools.Link } /></Toolbar.Tool>,
  document: <Toolbar.Tool action="document" title="Document"><ImageIcon icon={ UI.Images.Tools.Document } /></Toolbar.Tool>
});

export default Toolbar;
