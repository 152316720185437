import { css } from '@emotion/react';

export default ({ name, avatar }) => <>
  <div css={css`
    background-color: #CCCCCC;
    ${ avatar?.image_48 ? `background-image: url(${ avatar.image_48 });` : '' }
    background-size: contain;
    border-radius: 50%;
    height: 40px;
    margin-right: 16px;
    width: 40px;
  `} />
  <div css={css` padding-right: 16px; `}>{ name }</div>
  <UI.Belt beltCSS={css`left: auto; right: 0;`}>
    <div css={css`
      background-image: url(${ UI.Images.ChevronDown });
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      height: 13px;
      user-select: none;
      width: 24px;
    `} />
    <UI.Belt.Action
      onClick={ () => window.location = '/global/users/logout' }
      styling={css`
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 8px;
        padding: 8px 16px;
      `}
    >
      <UI.Icon type="sign-out" /> Logout
    </UI.Belt.Action>
  </UI.Belt>
</>;
