import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import Content from './Content';
import FileBadge from '../../../OC/oc-file-badge';
import MediaObject from '../../../OC/PageBuilder/MediaObject';
import { useCallback } from 'react';
import _ from 'lodash';
import { toast } from '../../../PageBuilder/helpers/toast';
import mime from 'mime-types';
import { wrapRef } from "../../../../data";

const available_types = () => {
  const common_types = {
    upload: 'Upload',
    external: 'External (URL)',
  };

  if (PB_SITE === 'www.owenscorning.com') {
    return {
      dms: 'DMS',
      ...common_types
    }
  }

  return common_types
}

const Document = Content(
  'Document',
  (UI) => ({
    version: '0.1',
    showShare: false,
    displayName: 'Document',
    paths: ['documents/uploads'],
    read: ({contents, metadata}) => {
      if (Array.isArray(contents)) {
        contents = {}
      }
      if (contents?.type === 'external' && typeof(contents.external) === 'undefined') {
        contents = _.cloneDeep(contents)
        contents.external = {
          url: contents.external_url,
        }
      }
      return { contents, metadata };
    },
    sidebar: {
      builder: ui`Form`.of({
        type: ui`Choices`.of(available_types())({
          label: 'Type',
          default: 'upload',
        }),
        document: [
          ({UI, Board, path, value, onChange, ...props}) => {
            const onUploaded = useCallback(val => {
              if (!val) {
                Board.Change(val, _.concat(_.initial(path), 'thumbnail'));
              } else {
                const { url } = val;
                const thumbUrl = url.replace(/\/file$/, '/thumb')
                fetch(thumbUrl, { method: 'HEAD' }).then(response => {
                  if (response.status === 404) {
                    toast.warn('Thumbnail not available, please upload an image for thumbnail.')
                  } else {
                    Board.Change(thumbUrl, _.concat(_.initial(path), 'thumbnail'));
                  }
                })
              }
              onChange(val)
            }, [onChange]);
            return (
              <UI.Upload.Document UI={ UI } Board={ Board } path={ path } value={ value } onChange={ onUploaded } { ...props } detailed />
            )
          },
          {
            label: 'Upload',
            visible: when`../type`.is.equal.to('upload'),
          }
        ],
        dms: ui`Form`.of(
          ui`Data/Parameters`.of('BuilderDocument')({ dataset: 'documents', unwrapped: true })
        )({
          label: 'Select Details',
          visible: when`../type`.is.equal.to('dms')
        }),
        external: ui`Form`.of({
          url: [
            ({UI, Board, path, value, onChange, ...props}) => {
              const onChanged = useCallback(val => {
                Board.Change(val.mime_type, _.concat(_.initial(path), 'mime_type'));
                Board.Change(val.size_bytes, _.concat(_.initial(path), 'size_bytes'));
                onChange(val.url);
              }, [onChange]);
              return (
                <UI.Url MODE="edit" UI={ UI } Board={ Board } path={ path } value={ value } onChange={ onChanged } { ...props } validate detailed />
              )
            },
            {
              label: 'External URL',
              external: true
            }
          ],
          link_text: ui`Text`({
            label: 'Featured Link Text',
          }),
        })({
          visible: when`../type`.is.equal.to('external'),
        }),
        thumbnail: ui`Upload/Image`({ label: 'Thumbnail' }),
      }),
      settings: {
        noIndex: ui`Switch`({ label: 'No Index' }),
        attributes: ui`AttributeSet`({
          query: {
            name: 'Document Attributes'
          },
          controlled: 'value'
        })
      }
    },
    view: Document.Renderer(UI),
  })
)

Document.Renderer = (UI, parameters={}) => ({
  contents: ({ value }) => {
    const { type, external, document, dms } = value || {};
    const { url, link_text, mime_type, size_bytes } =
      type === 'upload' ? (document || {}) :
        type === 'dms' ? (dms || {}) :
          (external || {});
    const thumbnail = type === 'dms' ? dms?.thumbnail : value?.thumbnail
    const heading = Board.settings.attributes?.title;
    const text = Board.settings.attributes?.short_description;
    const extension = mime.extension(mime_type)
    const analytics = {
      track: 'file-download',
      'file-name': Board.settings.attributes?.title,
      'doc-type': (Board.settings.attributes?.['content-type']||[]).join(','),
      'file-type': extension,
      'element-location': 'main section text link'
    }

    PB_LINK_META[url] = { analytics };
    const mo = {
      prehead: '',
      imgSize: 'document',
      aspectRatio: '0.77',
      image: thumbnail ? {
        alt: Board.settings.attributes?.title,
        file: thumbnail
      } : null,
      url: url,
      heading: heading,
      text: text,
      target: '_blank',
      linkText: type === 'external' ? (link_text||url) : <FileBadge extension={ extension } sizeBytes={ size_bytes } />,
      analytics
    }
    const docObj = (type === "dms" ? {document: "", title: "", description: "", url: "", ...dms } : external) || {}
    return (
      <Content.PreventClicks>
        <h4>Inline Link</h4>
        <div>
          <UI.Text.Rich singular key={ type } content={ {
            ops:  ['external', 'dms'].includes(type) || (type === "upload" && document?.url)? [{
                insert: {
                  document: ['external', 'dms'].includes(type) ? {
                    title: heading,
                    external: docObj,
                  } : {
                    title: Board.settings.attributes?.title,
                    document: wrapRef('Cms::Asset', document.url.match(/\/assets\/(.*)\/file/)[1])
                  }
                }
              } ] : [] } } />
        </div>
        <br />
        <h4>Featured</h4>
        <div>
          <MediaObject { ...mo } />
        </div>
      </Content.PreventClicks>
    )
  }
});

export default Document;
