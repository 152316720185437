import { stripJoinPath } from '../../../helpers/joinPath';

export const joinPath = stripJoinPath;
export const formatEditLink = (contentId, locale) =>
  // eslint-disable-next-line no-undef
  `/en-us/cms/contents/${PB_TYPE}/edit/${contentId}/${locale.toLowerCase()}`;
export const formatViewDraftLink = (contentId, locale) =>
  // eslint-disable-next-line no-undef
  `/en-us/cms/contents/${PB_TYPE}/preview/${contentId}/${locale.toLowerCase()}`;
export const formatViewPublishedLink = (content, locale) =>
  `/${joinPath(locale.toLowerCase(), content.path, content.slug)}`;
