import { ui, when, s, always } from '@owenscorning/pcb.alpha';
import queryProducts from './query_legacy_products';
import ProductItem from './ProductItem';
import _ from 'lodash';
import { expandRef, wrapRef } from '../../../../../data';
import useReference from '../../../../../hooks/use_reference';
import itemListResponse, { generateFilterOptions } from "./item_list_response";
import { digChoicesFromData } from '../../../Library/AttributeSet';
import retrieveImage from './retrieveImage';
import cms_api from '../../../../../cms_api';
import pathToContent from './path_to_content';

// This method will receive the Board element and the path where the associatedProduct setting is expected to be located
// at. This method does a bit of magic, by knowing that it will need to mutate (Boad.change) the documents on each associated
// product level path, i.e. metadata->settings->associatedProducts->items->index->documents.
const upsertRelatedDocuments = (Board, path) => {
  _.get(Board.Value, _.concat(path, 'items'), []).forEach((item, index) => {
    expandRef(item.product).then(resolvedProduct => {
      const productDocuments = getProductDocuments(resolvedProduct);
      let visibilityDocuments = item.documents;
      if (visibilityDocuments?.length > 0) {
        let changed = false;
        visibilityDocuments.forEach((document, index) => {
          // Searching for the inclusion of a document type element
          if (document.document) {
            if (!productDocuments.some(productDocument => productDocument.document && productDocument.document.__ref === document.document.__ref)) {
              visibilityDocuments = visibilityDocuments.splice(index, index);
              changed = true;
            }
          } else { // Searching for a link
            if (!productDocuments.some(productDocument => !productDocument.document && productDocument.link === document.link)) {
              visibilityDocuments = visibilityDocuments.splice(index, index);
              changed = true;
            }
          }
        })
        productDocuments.forEach((document, index) => {
          // Searching for the inclusion of a document type element
          if (document.document) {
            if (!visibilityDocuments.some(visibilityDocument => visibilityDocument.document && visibilityDocument.document.__ref === document.document.__ref)) {
              visibilityDocuments.push(document);
              changed = true;
            }
          } else { // Searching for a link
            if (!visibilityDocuments.some(visibilityDocument => !visibilityDocument.document && visibilityDocument.link === document.link)) {
              visibilityDocuments.push(document);
              changed = true;
            }
          }
        })
        if (changed) {
          Board.Change(visibilityDocuments, _.concat(path, 'items', index, 'documents'));
        }
      } else {
        Board.Change(productDocuments || [], _.concat(path, 'items', index, 'documents'));
      }
    })
  })
}

const getProductDocuments = (product) => {
  // It is internal knowledge that all PDPs store their documents within metadata->settings->documents->categories, and these internally
  // have a path of documents->index->document->__ref
  const categories = _.get(product, ['metadata', 'settings', 'documents', 'categories']) || []
  const documents = []
  categories.forEach((category) => {
    category.documents.forEach(document => {
      if (documents.some(existingDocument => document?.document && existingDocument?.document && document.document.__ref === existingDocument.document.__ref))
      return;

      documents.push(document);
    })
  })
  return documents;
}

const ProductItemList = (options) => {
  /*
{
  common: {
    type: 'Product::Insulation'
  },
  search: {
    name: 'Insulation (Residential) Products',
    preview: result => ({
      preheading: result.category,
      heading: result.proper_name
    })
  },
  list: {
    name: 'Products - Insulation (Residential)',
    attributeSet: {
      name: ...,
      path: ...
    }
    dataset: 'products_insulation',
    view: ProductListItem,
    filterData: (filter) => ({})
  }
}
   */
  const searchFields = ['name',
    ['metadata', 'settings', 'attributes', 'application'],
    ['metadata', 'settings', 'attributes', 'product_type'],
    ['metadata', 'settings', 'attributes', 'audience'],
    ['metadata', 'settings', 'attributes', 'standards'],
    ['metadata', 'settings', 'attributes', 'tprs_area'],
    ['metadata', 'settings', 'general', 'proper_name'],
    ['metadata', 'settings', 'general', 'short_description'],
    ['metadata', 'settings', 'general', 'subheading'],
    ['metadata', 'settings', 'meta', 'description'],
    ['metadata', 'settings', 'meta', 'keywords'],
    ['metadata', 'settings', 'meta', 'title'],
  ]

  const productItemListResponse = (items, showFilters, enabledFilters, allFilters) => {
    let results = items;
    if(items && !Array.isArray(items)) {
      results = items.results;
    }
    return itemListResponse(
      {
        items: results,
        filters: generateFilterOptions(showFilters, enabledFilters, allFilters, options.list.filterData),
        Component: options.list.view,
        searchFields,
        enableSearch: options.list.enableSearch,
      }
    )
  }

  let searchAbortController = new AbortController();
  const availableIn = options?.list?.availableIn || ['www.owenscorning.com'];

  return {
    Search: {
      name: options.search.name,
      meta: {},
      search: async ({ filter } = {}) => {
        if (!filter) {
          return null;
        }
        if (searchAbortController) searchAbortController.abort();
        searchAbortController = new AbortController();
        return (await queryProducts(options.common.type, Board.build.language, filter, { signal: searchAbortController.signal })).map(result => (
            {
              slug: result.route,
              category: options.common.type,
              language: result.language_iso_code,
              'UID': result.content_uuid,
              Name: result.metadata?.settings?.general?.proper_name || result.name
            }
        ))
      },
      preview: (result) => result && <ProductItem
        { ...(options.search.preview(result)) }
        img={
          _.get(result, ['metadata', 'settings', 'images', 'images', _.get(result, 'metadata.settings.images.list_item_image')])?.file
        }
      />
    },
    ItemList: {
      availableIn: options.list.availableIn,
      name: options.list.name,
      meta: async () => {
        const query = {
          filter: {
            type: 'Cms::Content::AttributeSet',
            name: options.list.attributeSet.name,
            route: options.list.attributeSet.path?.[0] == '/' ? options.list.attributeSet.path : `/${options.list.attributeSet.path}`,
          },
          fields: {
            '*': 'contents,metadata',
          },
        };
        const data = await cms_api.get_single_for_language(query, Board.build.language)
        const hasFilters = !!data;
        const filterChoices = hasFilters ? await digChoicesFromData('metadata.settings.attributes', data) : null;
        const taxonomies = hasFilters ? Object.fromEntries(Object.entries(filterChoices).map(([key, entry]) => [key, entry.label])) : {}
        const filters = hasFilters ? ui`Form`.of(
            Object.fromEntries(Object.entries(filterChoices).map(([key, { of, ...entry }]) => ([
              key,
              ui`Choices`.of(of)({ ...entry, multiple: true, includeParentTree: false })
            ])))
        ) : null
        const defaultEnabledFilterKeys = Object.keys(taxonomies).filter(x => x !== 'metadata.settings.attributes.tprs_area');
        const enabledFilters = hasFilters ? ui`ChoicesDraggable`.of(taxonomies)({
          // ** here is where we need to add the filter UI type
          label: 'Filters',
          visible: when`../showFilters`.is.equal.to(true).then(true).otherwise(false),
          default: {
            enabledFilters: defaultEnabledFilterKeys,
            orderedList: Object.keys(taxonomies).map(k => ({[k]: defaultEnabledFilterKeys.indexOf(k) >= 0}))
          },
        }) : null
        return {
          productSource: ui`Choices`.of({
            'all': 'All Items',
            'filtered': 'Filtered',
            'specific': 'Select Specific Items'
          })({
            label: 'Select Structure',
            default: 'all',
            mode: ui`Choices/Mode/Dropdown`
          }),
          attributeSet: ui`Form`.of(
            _.mapValues(options.list.attributeSet, v => always(v))
          )({
            default: options.list.attributeSet,
            visible: false
          }),
          filters: ui`List/Item`.of(filters)({
            standalone: true,
            title: 'Product Attributes',
            label: 'Filters',
            visible: when`../productSource`.is.equal.to('filtered')
          }),
          items: ui`List`.of({
            product: ui`Search`({
            startOpen: when`../product`.isnt.present.then(true).otherwise(false),
            label: 'Product',
            dataset: options.list.dataset,
            set: (value, path) => {
              // TODO: handle empty/null value?
              const ref = wrapRef('Cms::Content', { type: options.common.type, id: value.UID })
              expandRef(ref).then(result => {
                Board.Change(result.metadata?.settings?.general?.proper_name, _.concat(_.initial(path), 'proper_name'));
              })
              return ref;
            },
            get: (value) => {
              const { results, error, loading } = useReference(value);
              if (loading) {
                return <i>Loading...</i>
              } else if (error) {
                return <span>{ error }</span>
              }
              return results;
            }
          }),
          })({
            singular: 'Product',
            title: 'proper_name',
            label: 'Products',
            visible: when`../productSource`.is.equal.to('specific').then(true).otherwise(false)
          }),
          showFilters: ui`Switch`({
            label: 'Filter Pane',
            default: hasFilters,
            disabled: !hasFilters,
          }),
          [s._]: ui`Tip`.of(`Cannot find AttributeSet <b>${[options.list.attributeSet.path, options.list.attributeSet.name].join('/')}</b> in a matching language`)({
            visible: !hasFilters,
          }),
          [s._]: ui`Tip`.of('If the item list you wish to display is to be very long, we recommend turning on the Filter Side Pane to allow users to filter down the results to their needs.')({
            visible: hasFilters,
          }),
          enabledFilters
        }
      },
      view: (data = null) => {
        const { data: items, meta: { parameters, filters } } = (data || { meta: {} });
        const { /*productSource, filters, items=[],*/ showFilters, enabledFilters } = parameters || {};
        return productItemListResponse(items || [], showFilters, enabledFilters, filters || {})
      }
    },
    MediaObjectSet: {
      availableIn: options.list.availableIn,
      name: options.list.name,
      meta: async () => {
        return {
          attributeSet: ui`Form`.of(
            _.mapValues(options.list.attributeSet, v => always(v))
          )({
            default: options.list.attributeSet,
            visible: false
          }),
          items: ui`List`.of({
            product: ui`Search`({
              startOpen: when`../product`.isnt.present.then(true).otherwise(false),
              label: 'Product',
              dataset: options.list.dataset,
              set: (value, path) => {
                const ref = wrapRef('Cms::Content', { type: options.common.type, id: value.UID })
                expandRef(ref).then(result => {
                  Board.Change(result?.metadata?.settings?.general?.proper_name, _.concat(_.initial(path), 'proper_name'));
                  Board.Change(result?.metadata?.settings?.general?.subheading || _.get(result, 'metadata.settings.attributes.tprs_area'), _.concat(_.initial(path), 'prehead'));
                  Board.Change(result?.metadata?.settings?.general?.proper_name, _.concat(_.initial(path), 'heading'));
                  Board.Change(result?.metadata?.settings?.general?.short_description, _.concat(_.initial(path), 'description'));
                  Board.Change('View Product', _.concat(_.initial(path), 'linkText'));
                })
                return ref;
              },
              get: (value) => {
                const { results, error, loading } = useReference(value);
                if (loading) {
                  return <i>Loading...</i>
                } else if (error) {
                  return <span>{error}</span>
                }
                return results;
              }
            }),
            prehead: ui`Text`({
              label: 'Product Prehead',
              controlled: 'value',
              visible: when`../product`.is.present.then(true).otherwise(false)
            }),
            heading: ui`Text`({
              label: 'Product Title',
              controlled: 'value',
              visible: when`../product`.is.present.then(true).otherwise(false)
            }),
            description: ui`Text`({
              label: 'Product Description',
              controlled: 'value',
              visible: when`../product`.is.present.then(true).otherwise(false)
            }),
            linkText: ui`Text`({
              label: 'Text for action link',
              controlled: 'value',
              visible: when`../product`.is.present.then(true).otherwise(false),
            })
          })({
            singular: 'Product',
            title: 'proper_name',
            label: 'Products',
          }),
        }
      },
      old_view: async ({ items } = []) => {
        if (_.isEmpty(items)) return
        const results = items?.map(async (x) => {
          if (!x?.product) return
          const product = await expandRef(x?.product)
          if (!product) return
          return {
            image: {
              file: retrieveImage(product)?.file || '/',
              alt: retrieveImage(product)?.alt || ''
            },
            prehead: x?.prehead,
            heading: x?.heading,
            text: x?.description,
            target: "_blank",
            url: product?.metadata?.availability?.status === 'external' ? product?.metadata?.availability?.externalUrl : pathToContent(product),
            linkText: x?.linkText
          }

        })
        return Promise.all(results)
      }
    },
    ProductsInSolution: {
      availableIn,
      name: options.list.name,
      meta: async () => {
        // Always calculate on initial load the related documents, since the related products may have suffered
        // changes that do not automatically propagate here.
        upsertRelatedDocuments(Board, path);
        return {
          items: ui`List`.of({
            product: ui`Search`({
              startOpen: when`../product`.isnt.present.then(true).otherwise(false),
              label: 'Product',
              dataset: options.list.dataset,
              set: (value, path) => {
                const ref = wrapRef('Cms::Content', { type: options.common.type, id: value.UID })
                expandRef(ref).then(result => {
                  Board.Change(result?.metadata?.settings?.general?.proper_name, _.concat(_.initial(path), 'proper_name'));
                  Board.Change(result?.metadata?.settings?.general?.subheading || _.get(result, 'metadata.settings.attributes.tprs_area'), _.concat(_.initial(path), 'prehead'));
                  Board.Change(result?.metadata?.settings?.general?.proper_name, _.concat(_.initial(path), 'heading'));
                  Board.Change('View Product Details', _.concat(_.initial(path), 'linkText'));
                  // Add all documents by default to the list
                  Board.Change(getProductDocuments(result), _.concat(_.initial(path), 'documents'));
                })
                return ref;
              },
              get: (value) => {
                const { results, error, loading } = useReference(value);
                if (loading) {
                  return <i>Loading...</i>
                } else if (error) {
                  return <span>{error}</span>
                }
                return results;
              }
            }),
            prehead: ui`Text`({
              label: 'Product Prehead',
              controlled: 'value',
              visible: when`../product`.is.present.then(true).otherwise(false)
            }),
            heading: ui`Text`({
              label: 'Product Title',
              controlled: 'value',
              disabled: true,
              visible: when`../product`.is.present.then(true).otherwise(false)
            }),
            linkText: ui`Text`({
              label: 'Text for action link',
              controlled: 'value',
              visible: when`../product`.is.present.then(true).otherwise(false),
            }),
            documents: ui`List`.of({
              document: ui`Search`({
                startOpen: false,
                label: 'Document',
                dataset: 'documents',
                editable: false,
                get: (value) => {
                  const { results, error, loading } = useReference(value);
                  if (loading) {
                    return <i>Loading...</i>
                  } else if (error) {
                    return <span>{ error }</span>
                  }
                  return results;
                }
              }),
              show_document: ui`Switch`({
                label: 'Display document',
                default: true
              }),
            })({
              controlled: 'value',
              label: 'Related Documents',
              hideable: false,
              immutable: true,
              singular: 'Document',
              title: 'title',
              visible: when`../product`.is.present.then(true).otherwise(false)
            }),
          })({
            singular: 'Product',
            title: 'proper_name',
            label: 'Products',
            max: 10,
          }),
        }
      },
      fetch_data: async ({ items = [], documentGeneratorFn }) => {
        if (_.isEmpty(items)) return
        const results = items.map(async (x) => {
          if (!x?.product) return
          const product = await expandRef(x?.product)
          if (!product) return
          const dataSheet = _.get(
            _.filter(
              _.get(product, ['metadata', 'settings', 'documents', 'categories']),
              {category: 'data-sheets'}
            ),
            [0, 'documents', 0]
          )
          return {
            analytics: {
              'track': true,
              'data-track': 'learn-more',
              'data-track-destination': product.metadata?.settings?.general?.proper_name
            },
            image: {
              file: retrieveImage(product)?.file || '/',
              alt: retrieveImage(product)?.alt || ''
            },
            prehead: x?.prehead,
            heading: product.metadata?.settings?.general?.proper_name,
            target: "_blank",
            url: product.metadata?.availability?.status === 'external' ? product.metadata?.availability?.externalUrl : pathToContent(product),
            linkText: x?.linkText,
            dataSheet: dataSheet ? {ops: documentGeneratorFn(dataSheet) } : null,
          }

        })
        return Promise.all(results)
      }
    },
  }
}

export default ProductItemList;
