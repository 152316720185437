import def_0 from "./attribute_sets";
import def_1 from "./attributes";
import def_2 from "./bim";
import def_3 from "./color_pairing";
import def_4 from "./document_builder";
import def_5 from "./documents";
import def_6 from "./forms";
import def_7 from "./helpers/ArticleItemList";
import def_8 from "./helpers/BimProductGroupModal";
import def_9 from "./helpers/GenericContentSearch";
import def_10 from "./helpers/PfcItem";
import def_11 from "./helpers/ProductItem";
import def_12 from "./helpers/ProductItemList";
import def_13 from "./helpers/Shingle";
import def_14 from "./helpers/handle_external_url";
import def_15 from "./helpers/item_list_response";
import def_16 from "./helpers/path_to_content";
import def_17 from "./helpers/query_legacy_products";
import def_18 from "./helpers/retrieveImage";
import def_19 from "./insulation_applications_blog";
import def_20 from "./insulation_industrial_blog";
import def_21 from "./insulation_residential_blog";
import def_22 from "./iss";
import def_23 from "./lumber_blog";
import def_24 from "./paroc_articles";
import def_25 from "./products_enclosure";
import def_26 from "./products_insulation";
import def_27 from "./products_paroc";
import def_28 from "./products_pfc";
import def_29 from "./products_roofing";
import def_30 from "./reusable";
import def_31 from "./roofing_blog";
import def_32 from "./shingles";



export default {
  "attribute_sets": def_0,
  "attributes": def_1,
  "bim": def_2,
  "color_pairing": def_3,
  "document_builder": def_4,
  "documents": def_5,
  "forms": def_6,
  "helpers": {
    "ArticleItemList": def_7,
    "BimProductGroupModal": def_8,
    "GenericContentSearch": def_9,
    "PfcItem": def_10,
    "ProductItem": def_11,
    "ProductItemList": def_12,
    "Shingle": def_13,
    "handle_external_url": def_14,
    "item_list_response": def_15,
    "path_to_content": def_16,
    "query_legacy_products": def_17,
    "retrieveImage": def_18
  },
  "insulation_applications_blog": def_19,
  "insulation_industrial_blog": def_20,
  "insulation_residential_blog": def_21,
  "iss": def_22,
  "lumber_blog": def_23,
  "paroc_articles": def_24,
  "products_enclosure": def_25,
  "products_insulation": def_26,
  "products_paroc": def_27,
  "products_pfc": def_28,
  "products_roofing": def_29,
  "reusable": def_30,
  "roofing_blog": def_31,
  "shingles": def_32
};