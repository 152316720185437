import styled from "@emotion/styled";

export const Column = styled.div`
  width: 100%;

  @media(min-width: 768px) {
    width: 50%;
  }
`;

export const ColumnedSection = styled.div`
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  @media(min-width: 768px) {
    gap: 16px;
    flex-direction: row;
  }
`;

export const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  display: block;

  strong {
    margin-left: 8px;
  }
`;
