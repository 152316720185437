import { connectLocation } from '../../location/LocationConnectors';
import RichText from "../PageBuilder/RichText";
import Text from "../PageBuilder/utilities/Text";
import styled from "@emotion/styled";

const HeaderLink = styled.a`
  color: #000000;
  text-decoration: none;

  &:hover {
    color: #000000;
    text-decoration: underline;
  }
`;

const Link = connectLocation(({t, item, mobile}) => (
  mobile ?
    <a href={ item.url } className="nav-link" data-track="nav-click">
      <Text content={ item.title } />
    </a>
  :
    <a href={ item.url } className="nav-link" data-track="nav-click" aria-label={ item.title }>
      <Text content={ item.title } />
      { item.is_new && <span className="item-state">{ t('meganav.new') }</span> }
    </a>
));

const Subcolumn = ({item, mobile}) => (
  <div className="column-title">
    <h4><Text content={ item.title } /></h4>
  </div>
)

const Header = ({item, mobile}) => (
  <h6 className="group-heading">
    { item.url?.trim()
      ? <HeaderLink href={item.url}><Text content={item.title} /></HeaderLink>
      : <Text content={item.title} />
    }
  </h6>
);

const Card = connectLocation(({t, item, mobile}) => (
  <a href={ item.url } className="image-parent">
    { item.is_new && <span className="item-state-label">{ t('meganav.new') }</span> }
    <div className="image-container">
      <img src={ item.image?.source || item.image?.file } alt={ item.image?.alt } className="image-display" decoding="async" loading="lazy" />
    </div>
    <div className="image-heading">
      <Text content={ item.title } />
    </div>
    <div className="image-subheading">
      <Text content={ item.description?.toString() } />
    </div>
  </a>
));

const Decal = ({item, mobile}) => (
  !mobile &&
    <picture>
      <source media="(min-width: 1200px)" srcSet={ item.image?.source || item.image?.file } />
      <img src="#" alt={ item.image?.alt } width={ item.width } height={ item.height } className="decal" style={{ right: `${item.right }px`, bottom: `${ item.bottom }px` }} decode="async" loading="lazy" />
    </picture>
);

const ArticlePreview = connectLocation(({t, item, mobile}) => (
  <div className="article-preview">
    <div className="article-preview-text">
      <RichText content={ item.description } />
    </div>
    <a className="article-preview-button nav-link chevron-link" href={ item.url }>
      <Text content={ item.title } />
    </a>
  </div>
));

const LinkTypes = {
  text: Link,
  header: Header,
  card: Card,
  decal: Decal,
  'article-preview': ArticlePreview,
  subcolumn: Subcolumn
};

export default ({items, mobile}) => (
  <>
    {items.map((item,i) => {
      const Component = LinkTypes[item.link_type];
      return Component && <Component key={ i } item={ item } mobile={ mobile } />
    })}
  </>
);
