import React, { useEffect, useState } from 'react';
import { connectLocation } from "../../../location/LocationConnectors";
import wrappedInReduxProvider from "../../../wrappedInReduxProvider";
import styled from "@emotion/styled";

// Generic Components
import FilterOpener from '../../oc-filter-opener';
import FilterChips from '../../filter-list/oc-filter-chips';
import { Loader } from "../../../BuildYourRoof/loading"

// Content Components
import ContentHeader from './ContentHeader';
import FilterContainer from './FilterContainer';
import PrimaryContentContainer from './PrimaryContentContainer';
import SecondaryContentContainer from './SecondaryContentContainer';

const TAB_PARAM = 'tab'
const PRIMARY = 'primary'
const SECONDARY = 'secondary'
export const TABS = { TAB_PARAM, PRIMARY, SECONDARY }

// Styled Components
const DesktopFilterControls = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.5em;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1200px) {
    display: ${props => props.visible ? 'flex' : 'none !important'};
    padding-bottom: 2em;
  }
`;

const FilterLink = styled(FilterOpener)`
  margin: 0 32px 0 0;

  @media(max-width: 1199px) {
    display: inline-block;
  }
`;

const ContainerElement = styled.div`
  display: flex;
  justify-content: space-between!important;
  margin-bottom: 4em;
  position: relative;
`;

const LoaderContent = styled(Loader)`
  align-items: first baseline;
  padding-top: 90px;
`;

function shouldShowDesktopFilterControls(filterChipsVisible, primaryActive) {
  if (filterChipsVisible === true || filterChipsVisible === 'true') {
    return true;
  }
  if (filterChipsVisible === false || filterChipsVisible === 'false') {
    return false;
  }
  if (filterChipsVisible === PRIMARY) {
    return primaryActive;
  }
  if (filterChipsVisible === SECONDARY) {
    return !primaryActive;
  }
  if (Array.isArray(filterChipsVisible)) {
    return filterChipsVisible.includes(primaryActive ? PRIMARY : SECONDARY);
  }
  return !!filterChipsVisible;
}

const FilterableListContainer = connectLocation(({
  t,
  headerContent,
  sortContent,
  allowSearch,
  filterHeading = "Filters",
  resultCount,
  appliedFilters,
  filters,
  filterContent,
  filterReset,
  primaryActiveDefault = true, // primaryActiveDefault: <boolean> – Set default value for which content section is active
  primaryContent,              // primaryContent: <Component> – Left pane content, ex: filtered results list
  secondaryContent,            // secondaryContent: <Component> – Right pane content, ex: locations map
  noResultContent,
  appliedFilterLabel,
  filterChipsVisible = SECONDARY, // true/false, or PRIMARY/SECONDARY, an array is also fine [PRIMARY, SECONDARY]
  loading
}) => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [primaryActive, setPrimaryActive] = useState(primaryActiveDefault);
  const [secondaryActive, setSecondaryActive] = useState(!primaryActiveDefault);

  const toggleFilterOverlay = () => {
    setOverlayOpen(!overlayOpen);
  }

  const activatePrimary = () => {
    setSecondaryActive(false);
    setPrimaryActive(true);

    if (headerContent.props?.updateParams) {
      headerContent.props.updateParams(TAB_PARAM, PRIMARY);
    }
  }

  const activateSecondary = () => {
    setSecondaryActive(true);
    setPrimaryActive(false);

    if (headerContent.props?.updateParams) {
      headerContent.props.updateParams(TAB_PARAM, SECONDARY);
    }
  }

  const contentHeaderProps = Object.assign(headerContent.props, {
    activatePrimary: activatePrimary,
    activateSecondary: activateSecondary
  });
  const HeaderContent = headerContent.component;
  const SortComponent = sortContent ? sortContent.component : undefined;
  const NoResultComponent = noResultContent;

  return (
    <>
      {HeaderContent &&
        <ContentHeader
          filterScope={headerContent.props.filterScope}
          primaryActive={primaryActive}
          activatePrimary={activatePrimary}
          secondaryActive={secondaryActive}
          activateSecondary={activateSecondary}
        >
          <HeaderContent {...contentHeaderProps} />
        </ContentHeader>
      }

      {filterContent &&
        <DesktopFilterControls
          visible={shouldShowDesktopFilterControls(filterChipsVisible, primaryActive)}
          >
        <FilterLink onClick={() => toggleFilterOverlay()} />
          <FilterChips
            appliedFilters={appliedFilters}
            filterReset={filterReset}
            filters={filters}
            updateParams={headerContent.props.updateParams}
            appliedFilterLabel={appliedFilterLabel}
          />
        </DesktopFilterControls>
      }

      <ContainerElement>
        {filterContent &&
          <FilterContainer
            heading={filterHeading}
            resultCount={resultCount}
            overlay={secondaryActive}
            open={overlayOpen}
            closeFunction={() => toggleFilterOverlay()}
            filterReset={filterReset}
          >
            {filterContent}
          </FilterContainer>
        }

        {loading
          ? <LoaderContent />
          : (/*
              Hide filters and results block if: search input is present and result
              count is zero after searching for any text
            */
            allowSearch && NoResultComponent && resultCount === 0
            ? <NoResultComponent searchValue={headerContent.props.searchValue} urlParams={headerContent.props?.urlParams} />
            : (
                <>
                  <PrimaryContentContainer primaryActive={primaryActive} filterContent={filterContent}>
                    {SortComponent ? (<SortComponent {...sortContent.props} />) : null}
                    {primaryContent}
                  </PrimaryContentContainer>
                  {secondaryContent ?
                    <SecondaryContentContainer secondaryActive={secondaryActive}>
                      {secondaryContent}
                    </SecondaryContentContainer> : null}
                </>
              )
            )
        }
      </ContainerElement>
    </>
  )
});

FilterableListContainer.WrappedComponent.displayName = 'FilterableListContainer';

export default wrappedInReduxProvider(FilterableListContainer);
