import { forwardRef } from 'react';
import { useAccessibleInteractivity, useSyncedRef } from '../../../hooks';
import { connectLocation } from '../../location/LocationConnectors';
import styled from '@emotion/styled';
import Text from '../../OC/PageBuilder/utilities/Text';

const RootItem = styled.li`
  margin-bottom: 10px;

  a {
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.i`
  margin-right: 4px;
`;

const InteractiveLink = forwardRef(
  ({ onClick, rootText, backToText, title, toFocusForAccessibility }, ref) => {
    const linkRef = useSyncedRef(ref);
    useAccessibleInteractivity(linkRef, (event) => {
      onClick(event);
      if (event.type === 'keydown') {
        toFocusForAccessibility && toFocusForAccessibility.current && toFocusForAccessibility.current.focus()
      }
    });

    return (
      <a
        data-track="filter"
        data-track-filter-name={title}
        data-track-filter-option={rootText}
        ref={linkRef}
      >
        <Icon className="icon icon-chevron-left" />
        <span className="sr-only">{ backToText }</span>
        <Text content={ rootText } />
      </a>
    )
  }
);

const LinkWithHref = forwardRef(
  ({ href, rootText, backToText, title }, ref) => (
    <a
      href={ href }
      data-track="filter"
      data-track-filter-name={ title }
      data-track-filter-option={ rootText }
      ref={ref}
    >
      <Icon className="icon icon-chevron-left" />
      <span className="sr-only">{ backToText }</span>
      <Text content={ rootText } />
    </a>
  )
);

const RootListItem = connectLocation(
  forwardRef(
    ({ hierarchyInPath, href, onClick, rootText, title, toFocusForAccessibility, selected, t }, ref) => {
      return (
        <RootItem selected={ selected }>
          {
            href
            ? <LinkWithHref href={ href } rootText={ rootText } backToText={ t('product_list.back_to_all') } title={ title } />
            : <InteractiveLink onClick={ onClick } ref={ ref } rootText={ rootText } backToText={ t('product_list.back_to_all') } title={ title } toFocusForAccessibility={ toFocusForAccessibility } />
          }
        </RootItem>
      )
    }
  )
);

export default RootListItem;
