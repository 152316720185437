import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from "@emotion/styled";
import _ from 'lodash';

// Assets
import IconPimLink from '../../../ComponentLibrary/icons/icon-pim-link';

// Components
import HierarchicalFilter from '../../../ComponentLibrary/filter-tree/oc-hierarchical-filter';

// Utilities
import { apiPost, apiDelete, apiPut } from '../../../PageBuilder/helpers/api';
import { formatEditLink, joinPath } from '../../../PageBuilder/helpers/path';

export const migrateListToTree = (list, idGetter) =>
  list?.reduce(
    (acc, item, index) => {
      const id = idGetter(item, index);
      const { order, ...data } = acc;
      _.set(data, id, item);
      order.push(id);
      return { ...data, order };
    },
    { template: {}, user: {}, order: [] }
  );

export const migrateTreeToList = tree => tree?.order?.map(id => _.get(tree, id));

export const metadataVersion = (metadata) => (
  typeof(metadata.version) === 'undefined' ? 0 : parseInt(metadata.version || 0)
);

const generatePathTree = (paths) => {
  const lookup = {};

  const traverseUp = (current) => {
    const parts = current.split('/');
    if (parts.length > 1) {
      const segment = parts.pop();
      const parent = parts.join('/');
      lookup[current] = { path: current, segment };
      traverseUp(parent);
    } else {
      lookup[current] = { path: current, segment: current };
    }
  }


  paths.forEach(path => traverseUp(path));

  const roots = {};
  Object.keys(lookup).sort().reverse().forEach(path => {
    const parts = path.split('/');
    const segment = parts.pop();
    const parent = parts.join('/');

    if (parent) {
      lookup[parent].children = lookup[parent].children || [];
      lookup[parent].children.push(lookup[path])
    } else {
      roots[path] = lookup[path]
    }

  })

  return Object.values(roots);
}

const IconLabel = styled.span`
  align-items: center;
  display: flex;
`;

const PIMLinkIcon = styled(IconPimLink)`
  margin-left: 8px;
`;

const ContentInner = (type, factory) => (UI) => {
  const typeSpecificFields = factory(UI);
  const displayName = typeof(typeSpecificFields.displayName) === 'undefined' ? type : typeSpecificFields.displayName;
  const usage = typeof(typeSpecificFields.usage) === 'undefined' ? 'route' : typeSpecificFields.usage;
  const showSlug = usage === 'route';
  const paths = typeSpecificFields.paths;
  const pathTip = typeof(typeSpecificFields.pathTip) === 'undefined' ? null : typeSpecificFields.pathTip;
  const allowsPIMLink = typeSpecificFields.allowsPIMLink;

  const pathTree = paths ? generatePathTree(paths) : null
  const path = {
    path: [
      ({ value, onChange }) => (
        <HierarchicalFilter
          unwrapped
          options={ UI.Pathing.Options(paths ? pathTree : Board.paths) }
          // we have to replace "/" in paths because it's interpreted as a special in pcb :(
          value={ (value||'').replaceAll('!', '/') }
          onChange={ paths ? () => {} : (attribute, value) => onChange((value||'').replaceAll('/', '!')) }
          root={
            ((paths ? pathTree : Board.paths)||[]).length > 1 ? {
              name: 'All'
            } : null
          }
        />
      ),
      {
        label: `${ displayName } Location`,
        // we have to replace "/" in paths because it's interpreted as a special in pcb :(
        default: paths ? (paths[0]||'').replaceAll('/', '!') : '',
        tip: pathTip
      }
    ]
  }

  const slug = {
    slug: ui`Text`({
      label: 'URL Slug',
      tip: 'Slug may not contain spaces (use dashes if needed, "-") and is limited to 16 characters. Your slug is not your page name, but should be representative of your page.'
    }),
    url: [
      // we have to replace "/" in paths because it's interpreted as a special in pcb :(
      ({ path = '', slug = '' }) => <UI.Paragraph><b>Page URL:</b> /{'{language}'}/{ joinPath((path||'').replaceAll('!', '/'), slug) }</UI.Paragraph>,
      {
        path: select`../path`,
        slug: select`../slug`
      }
    ],
  };

  const labelIcon = <PIMLinkIcon />;
  const pimSource = `pim_${type.split("::")[1]?.toLowerCase()}`;
  const startFromPIM = {
    startFromPIM: ui`Choices`.of({
      no: 'Start with Blank Page',
      yes: renderToStaticMarkup(<IconLabel>Start from a PIM Product {labelIcon}</IconLabel>)
    })({
      label: 'How would you like to start?',
      default: 'no',
      compact: when`../startFromPIM`.is.equal.to("yes").then(true).otherwise(false)
    }),
    linked_source: ui`Hidden`({
      noPadding: true,
      value: when`../startFromPIM`.is.equal.to('yes').then(pimSource).otherwise(undefined)
    }),
    linked_product: ui`Build/LinkPIM`({
      label: false,
      language: select`../language`,
      onChangeProduct: (product, path) => {
        Board.Change(product?.name || "", _.concat(_.initial(path), 'name'));
      },
      pimSource: pimSource,
      style: { selectedItemPadding: "0 0 0 32px" },
      visible: when`../startFromPIM`.is.equal.to('yes').then(true).otherwise(false)
    }),
    linked_external_id: ui`Hidden`({
      noPadding: true,
      value: when`../startFromPIM`.is.equal.to('yes').then(select`../linked_product/id`).otherwise(undefined)
    })
  };

  const name = {
    name: ui`Text`({
      label: `${ displayName } Name (Internal)`,
      tip: `${ displayName } Name should be less than 60 characters and is used for identification within Page Builder. This name is the overarching name for all variants (language and locale) of this item.`,
      disabled: allowsPIMLink && when`../startFromPIM`.is.equal.to('yes').then(true).otherwise(false),
      controlled: allowsPIMLink && 'value'
    }),
  };

  const contentFields = _.merge({}, (allowsPIMLink ? startFromPIM : {}), name, path, (showSlug ? slug : {}));

  const localeFields = (forCreate) => {
    const localized_name = {
      localized_name: ui`Text`({
        label: 'Localized Name',
        default: select`~defaults/localized_name`,
        tip: 'Localized Name should be short (ideally, less than 24 characters) as it may be rendered on the page (breadcrumbs). Localized Name should also be localized and translated appropriately.'
      }),
    };
    const language = {
      language: ui`Choices`({
        label: 'Location / Language',
        tip: 'Select one to start. More languages/locations variants may be added later.',
        contents: forCreate ? (select`../path`.from(select`~createLanguages`)) : (transform`~modal/variables/languages`(set => Array.from(set)))
      })
    }
    return _.merge({}, (showSlug ? localized_name : {}), language)
  }

  const redirectType = (includeNullWarning) => ({
    type: ui`Choices`({
      label: 'Type',
      mode: ui`Choices/Mode/Dropdown`,
      tip: select`.`.from({
        301: 'Be careful with "Content Moved Permanently".  If you choose and publish this, this URL will NEVER be available for use again, unless we remove this redirect AND update the target URL to 301 redirect back to this URL.<br><strong>If you are unsure, choose "Vanity URL" as it can be changed later if necessary.</strong>',
      }),
      default: 302,
      contents: select`~modal/variables/exists`.from({
        'true': {
          'null': 'None (Remove Redirect)',
          302: 'Vanity URL or Temporary Redirect',
          301: 'Content Moved Permanently'
        },
        'false': {
          302: 'Vanity URL or Temporary Redirect',
          301: 'Content Moved Permanently'
        }
      })
    }),
    [s._]: ui`AlertMessage`({
      message: select`../type`.from(
        _.merge(
          {},
          {
            301: 'Adding/changing a redirect will take effect immediately after submission',
            302: 'Adding/changing a redirect will take effect immediately after submission',
          },
          includeNullWarning ? { 'null': 'After removing the redirect, this item will be in "Redirected with Draft" status, the draft must be published to complete removal of redirect' } : {},
        )
      ),
      type: 'warning',
      visible: includeNullWarning || when`../type`.isnt.equal.to('null')
    })
  })

  return _.merge({},
    {
      displayName,
      usage,
      pathTip: '',
      isGlobal: false,
      showShare: true,
      modals: {
        redirect_all: {
          title: 'Manage Redirect',
          body: ui`Form`.of({
            current_url: [ ({ path, slug }) => <><b>Current URL: </b> /{ joinPath(path, slug) }</>, { path: select`~modal/variables/path`, slug: select`~modal/variables/slug` } ],
            target: ui`Url`({
              label: 'Target URL',
              tip: 'When redirecting an entire content item, you should NOT include a language prefix. The proper language page should be detected from user preferences at the Target URL.'
            }),
            ...redirectType(false)
          })({
            compact: true
          }),
          buttons: {
            Submit: (values) => {
              const body = { redirect: values.type === 'null' ? { target: false, type: null } : values }
              apiPost(`/api/v2/cms/sites/${PB_SITE}/contents/${ Board.modal.variables.item }/redirect`, body)
                .then(response => window.location.reload()); // TODO, maybe inline reload
            }
          }
        },
        redirect_one: {
          title: 'Manage Redirect',
          body: ui`Form`.of({
            current_url: [ ({ locale, path, slug }) => <><b>Current URL: </b> /{ locale.toLowerCase() }/{ joinPath(path, slug) }</>, { locale: select`~modal/variables/locale`, path: select`~modal/variables/path`, slug: select`~modal/variables/slug` } ],
            target: ui`Url`({ label: 'Target URL' }),
            ...redirectType(true)
          })({
            compact: true
          }),
          buttons: {
            Submit: (values) => {
              const body = { redirect: values.type === 'null' ? { target: false, type: null } : values }
              apiPost(`/api/v2/cms/sites/${PB_SITE}/contents/${ Board.modal.variables.item }/languages/${ Board.modal.variables.locale.toLowerCase() }/redirect`, body)
                .then(response => window.location.reload()); // TODO, maybe inline reload
            }
          }
        },
        edit_details: {
          title: `Edit ${ displayName } Details`,
          body: ui`Form`.of({
            name: ui`Text`({
              label: `${ displayName } Name (Internal)`,
              tip: `${ displayName } Name should be less than 60 characters and is used for identification within Page Builder. This name is the overarching name for all variants (language and locale) of this item.`,
            }),
          })({
            compact: true
          }),
          buttons: {
            Submit: (values) => {
              apiPut(`/api/v2/cms/sites/${PB_SITE}/contents/${ Board.modal.variables.item }`, values)
                .then(response => window.location.reload()); // TODO, maybe inline reload
            }
          }
        },
        create: {
          title: `Create New ${ displayName }`,
          body: ui`Form`.of({
            ...contentFields,
            ...localeFields(true)
          })({
            compact: true
          }),
          buttons: {
            Submit: (values) => {
              // we have to replace "/" in paths because it's interpreted as a special in pcb :(
              let real_path = (values.path||'').replaceAll('!', '/')
              if (usage === 'type') {
                values.slug = '*';
              }
              values = {
                ...values,
                usage: usage,
                route: `/${joinPath(real_path, values.slug)}`
              }
              // "type" usages users select a path and we will append "*" to the route
              // TODO: we want to enable this when we start posting to v2, which includes posting the "usage" field
              // if (usage === 'type') {
              //   values.slug = '*';
              // }
              apiPost(`/api/v2/cms/sites/${PB_SITE}/contents`, { ...values, type })
                .then(response => window.location = formatEditLink(response.uuid, values.language));
            }
          }
        },
        duplicate: {
          title: 'Duplicate Content',
          body: ui`Form`.of({
            ...contentFields
          })({
            compact: true
          }),
          buttons: {
            Submit: (values) => {
              // we have to replace "/" in paths because it's interpreted as a special in pcb :(
              let real_path = (values.path||'').replaceAll('!', '/')
              if (usage === 'type') {
                values.slug = '*';
              }
              values = {
                ...values,
                route: `/${joinPath(real_path, values.slug)}`
              }
              apiPost(`/api/v2/cms/sites/${PB_SITE}/contents/${ Board.modal.variables.item }/duplicate`, values)
                .then(response => window.location.reload()); // TODO, maybe inline reload
            }
          }
        },
        languageCreate: {
          title: 'Create New Language / Location',
          body: ui`Form`.of({
            ...localeFields(false)
          })({
            compact: true
          }),
          buttons: {
            Submit: (values) => {
              apiPost(`/api/v2/cms/sites/${PB_SITE}/contents/${ Board.modal.variables.item }/languages/${ values.language.toLowerCase() }`, values)
                .then(response => window.location = formatEditLink(Board.modal.variables.item, values.language));
            }
          }
        },
        languageDuplicate: {
          title: 'Duplicate Language / Location',
          body: ui`Form`.of({
            language: ui`Choices`({
              label: 'Location / Language',
              tip: 'Select one to start. More languages/locations variants may be added later.',
              contents: (transform`~modal/variables/languages`(set => Array.from(set)))
            })
          })({
            compact: true
          }),
          buttons: {
            Submit: (values) => {
              const { language, ...otherValues } = values;
              values = { ...otherValues, target_language: language }
              apiPost(`/api/v2/cms/sites/${PB_SITE}/contents/${ Board.modal.variables.item }/languages/${ Board.modal.variables.locale.toLowerCase() }/duplicate`, values)
                .then(response => window.location = formatEditLink(Board.modal.variables.item, language));
            }
          }
        }
      },
      read: _.identity,
      write: _.identity,
      variables: _.merge({}, {
        build: { type },
        Gutter: {
          [UI.Viewport.Device.Desktop]: 85,
          [UI.Viewport.Device.Tablet]: 15,
          [UI.Viewport.Device.Mobile]: 15
        },
        Gap: {
          [UI.Viewport.Device.Desktop]: 64,
          [UI.Viewport.Device.Tablet]: 48
        }
      }),
      view: Content.DefaultRenderer
    },
    typeSpecificFields
  )
}

const Content = (type, factory) => _.memoize(ContentInner(type, factory));

Content.PreventClicks = ({ children }) => (
  <div onClick={
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }>
    { children }
  </div>
)

Content.DefaultRenderer = (UI, parameters={}) => ({
  contents: ({ value }) => (
    <Content.PreventClicks>
      <textarea value={JSON.stringify(value || {})} />
    </Content.PreventClicks>
  )
});

export default Content;
