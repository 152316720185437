/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
// Library Imports
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';

import withDefaultPrevented from '../../../../../../withDefaultPrevented';
import Link from '../../../../../OC/oc-link';
import { store } from '../../../../../Store';
import { PrefixWithLocale } from '../../../../../Utilities';
import { connectLocation } from '../../../../../location/LocationConnectors';

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  padding: 12px 20px;
  background: #000000;
  color: #ffffff;

  .banner-text {
    display: inline-flex;
    justify-content: flex-start;
    line-height: 18px;
    position: relative;

    &:before {
      content: '\\f071';
      font-family: 'fontawesome';
      margin-right: 12px;
    }

    a {
      color: inherit;
      font-weight: 700;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const Banner = connectLocation(({ zip, openLocationModal, t, locale }) => (
  <Container>
    <div className="banner-text">
      <div className="message">
        {`${t('pdp.product_not_available')} (${zip}) `}
        <Link onClick={withDefaultPrevented(openLocationModal)}>
          {t('pdp.change_location')}
        </Link>
        {` ${t('pdp.or')} `}
        <Link
          href={PrefixWithLocale('/roofing/components/products', locale.code)}
        >
          {t('pdp.browse_products')}
        </Link>
      </div>
    </div>
  </Container>
));

Banner.propTypes = {
  zip: PropTypes.string.isRequired,
  openLocationModal: PropTypes.func.isRequired,
};

const UnavailableProductsBannerWithStore = () => (
  <Provider store={store}>
    <Banner />
  </Provider>
);

export default UnavailableProductsBannerWithStore;
