import _ from 'lodash';

import {
  outsideAirRValue,
  osbRValue,
  gypsumRValue,
  insideAirRValue,
  studWoodOptions,
  studRvalues,
  studSpacingOptions,
  UPDATE_ACTION,
  RESET_ACTION,
  TEXT,
} from './constants';

function parseThickness(thickness) {
  if (!thickness || !thickness.includes(':')) {
    return [NaN, NaN];
  }

  return thickness.split(':').map(parseFloat);
}

function thicknessSort(a, b) {
  const [aThickness, aRValue] = parseThickness(a.value);
  const [bThickness, bRValue] = parseThickness(b.value);
  return aThickness * 100 + aRValue - (bThickness * 100 + bRValue);
}

function makeThicknessOptions(products) {
  if (!products) {
    return [];
  }
  return _.uniqWith(
    products.map(product => ({
      text: `${product.subCategory ? `${product.subCategory}, ` : ''}${
        product.thickness
      } inch (R-${product.rValue})`,
      value: `${product.thickness}:${product.rValue}`,
    })),
    _.isEqual
  ).sort(thicknessSort);
}

// didn't have a great name for it. this smoothes over 2 things
// - switching between manual text entry and product/thickness selection
// - reconciling the default value in the select dropdown
function coalesceRValue(textOrProduct, text, thickness, thicknessOptions) {
  if (textOrProduct === TEXT) {
    return parseFloat(text);
  }

  if (!thicknessOptions.some(o => o.value === thickness)) {
    return parseThickness(thicknessOptions[0]?.value)[1];
  }

  return parseThickness(thickness)[1];
}

class RValueCalculatorState {
  constructor(state, dispatch, scrollToRef) {
    this.dispatch = dispatch;
    this.scrollToRef = scrollToRef;
    Object.entries(state).forEach(([key, value]) => {
      this[key] = value;
      this[`set${key[0].toUpperCase()}${key.slice(1)}`] = nextValue =>
        this.update(key, nextValue);
      Object.freeze(this[key]);
    });
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.scrollToResults = this.scrollToResults.bind(this);
  }

  update(key, value) {
    this.dispatch({ type: UPDATE_ACTION, key, value });
  }

  reset() {
    this.dispatch({ type: RESET_ACTION });
  }

  scrollToResults() {
    this.scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  get studWoodApplicableProducts() {
    return (
      this.productData?.filter(product => product[`is${this.studWood}`]) || []
    );
  }

  get exteriorProducts() {
    return (
      this.studWoodApplicableProducts?.filter(
        product => product.material === 'xps-insulation'
      ) || []
    );
  }

  get cavityProducts() {
    return (
      this.studWoodApplicableProducts?.filter(
        product => product.material === this.cavityInsulationType
      ) || []
    );
  }

  get cavityThicknessOptions() {
    return makeThicknessOptions(this.cavityProducts);
  }

  get exteriorThicknessOptions() {
    return makeThicknessOptions(this.exteriorProducts);
  }

  get cavityRValue() {
    return coalesceRValue(
      this.cavityTextOrProduct,
      this.cavityRValueText,
      this.cavityThickness,
      this.cavityThicknessOptions
    );
  }

  get exteriorRValue() {
    return coalesceRValue(
      this.exteriorTextOrThickness,
      this.exteriorRValueText,
      this.exteriorThickness,
      this.exteriorThicknessOptions
    );
  }

  get totalRValue() {
    const { cavityRValue, exteriorRValue } = this;
    const studRvalue = studRvalues[this.studWood];
    const framePct = {
      16: 0.25,
      24: 0.21,
    }[this.studSpacing];

    const cavityAverage =
      1 / ((1 - framePct) / cavityRValue + framePct / studRvalue);

    return (
      cavityAverage +
      outsideAirRValue +
      osbRValue +
      gypsumRValue +
      insideAirRValue +
      exteriorRValue
    );
  }

  get productResults() {
    const results = [];
    if (!this.isCavityText) {
      results.push(
        ...this.cavityProducts.filter(p => p.rValue === this.cavityRValue)
      );
    }
    if (!this.isExteriorText) {
      results.push(
        ...this.exteriorProducts.filter(p => p.rValue === this.exteriorRValue)
      );
    }
    return _.uniqBy(results, 'id');
  }

  get isCavityText() {
    return this.cavityTextOrProduct === TEXT;
  }

  get isExteriorText() {
    return this.exteriorTextOrThickness === TEXT;
  }

  get studWoodText() {
    const studWoodOption = studWoodOptions.find(o => o.value === this.studWood);
    if (studWoodOption) {
      return `${studWoodOption.text} (R-value ${studRvalues[this.studWood]})`;
    }
    return '';
  }

  get studSpacingText() {
    return studSpacingOptions.find(o => o.value === this.studSpacing)?.text;
  }
}

// this is usually a bad idea. but this class is completely immutable,
// with many computed properties
function memoizeGetter(klass, functionName) {
  const getter = Object.getOwnPropertyDescriptor(klass.prototype, functionName);
  const cacheKey = `_${functionName}-cache_`;
  Object.defineProperty(klass.prototype, functionName, {
    get() {
      if (cacheKey in this) {
        return this[cacheKey];
      }
      this[cacheKey] = getter.get.call(this);
      return this[cacheKey];
    },
  });
}

[
  'studWoodApplicableProducts',
  'exteriorProducts',
  'cavityProducts',
  'cavityThicknessOptions',
  'exteriorThicknessOptions',
  'cavityRValue',
  'exteriorRValue',
  'totalRValue',
  'productResults',
].forEach(fn => memoizeGetter(RValueCalculatorState, fn));

export default RValueCalculatorState;
