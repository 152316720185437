import { ui, transform, select } from '@owenscorning/pcb.alpha';
import _ from 'lodash';

import Modular from './Modular';
import migratePageArrayContents from '../../migrate_old_page_format';

const Settings = {
  postalRequired: ui`Switch`({ label: 'Require Postal Code?', default: false }),
  meta: ui`Meta`({
    label: transform`~definition`(
      definition => `${definition.displayName || definition.type} Metadata`
    ),
  }),
  open_graph_settings: ui`OpenGraph`({
    label: 'Open Graph Settings'
  }),
}

const Page = Modular('Page', 'Page', {
  displayName: 'Page',
  layout: { Header: ['Hero', 'StickyNav'] },
  list: UI => ({
    'layout/Header': Modular.Layout.List(UI, 'Header', ['Hero', 'StickyNav']),
    sections: ui`List`.of({ modules: Modular.Module.List(UI) })({
      title: 'details/name',
      singular: 'Section',
      actions: [
        {
          label: 'Settings',
          icon: 'cogs',
          onClick: Modular.Section,
        },
      ],
      startOpened: select`~lastOpenedSection`,
    }),
  }),
  settings: Settings,
  //read migration
  read: ({ contents, metadata, ...rest }) => {
    if (metadata?.version) return { contents, metadata, ...rest };

    if (_.isEmpty(contents)) {
      contents = [];
    }

    return migratePageArrayContents({ contents, metadata });
  },
  //write migration
  //keep writing uuids until after we deploy
  write: ({ contents, metadata }) => {
    return metadata?.version
      ? { contents, metadata }
      : {
          contents: (contents.sections || []).map((section, sectionIndex) => ({
            ..._.omit(section, 'modules'),
            __uuid: `sections/${sectionIndex}`,
            component: 'Section',
            children: (section.modules || []).map((module, index) => ({
              component: module.type,
              ...module.data,
              ..._.omit(module, ['type', 'data']),
              __uuid: `sections/${sectionIndex}/modules/${index}`,
            })),
          })),
          metadata: {
            layouts: {
              Header: {
                modules: _.mapValues(
                  contents?.layout?.Header,
                  (module, name) => ({
                    ...module,
                    __uuid: `layout/Header/${name}`,
                  })
                ),
              },
            },
            ...metadata,
          },
        };
  },
  modals: (UI) => ({
    module: {
      body: ui`Tabs`.of(
        _.mapValues(UI.Modules.Categories.Page, (category) => ({
          label: category,
          pane: ui`Grid`.of(
            _.mapValues(
              _.pickBy(_.omit(UI.Modules.Page, 'Thumbnails'), (module) => {
                return module.category
                  ? _.get(UI, module.category.path.split('/')) == category
                  : category == UI.Modules.Categories.Page.Customizable
              }),
              (module, type) => ui`Thumbnail`({
                image: module.thumbnail,
                subtitle: module.displayName,
                onClick: () => {
                  Board.modal.reference({ type, data: Board.Evaluate(module.modes.edit) });
                  Board.modal.close();
                }
              })
            )
          )
        }))
      )
    }
  })
});

Page.Settings = Settings;

export default Page;
