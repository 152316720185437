import RichText from './RichText';
import Text from './utilities/Text';
import News from '../oc-news';
import createSizes from "./utilities/sizes";
import styled from "@emotion/styled";
import { Screens } from "../../PageBuilder/editing/Viewport";

const Sizes = createSizes();

const NewsWrapper = styled.div`
display: flex;
justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const NewsContainer = styled.div`
  margin: 0;
  width: ${({ width }) => `${ width.Mobile}px` };
  @media (min-width: ${ Screens.Tablet }px) { width: ${ ({ width }) => `${ width.Tablet }px`}; }
  @media (min-width: ${ Screens.Desktop }px) { width: ${ ({ width }) => `${ width.Desktop }px`}; }
`;

const CMSNews = ({ news, newsHeading, customCta, containerSettings = {size: 'xl', alignment: 'Left'}}) => {
  news = news?.map((item, index) => {
    if (item) item.text = <RichText content={item.text} />;
    return item;
  });

  const { size, alignment } = containerSettings;
  const Width = Sizes[(size || 'xl')];

  return (
    <NewsWrapper alignment={alignment}>
      <NewsContainer size={size} width={Width}>
        <News
          news={news}
          newsHeading={newsHeading}
          cta={customCta?.cta}
          ctaUrl={customCta?.ctaUrl || 'https://newsroom.owenscorning.com'}
          ctaText={customCta?.ctaText || 'More News'}
        />
      </NewsContainer>
    </NewsWrapper>
  );
};

export default Text.Parse(CMSNews, 'news.prehead', 'news.heading');
