import { css } from '@emotion/react';
import styled from '@emotion/styled';
import RichText from './RichText';
import Theme from "../../../themes";
import Text from "./utilities/Text";

const Container = styled.div`
  display: grid;
  padding-right: 8px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 50% 50%;
    column-gap: 5px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 970px;
    margin: auto;
    padding-right: 0px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
`;

const renderCss = {
  section: () => css`
    background-color: black;
    margin-bottom: 10px;
  `,
  h4: () => css`
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    color: white;
    line-height: 26px;
    @media screen and (min-width: 1200px) {
      font-size: 28px;
      line-height: 30px;
    }
  `,
  labelTextContainer: () => css`
    padding-left: 12px;
    font-size: 10px;
    color: white;
    overflow-wrap: break-word;
    max-width: 260px;

    @media screen and (min-width: 767px) {
      font-size: 14px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 16px;
      max-width: 341px;
    }

    p {
      padding-bottom: 0;
      color: white;
    }
  `,
};

//CSS for the rating display
const RatingContainer = styled.div`
  font-family: Oswald, sans-serif;
  display: flex;
  align-items: center;
  height: 61px;
  padding: 0 5px;

  @media screen and (max-width: 1200px) { /* Tablet view */
    height: 50px;
    padding-left: 5px;
  }

  @media screen and (max-width: 767px) { /* Mobile view */
    height: 40px;
    padding-left: 7px;
    padding-right: 0;
  }
`;

const Number = styled.span`
  color: ${Theme.colors.brand};
  font-size: 69px;
  line-height: 1;

  @media screen and (max-width: 1200px) { /* Tablet view */
    font-size: 55px;
  }

  @media screen and (max-width: 767px) { /* Mobile view */
    font-size: 45px;
  }
`;

const VerticalText = styled.div`
  color: ${Theme.colors.brand};
  display: flex;
  font-size: 18px;
  margin: 6px 4px 0;
  transform: rotate(-180deg);
  writing-mode: vertical-rl;

  @media screen and (max-width: 1200px) { /* Tablet view */
    font-size: 15px;
    margin: 4px 2px 0;
  }

  @media screen and (max-width: 767px) { /* Mobile view */
    font-size: 12px;
    margin-left: 0;
  }
`;

const VerticalSpan = styled.span`
  display: block;
  text-transform: uppercase;
`;

const RatingDisplay = ({firstNumber, secondNumber, outOfText}) => {
  return (
    <RatingContainer>
      <Number>{firstNumber}</Number>
      <VerticalText>
        <VerticalSpan>{outOfText}</VerticalSpan>
      </VerticalText>
      <Number>{secondNumber}</Number>
    </RatingContainer>
  );
};


export default function BannerPole({ settings = {}, content = [], image={} }) {
  return (
    <div css={renderCss?.section}>
      <Container>
        {content?.map((portion, i) => (
          <SubContainer key={i}>
            <RatingDisplay firstNumber={portion.first_number} secondNumber={portion.second_number} outOfText={portion.out_of_text} />

              <div css={renderCss?.labelTextContainer}>
                <h4 css={renderCss?.h4}><Text content={portion?.title} /></h4>
                {settings?.textStyling === 'custom' && (
                  <p>
                    <RichText content={portion?.content} />
                  </p>
                )}
                {settings?.textStyling === 'default' && (
                  <p>
                    <Text content={portion?.content?.ops?.[0]?.insert} />
                  </p>
                )}
              </div>

          </SubContainer>
        ))}
        ,
      </Container>
    </div>
  );
}
