import _ from "lodash";
import { jsx, css } from '@emotion/react';
import Conditional from './Conditional';
import { connectLocation } from './location/LocationConnectors';
import {OCNameFilter} from "./Utilities";
import InfoIcon from './OC/oc-info-icon';
import LocaleSpecific from "./LocaleSpecific";

export const CHECKBOX = 'checkbox'
export const RADIO = 'radio'

const TYPE_CLASSES = {
  [CHECKBOX]: "checkbox-input-style",
  [RADIO]: "radio-input-style"
}

const filterToggle = css`
  .icon {
    width: 20px;
    height: 20px;
    justify-content: center;
    margin-right: 10px;

    img {
      max-width: 20px;
      max-height: 20px;
      margin-right: 0px;
    }
  }

  .checkbox-check-container {
    position: relative;
  }
`;

const labelOnFocus = css`
  &:focus {
    outline-offset: 2px;
    outline-color: #d40f7d;
    outline-style: solid;
    outline-width: 2px;
  }
`;

export const EXCLUSIVE_STRATEGY = 'exclusive'
export const MULTISELECT_STRATEGY = 'multiselect'

// If a filter toggle is not enabled by default (props) or already checked,
// then we determine whether or not it should be enabled/disabled
// by updating the current filters with value passed to the filter toggle.
// We then filter the items using the updated filters to determine
// whether any items would meet the updated filter requirements.
// If at least one item meets the updated filter requirements,
// then the filter toggle is enabled.  If no items meet
// the updated filter requirements, then the filter toggle is disabled.
//
// The exclusive strategy updates the filters by making the value
// the only selected option in the specified filter section (filterKey).
// In other words, it removes any current values from the filter section,
// replacing them with the filter toggles value.
//
// The multiselect strategy updates the filters by adding the value
// to the current values in the specified filter section (filterKey).
export const FILTERS_WITH_VALUE = {
  [EXCLUSIVE_STRATEGY]: function({ filters, filterKey, value }) {
    return (
      {
        ...filters,
        [filterKey]: [value]
      }
    )
  },
  [MULTISELECT_STRATEGY]: function({ filters, filterKey, value }) {
    const filterKeyValues = _.clone(filters[filterKey]) || [];
    filterKeyValues.push(value)

    return (
      {
        ...filters,
        [filterKey]: filterKeyValues
      }
    )
  }
}

const FilterToggle = connectLocation(({
  filterFunc,
  filterStrategy = EXCLUSIVE_STRATEGY,
  filtersConfig,
  filters,
  filterKey,
  value,
  defaultEnabled,
  children,
  labelProps,
  onToggleFilter,
  items,
  icon,
  inputLabel,
  onInfo,
  popular,
  name,
  resultsCount = 0,
  alt = "",
  t,
  type = CHECKBOX
}) => {
  const checked = (filters[filterKey] || []).some(elem => JSON.stringify(value) === JSON.stringify(elem));
  const enabled = (
    defaultEnabled
    || checked
    || ( filterFunc ? !_.isEmpty(filterFunc(items, FILTERS_WITH_VALUE[filterStrategy]({ filters, filterKey, value }))) : false)
    || resultsCount
  );

  const hidden = (!enabled && (filterKey !== 'star_rating'));

  const { analytics, tabIndex } = filtersConfig ? filtersConfig[filterKey] : {analytics: null, tabIndex: 0}
  const filterOption = analytics?.option(value);

  return (
    <li
      css={filterToggle}
      className={`${checked ? 'active' : null} ${hidden ? 'hidden' : ''} oc-filter-option`}
      aria-hidden={hidden}
    >
      <span
        className={enabled ? '' : 'disabled'}
      >
        <label
          css={labelOnFocus}
          aria-label={inputLabel || name || filterOption}
          tabIndex={!!enabled ? tabIndex : -1}
          {...(labelProps || {})}
          onKeyPress={(event) => {
            if (event.charCode == "13") {
              if (enabled) onToggleFilter({ [filterKey]: value });
            }
          }}
        >
          <span className="checkbox-check-container">
            <input
              className={TYPE_CLASSES[type]}
              data-track={`${checked ? 'remove-' : ''}filter`}
              data-track-filter-name={analytics?.name}
              data-track-filter-option={filterOption}
              type="checkbox"
              checked={checked}
              onChange={() => onToggleFilter({ [filterKey]: value })}
              disabled={!enabled}
              tabIndex={-1}
              readOnly={enabled === false ? 'readonly' : null}
              autoComplete="off"
            />

            <Conditional if={checked}>
              <span
                className="check"
                role="checkbox"
                aria-checked={checked}
                aria-disabled={!enabled}
              >
              </span>
            </Conditional>

            <Conditional if={icon}>
              <span className="icon" aria-hidden>
                <img src={icon} alt={alt}/>
              </span>
            </Conditional>
            <Conditional if={!checked}>
              { filterKey === "colors" && <OCNameFilter className="label-text" aria-hidden>{children || name || filterOption}</OCNameFilter> }
            </Conditional>
          </span>
          { filterKey !== "colors" && <OCNameFilter className="label-text" aria-hidden>{children || name || filterOption}</OCNameFilter> }
          <Conditional if={popular}>
            <LocaleSpecific except={['fr-CA','en-CA']}>
              <span className="label">{t('shingles.popular')}</span>
            </LocaleSpecific>
          </Conditional>
        </label>

        <Conditional if={onInfo}>
          <InfoIcon clickHandler={onInfo} enabled={enabled}/>
        </Conditional>
      </span>
    </li>
  );
});

FilterToggle.WrappedComponent.displayName = 'FilterToggle';

export default FilterToggle;
