import { define, ui, s } from '@owenscorning/pcb.alpha';

import { BasicContent } from '../../../../OC/PageBuilder/BasicContent';
import { highlightSettingsSection } from '../../../../OC/PageBuilder/utilities/shared';
import ContentAndImage from '../../../../OC/oc-content-and-image';
import { connectLocation } from '../../../../location/LocationConnectors';
import CTA from '../../CTA';

const CALCULATOR_LINK_TO = 'tools-documents/calculators/co2-calculator';

const getCalculatorUrl = (localeCode, sku) =>
  sku
    ? `/${localeCode}/${CALCULATOR_LINK_TO}?sku=${sku}`
    : `/${localeCode}/${CALCULATOR_LINK_TO}`;

const IMAGE_URL =
  'https://imagecdn.owenscorning.com/ocimages/image/upload/c_crop,h_228,w_400,x_0,y_214/f_auto,q_auto/v1/pagebuilder/uploads/prod/86d85f4f-bac7-456d-8266-ca0803d25693/co2-calculator-highlight-image';

const Promo = connectLocation(
  ({ sku, visible = true, t, locale }) =>
    visible && (
      <ContentAndImage
        ratio="2-1"
        contentOrderMobile="first"
        img={IMAGE_URL}
        noMargin
      >
        <BasicContent
          prehead={t('co2.promo.prehead')}
          heading={t('co2.promo.heading')}
          bodyCopy={t('co2.promo.body_copy')}
        />
        <CTA
          text={t('co2.promo.cta_text')}
          url={getCalculatorUrl(locale?.code, sku)}
        />
      </ContentAndImage>
    )
);

export default define`Paroc CO2 Calculator Promo`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/ParocCo2CalculatorPromo`,
  view: ({ Board }) => {
    const variants = Board.Value.metadata?.settings?.attributes?.variants ?? [];
    const metadata = Board.Value.linkedObject?.metadata ?? Board.Value.metadata;
    const sku = metadata?.connection?.name;
    return <Promo sku={sku} visible={variants.length > 0} />;
  },
  edit: ui`Form`.of({
    [s._]: ui`Tip`.of(
      'This is only visible when Variants are present in Settings > Attributes'
    )({ noTopMargin: true }),
    [s._]: ui`RawLink`.of('Go to settings')({
      onClick: () => {
        highlightSettingsSection('sidebar-pane', 'attributes');
      },
      noTopMargin: true,
      icon: 'external-link',
    }),
  }),
});
