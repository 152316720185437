import { ui } from '@owenscorning/pcb.alpha';

import Product from './Product';

const Vidawool = Product(
  'Product::Vidawool',
  ui`AttributeSet`({
    query: {
      name: 'Product Attributes',
      path: '/',
    },
  }),
  build => [],
  {},
  {
    version: '0.1',
    displayName: 'Vidawool PDP',
    paths: ['solutions'],
    allowsPIMLink: false,
  },
  [
    {
      title: 'Intro',
      unmovables: [true],
      modules: ['ContentAndImage', 'Reusable', 'BasicContent', 'AttentionBar'],
      initial: ['ContentAndImage'],
      showInSticky: false,
    },
    {
      title: 'Options',
      modules: ['ContentAndImage', 'Reusable', 'BasicContent', 'MediaObjectSet'],
      initial: ['CardSet'],
      showHeading: true,
    },
    {
      title: 'Features',
      modules: ['ContentAndImage', 'Reusable', 'BasicContent', 'MediaObjectSet'],
      initial: ['MediaObjectSet'],
      showHeading: true,
    },
    {
      title: 'Call Out',
      modules: ['ContentAndImage', 'Reusable', 'BasicContent'],
      initial: ['ContentAndImage'],
      showHeading: true,
    },
    {
      title: 'Sustainability',
      modules: ['CardSet', 'ContentAndImage', 'Reusable', 'BasicContent', 'MediaObjectSet'],
      initial: ['CardSet'],
      showHeading: true,
    },
    {
      title: 'Specifications & Literature',
      modules: ['BasicContent', 'ContentAndImage', 'Reusable', 'Table'],
      initial: ['SpecificationsAndLiterature'],
      showHeading: true,
    },
    {
      title: 'Characteristics',
      modules: ['Table', 'ContentAndImage', 'Reusable', 'BasicContent'],
      initial: ['Table', 'Table'],
      showHeading: true,
    },
    {
      title: 'Guide',
      modules: ['ContentAndImage', 'Reusable', 'BasicContent', 'AttentionBar'],
      initial: ['MediaObjectSet', 'AttentionBar'],
      showHeading: true,
    },
  ],
  '',
  true
);

export default Vidawool;
