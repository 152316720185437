import { useRef } from 'react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled'
import _ from 'lodash';

import Card from '../oc-card-new';
import Link from '../oc-link';
import { H2, H3, H4, H5, H6 } from '../oc-h';
import WrapperLink from '../oc-wrapper-link';
import RichText from './RichText';
import ChevronLink from '../oc-chevron-link';
import Cta from './Cta';
import Picture from '../oc-picture';

import Text from './utilities/Text';

const CMSCard = ({img, alt, icon, children, prehead, heading, text, linkText, url, closing, socialShare, ctaSwitch, ctaProps, crop, ...props}) => {
  const CtaButtonRef = useRef(null);
  const handleCtaButtonClick = (e) => {
    if(CtaButtonRef.current){
      e.preventDefault();
      CtaButtonRef.current?.click();
    }
  };

  return (
    <Card img={crop ? Picture.Transform(img, { crop: 'crop', ...crop }) : img} alt={alt} icon={icon} url={ !!ctaSwitch? (CtaButtonRef?.current?.href || '#') : url } linkText={linkText} socialShare={socialShare} ctaSwitch={ctaSwitch} handleCtaButtonClick={handleCtaButtonClick} {...props}>
      <WrapperLink url={ !!ctaSwitch? (CtaButtonRef?.current?.href || '#') : url } tabIndex="-1" { ...(!!ctaSwitch && {onClick: handleCtaButtonClick})}>
        { heading && <H3 prehead={ prehead } tag="h3" font="body"><Text content={ heading } /></H3> }
      </WrapperLink>

      <RichText content={ text } />

      {/* Only add Chevron Link if url or linkText is present */}
      { (!ctaSwitch && linkText) &&
        <ChevronLink url={url}><Text content={ linkText } /></ChevronLink>
      }

      {!(!!ctaSwitch) && closing ? <p><Text content={closing} /></p> : null}
      {!!ctaSwitch && (_.isArray(ctaProps) ? ctaProps?.map((props) => (
        <div>
          <Cta
            {...props}
            forwardRef={CtaButtonRef}
          />
        </div>
      )) : <div
      >
        <Cta
          {...ctaProps}
          forwardRef={CtaButtonRef}
        /></div>)
      }
    </Card>
  )
};

export default CMSCard;
