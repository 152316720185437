import CheckboxSelector from '../../../ComponentLibrary/filter-list/oc-filters';
import DistanceSelector from '../../../ComponentLibrary/oc-distance-selector';

function distanceFilter({ distanceBreakPoints, units }) {
  return {
    component: DistanceSelector,
    displayName: 'Distance',
    options: distanceBreakPoints?.map(dist => ({
      displayName: dist,
      value: dist,
    })),
    props: { units },
    matchType: 'ajax',
    unscoped: true,
  };
}

function attrFilter({ options, title, data_type: dataType }) {
  const valueType =
    {
      select: 'singular',
      multi_select: 'multiple',
    }[dataType] || 'none';
  return {
    component: CheckboxSelector,
    displayName: title,
    options: options?.map(option => ({
      value: option.key,
      displayName: option.label,
    })),
    valueType,
    filterValueType: 'multiple',
    matchType: 'ajax',
  };
}

export function getFilterComponents(
  filterInformation,
  distanceBreakPoints,
  units
) {
  const { filters } = filterInformation;
  const filterComponents = {};
  // they all have a distance filter
  filterComponents.distance = distanceFilter({ distanceBreakPoints, units });
  // look through PB props for the rest
  filters?.forEach(filter => {
    filterComponents[filter.filterId] = attrFilter(filter);
  });
  return filterComponents;
}
