import cmsApi from '../../../../cms_api';

function flattenProducts(data) {
  return data
    .flatMap(product => {
      const {
        id,
        route,
        metadata: {
          settings: {
            general: {
              long_description: longDesc,
              short_description: shortDesc,
              prehead,
              proper_name: name,
            },
            images: { images = [], list_item_image: imageIndex = 0 },
            attributes: {
              variants = [],
              'insulating-material': materials,
              insulating_material: materialsFallback,
            },
            meta: { description: metaDesc },
          },
        },
      } = product;
      const material = (materials || materialsFallback || []).join(','); // there's always just 1, but it's an array. *shrug*
      const image = images[imageIndex]?.file;
      const description = (longDesc || shortDesc || metaDesc)?.trim();
      return variants.map(
        ({
          'r-value': rValue,
          thickness,
          '2x4-applicable': is2x4 = false,
          '2x6-applicable': is2x6 = false,
          'sub-category': subCategory,
        }) => ({
          id,
          route,
          description,
          image,
          name,
          prehead,
          material,
          rValue: parseFloat(rValue),
          thickness: parseFloat(thickness),
          is2x4,
          is2x6,
          subCategory,
        })
      );
    })
    .filter(productVariant => {
      const { rValue, thickness, is2x4, is2x6, material } = productVariant;
      if (
        !rValue ||
        Number.isNaN(rValue) ||
        !thickness ||
        Number.isNaN(thickness) ||
        !material ||
        typeof material !== 'string' ||
        material.length === 0 ||
        (!is2x4 && !is2x6)
      ) {
        console.warn('Product missing required data!', { productVariant });
        return false;
      }
      return true;
    });
}

export function getInsulationProducts(localeCode = 'en-US') {
  return cmsApi
    .get_all({
      filter: {
        type: 'Cms::Content::Product::Insulation',
        language_iso_code: localeCode,
        metadata: {
          redirect: 'null',
          'settings.attributes.variants': '\x00',
          'settings.availability.status': ['active', 'external'],
          'settings.availability.visibility': 'listed',
        },
      },
      fields: {
        '*': 'name,metadata,route',
      },
      sort: 'name',
    })
    .then(flattenProducts);
}
