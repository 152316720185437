import { define, ui } from '@owenscorning/pcb.alpha';

// eslint-disable-next-line import/no-cycle
import Renderer from '../../../../OC/PageBuilder/SprayFoamVentilationCalculator';

export default define`Spray Foam Ventilation Calculator`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/RainbarrierComparisonTable`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    content: ui`Form`.of({
      copy: ui`Content/Basic`,
      footnotes: ui`Text/Rich`({ label: 'Footnotes' }),
      underResultsContent: ui`Text/Rich`({
        label: 'Results Content',
        default: {
          ops: [
            {
              attributes: { smaller: true },
              insert: '* Bending of exhaust line will reduce CFM output',
            },
            { attributes: { smaller: true }, insert: '\n\n' },
            {
              attributes: { bold: true },
              insert: 'Use of Filters: ',
            },
            {
              insert:
                'Use of filters on fan intakes is recommended and may reduce fan output.',
            },
            { attributes: { smaller: true }, insert: '\n\n' },
            {
              attributes: { bold: true },
              insert: 'Calculation Method: ',
            },
            {
              insert:
                'For further guidance and for calculation method, visit the ',
            },
            {
              attributes: {
                link:
                  'https://www.americanchemistry.com/industry-groups/spray-foam-coalition-sfc/resources/ventilation-considerations-for-spray-polyurethane-foam-guidance-on-ventilation-during-installation-of-interior-applications-of-high-pressure-spray',
                alt: 'ACC website',
              },
              insert: 'ACC website.',
            },
            { attributes: { smaller: true }, insert: '\n\n' },
            { attributes: { bold: true }, insert: 'Estimating Dimensions: ' },
            {
              insert:
                'Many rooms may have complicated layouts and ceiling constructs, which can make calculating dimensions more difficult. When estimating approximate dimensions, ensure values are estimated on the larger size.',
            },
            { attributes: { smaller: true }, insert: '\n\n' },
            {
              attributes: { bold: true },
              insert: 'Duration of Fan Operation: ',
            },
            {
              insert:
                'minimum of two hours post spraying; however, longer is better.',
            },
            { attributes: { smaller: true }, insert: '\n\n' },
            { attributes: { bold: true }, insert: 'Disclaimer: ' },
            {
              insert:
                'The estimated fan requirements are based on the dimensions entered into the calculator. The fan(s) should be appropriately sized for the workspace and strategically placed to ensure an even distribution of air and fume removal. It is the contractor’s responsibility to insert accurate dimensions and ensure adequate ventilation.',
            },
          ],
        },
      }),
    })({ label: 'Content' }),
    calculator: ui`Form`.of({
      laborRate: ui`Text`({ label: 'Default ACH' }),
    })({ label: 'Calculator Defaults' }),
  },
});
