import { Global, css } from '@emotion/react';
import { withRouter, BrowserRouter as Router } from 'react-router-dom';
import styled from '@emotion/styled';
import _ from 'lodash';
import Theme from '../../../themes';

import { connectLocation } from '../../location/LocationConnectors';
import parameterizeString from '../../../helpers/parameterize';
import ColumnList from './column-list';
import RichText from '../oc-rich-text';
import Text from '../oc-text';

const StyledLink = styled.a`
  background: ${Theme.colors.brand};
  border: 1px solid ${Theme.colors.brand};

  &:hover,
  &:focus {
    color: ${Theme.colors.brand};
  }
`;

const CtaItem = ({ item }) => (
  <div className="call-to-action">
    <StyledLink className="button oc-cta-button" href={ item.details?.url } data-track="nav-click">
      <Text content={ item.details?.title } />
    </StyledLink>
  </div>
);

const MobileCtaItem = ({ item }) => (
  <div className="mobile-ctas">
    <CtaItem item={ item } />
  </div>
);

const LinkItem = ({ item }) => {
  let useTitleOverride = item.details?.showTitleOverride;
  let titleOverride = item.details?.titleOverride;
  let title = item.details?.title;

  return (
    <a
      aria-label={title}
      className="nav-link"
      href={item.details?.url}
      data-track="nav-click"
      data-track-nav-select={title}
    >
      <RichText content={useTitleOverride ? titleOverride : title} />
    </a>
  );
};

const LinkDropdownItem = ({ item }) => {
  let useTitleOverride = item.details?.showTitleOverride;
  let titleOverride = item.details?.titleOverride;
  let title = item.details?.title;

  return (
    <div className="navbar-dropdown" data-track-top-level={title}>
      <div
        className="nav-dropdown-trigger"
        role="button"
        tabIndex="0"
        aria-expanded="false"
        aria-label={title}
      ></div>

      {item.details?.url ?
        <a
          className="nav-link-dropdown"
          href={item.details?.url}
          aria-label={title}
          data-track="nav-click"
          data-track-nav-select={title}
        >
          <div className="nav-dropdown__content" aria-hidden="true">
            <RichText content={useTitleOverride ? titleOverride : title} />
            <div className="hover-decal"></div>
          </div>
        </a>
        :
        <div className="nav-dropdown__content" aria-hidden="true">
          <RichText content={useTitleOverride ? titleOverride : title} />
          <div className="hover-decal"></div>
        </div>
      }

      <div className="dropdown-section" aria-hidden="true">
        <div className="nav-dropdown-content" role="menu">
          <ColumnList columns={ item.children } />
        </div>
      </div>
    </div>
  );
};

const DropdownItem = ({ item }) => {
  let useTitleOverride = item.details?.showTitleOverride;
  let titleOverride = item.details?.titleOverride;
  let title = item.details?.title;

  return (
    <div className="navbar-dropdown" data-track-top-level={title}>
      <div
        className="nav-dropdown-trigger"
        role="button"
        tabIndex="0"
        aria-expanded="false"
        aria-label={title}
      ></div>

      <div className="nav-dropdown__content" aria-hidden="true">
        <RichText content={useTitleOverride ? titleOverride : title} />
        <div className="hover-decal"></div>
      </div>

      <div className="dropdown-section" role="group">
        <div className="nav-dropdown-content">
          <ColumnList columns={ item.children } />
        </div>
      </div>
    </div>
  );
};

const SearchBar = withRouter(({ t, location }) => {
  const searchUrlMap = {
    'brandCenter': '/corporate/brand-center/search/results',
    'www.owenscorning.com': 'https://search.owenscorning.com/search/',
    'www.paroc.com': 'https://search.paroc.com/search/'
  };
  const isBrandCenter = location.pathname.startsWith('/corporate/brand-center');
  const meganavSearchUrl = isBrandCenter ? searchUrlMap['brandCenter'] : searchUrlMap[PB_SITE];
  const meganavSearchPlaceholder = isBrandCenter ? 'Search Brand Center' : t('nav.search');
  return (
    <div className="search-bar" role="search" aria-label="Site Search">
      <form action={ meganavSearchUrl } data-track="search-bar" data-track-search-term="">
        <div>
          <label className="sr-only" htmlFor="search">{ meganavSearchPlaceholder }</label>
          <input
            type="search"
            name="q"
            id="search"
            placeholder={ meganavSearchPlaceholder }
            value=""
          />
          <button className="search-button" type="submit" aria-label="Submit Search">
            <span aria-hidden="true" className="fa fa-search fa-md" />
          </button>
        </div>
      </form>
    </div>
  );
});

const MobileSearchBar = withRouter(({ t, location }) => {
  const searchUrlMap = {
    'brandCenter': '/corporate/brand-center/search/results',
    'www.owenscorning.com': 'https://search.owenscorning.com/search/',
    'www.paroc.com': 'https://search.paroc.com/search/'
  };
  const isBrandCenter = location.pathname.startsWith('/corporate/brand-center');
  const meganavSearchUrl = isBrandCenter ? searchUrlMap['brandCenter'] : searchUrlMap[PB_SITE];
  const meganavSearchPlaceholder = isBrandCenter ? 'Search Brand Center' : t('nav.search');
  return (
    <form className="search-bar__mobile" action={ meganavSearchUrl } data-track="search-bar" data-track-search-term="">
      <label className="sr-only" htmlFor="q">{ meganavSearchPlaceholder }</label>
      <input id="q" type="search" name="q" placeholder={ meganavSearchPlaceholder } value=""/>
      <button className="search-button" type="submit" aria-label="search submit">
        <span aria-hidden="true" className="fa fa-search fa-md" />
      </button>
    </form>
  );
});

export default connectLocation(({ t, locale: { code }, meganavItems, navFooterItems }) => {
  const mid = Math.ceil((navFooterItems || []).length / 2);
  const leftNavFooterItems = (navFooterItems || []).slice(0, mid);
  const rightNavFooterItems = (navFooterItems || []).slice(mid);

  let meganavClasses = ["oc-meganav"];
  let navLogoClasses = ["navbar-brand"];
  let mobileNavButtonClasses = ["mobile-nav__toggle"];
  let tenantClass = `tenant-${parameterizeString(PB_SITE_NAME)}`;
  [meganavClasses, navLogoClasses, mobileNavButtonClasses].forEach((array) => array.push(tenantClass));

  return (
    <Router>
      <Global
        styles={css`
          .image-parent {
            padding-bottom: 24px;
          }
          .image-parent:last-of-type {
            padding-bottom: 0;
          }
      `}
      />
      <div className={meganavClasses.join(" ")} aria-label="Main Navigation">
        <div className="nav-wrapper">
          <div className="desktop-nav">
            <div className="navbar navbar-default">
              <div className="nav-content" data-track-nav-loc="header">
                <div className="nav-content-container">
                  <div className="navbar-header">
                    <a
                      className={navLogoClasses.join(" ")}
                      href={code.toLowerCase()}
                      data-track="nav-click"
                      data-track-top-level="home"
                    >
                      {PB_SITE_NAME}
                    </a>
                  </div>
                  <nav className="dropdowns" role="navigation" aria-label="Main Navigation">
                    {meganavItems.map((item, i) => {
                      if (item.details?.cta) {
                        return <CtaItem key={ i } item={ item } />
                      } else if (!item.details?.url && item.children?.length >= 1) {
                        return <DropdownItem key={ i } item={ item } />
                      } else if (item.children?.length >= 1 ) {
                        return <LinkDropdownItem key={ i } item={ item } />
                      } else {
                        return <LinkItem key={ i } item={ item } />
                      }
                    })}
                  </nav>
                </div>
                {code !== 'en-CA' && code !== 'fr-CA' && <SearchBar t={ t } />}
              </div>
            </div>
          </div>
          <div className="mobile-nav" data-track-nav-loc="mobile header">
            <nav className="navbar navbar-default">
              <div className="nav-content" data-track="nav-click">
                <div className="navbar-header">
                  <a className={navLogoClasses.join(" ")} href={code.toLowerCase()}>{PB_SITE_NAME}</a>
                </div>

                <div className="navbar__right">
                  <div className="right__positioner">
                    {meganavItems.filter(item => item.details?.cta).map((item, i) => (
                      <MobileCtaItem key={ i } item={ item } />
                    ))}
                    <button data-track="menu-open" className={mobileNavButtonClasses.join(" ")}>
                      <span className="sr-only">Hamburger Menu</span>
                      <span id="hamburger__icon" aria-hidden="true" className="fa fa-bars fa-lg" />
                      <span className="sr-only">hamburger button</span>
                    </button>
                  </div>
                </div>
              </div>
            </nav>
            <div id="mobile-nav-panel" className="mobile-nav__panel faded-nav fade-in-exit-done" role="search" aria-label="Site Search">
              <div className="nav-border slide-panel secondary-panel-1 main-panel-enter-done">
                {code !== 'en-CA' && code !== 'fr-CA' && <MobileSearchBar t={ t } />}

                <div className="mobile-nav__list">
                  {meganavItems.filter(item => !item.details?.cta).map((item, index) => {
                    if (item.details?.url && _.isEmpty(item.children)) {
                      return (
                        <div key={ index } className="mobile-nav__list-item">
                          <a href={ item.details.url } data-track="nav-click">{ item.details.title }</a>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="mobile-nav__list-item"
                          data-structure="mobile-nav-list-item"
                          data-target={ item.details?.title }
                          data-track="nav-reveal"
                          data-track-top-level={ item.details?.title }
                          key={ index }
                          tabIndex="0"
                        >
                          <div className="list-item__content">{ item.details?.title }</div>
                          <div className="list-item__chevron"><span aria-hidden="true" className="fa fa-chevron-right fa-md" /></div>
                        </div>
                      );
                    }
                  })}
                </div>
                {navFooterItems?.length > 0 &&
                  <div className="mobile-nav__bottom-links">
                    {leftNavFooterItems &&
                      <div className="bottom-links__left-column">
                        {leftNavFooterItems.map((item, i) => (
                          <a key={ i } href={item.url}>{ item.title }</a>
                        ))}
                      </div>
                    }
                    {rightNavFooterItems &&
                      <div className="bottom-links__right-column">
                        {rightNavFooterItems.map((item, i) => (
                          <a key={ i } href={item.url}>{ item.title }</a>
                        ))}
                      </div>
                    }
                  </div>
                }
              </div>
              <div className="nav-border slide-panel secondary-panel-2 secondary-panel-exit-done">
                {meganavItems.filter(item => item.children).map((item, i) => (
                  <div key={ i } id={ item.details?.title } className="mobile-nav__active-selection">
                    {code !== 'en-CA' && code !== 'fr-CA' && <MobileSearchBar t={ t } />}

                    <div className="active-selection__back-button" tabIndex="0">
                      <span aria-hidden="true" className="fa fa-caret-left fa-md" />
                      <span>Back</span>
                    </div>

                    <div className="active-selection__header">
                      { item.details?.title }
                    </div>

                    <div className="active-selection__content">
                      <ColumnList columns={ item.children } mobile />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  )
});
