// Library Imports
import { useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import _ from 'lodash';

// Helper & Component Imports
import { anchor as stickyNavAnchor } from '../../../../../helpers/sticky_nav';
import { filterEntity, isItemVisible } from '../../../../../PageBuilder/helpers/content';
import { define, ui, when } from '@owenscorning/pcb.alpha';
import wrappedInRouter from '../../../../../../components/wrappedInRouter/index.jsx'
import AlertMessage from '../../../../../OC/PageBuilder/AlertMessage';
import DiscontinuedBanner from './DiscontinuedBanner';
import Text from '../../../../../OC/PageBuilder/utilities/Text.jsx';
import Link from '../../../../../OC/oc-link.jsx';
import CMSCta from '../../../../../OC/PageBuilder/Cta';
import useOCStickyNav from '../../../../../OC/hooks/use-oc-sticky-nav';
import { makeT } from "../../../../../location/locales";
import UnavailableProductsBanner from './UnavailableProductsBanner'
import { connectLocation } from '../../../../../location/LocationConnectors';

// Styled Components
const StickyNav = styled.div`
  margin-top: 0px !important;
`;

const StickyWrapper = styled.div`
  @media (min-width: 768px) {
    height: 95px;
    overflow: hidden;
    padding-left: 16px !important;
  }
`;

const StickyNavContainer = styled.div`
  ul {
    @media (min-width: 768px) {
      display: flex !important;

      li {
        display: inline-block;

        &.hovering * {
          color: ${ () => UI.Theme.colors.brand };
        }

        a {
          display: inline-block;
          position: relative;

          &:hover {
            color: ${ () => UI.Theme.colors.brand }!important;
          }
        }
      }
    }
  }
`;

const CtaGroup = styled.div`
  flex-shrink: 0;

  .oc-cta-container {
    padding-top: 0 !important;
  }
`;

const MobileNav = styled.div`
  li a {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: ${() => UI.Theme.colors.brand};
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const NonMobileNav = styled.ul`
  @media (max-width: 767px) {
    display: none;
  }
`;

const sectionHasModules = (obj) => !_.isEmpty(obj?.modules||[].filter(module => isItemVisible(module)));

const ctaDestinations = {
  link: true,
  modal: true,
  pricespider: true,
  share: true,
  document: true,
  zipFile: true,
}

const Renderer = connectLocation(wrappedInRouter(({ alertMessage = {}, cta, items, children }) => {
  const navRef = useRef();
  const wrapperRef = useRef();
  const containerRef = useRef();
  const { hovering, onClick } = useOCStickyNav({
    navRef,
    wrapperRef,
    containerRef,
    items,
  });

  // OCR-7948: PDP Discontinued Banner
  const isProduct = Board.build.moduleType === "Product"
  const discontinued = Board.settings.availability?.status === "discontinued";

  const isRoofingProduct = window.PB_TYPE === "Cms::Content::Product::Roofing";

  const availableByPbZone =
    !window.PB_ZONE || window.PB_ZONE === "all" ||
    Board.settings.availability?.zones === undefined ||
    Board.settings.availability?.zones.includes(window.PB_ZONE) ;

  // PB-568: PDP Reduced Availability Banner
  const reducedAvailability = Board.settings.availability?.status === "reduced";
  const reducedAvailabilityBanner = Board.settings.availability?.reducedBanner;
  const t = makeT(Board.build.language);

  const customCta = (
    cta?.destination === 'link'
      ? cta?.linkType === 'anchor'
        ? (onClick) => <CMSCta {...cta} fromStickyNav={true} onClick={(e) => onClick(e, cta?.url)} />
        : !!cta?.url && <CMSCta {...cta} fromStickyNav={true} />
      : ctaDestinations[cta?.destination] && <CMSCta {...cta} fromStickyNav={true} />
  );

  return (
    <>
      <StickyNav className="oc-stickynav" data-am-region="Sticky Nav" ref={navRef}>
        <StickyWrapper className="sticky-wrapper" ref={wrapperRef}>
          <StickyNavContainer className="stickynav-container" ref={containerRef}>
            {customCta &&
              <CtaGroup className="cta-group">
                {_.isFunction(customCta) ? customCta(onClick) : customCta}
              </CtaGroup>
            }

            <MobileNav className="mobile-stickynav">
              {items?.length > 0 &&
                <UI.Dropdown.Mobile title={ t('modules.header.page_menu') }>
                  {items.map(({ heading, anchor, tabName }) => {
                    if (!heading) return null;
                    return (
                      <li id={`menu-${anchor}`} className={anchor == hovering && 'hovering'} key={anchor}>
                        <Link
                          className="anchor"
                          data-track="tab-click"
                          data-track-tab-name={tabName || heading}
                          href={`#${anchor}`}
                          onClick={(e) => onClick(e, anchor)}
                          tabIndex="0"
                        >
                          <Text content={heading} />
                        </Link>
                      </li>
                    );
                  })}
                </UI.Dropdown.Mobile>
              }
            </MobileNav>

            <NonMobileNav id="stickynav-slider" className="stickynav-links" aria-hidden="false">
              {items.map(({ heading, anchor, tabName }) => {
                if (!heading) return null;
                return (
                  <li id={`menu-${anchor}`} className={anchor == hovering && 'hovering'} key={anchor}>
                    <Link
                      onClick={(e) => onClick(e, anchor)}
                      href={`#${ anchor }`}
                      data-track="tab-click"
                      data-track-tab-name={tabName || heading}
                      className="anchor"
                      tabIndex="0"
                    >
                      <Text content={heading} />
                    </Link>
                  </li>
                );
              })}
            </NonMobileNav>
          </StickyNavContainer>
        </StickyWrapper>
      </StickyNav>

      {children}

      {alertMessage.display &&
        <AlertMessage
          dismissable={alertMessage.dismissable}
          message={alertMessage.message}
          title={alertMessage.title}
          type={alertMessage.type}
        />
      }

      {isProduct && reducedAvailability &&
        <AlertMessage {...reducedAvailabilityBanner} type="warning" />
      }

      {isProduct && discontinued &&
        <DiscontinuedBanner />
      }

      {isProduct && isRoofingProduct && !availableByPbZone &&
        <UnavailableProductsBanner />
      }
    </>
  );
}))

export default define`Sticky Nav`('0.0.1')({
  styles: css`
    position: sticky;
    top: 0px;
    z-index: 999;
  `,
  gapless: true,
  gutterless: true,
  view: ({ value={} }) => <Renderer
    { ...value }
    items={
      filterEntity(
        _.map(
          _.get(Board.Value, 'contents.sections'),
          ({ sticky = { show: false }, details = {}, ...section } = { sticky: { show: false } }, index) => sectionHasModules(section) && sticky.show ? {
            ...section,
            heading: sticky.name,
            anchor: stickyNavAnchor(sticky.name || details.name || `sections/${ index }`),
            tabName: details.name
          } : false
        ).filter(Boolean),
        isItemVisible
      )
    }
  />,
  edit: {
    cta: ui`CTA`({ default: { url: '' } }),
    alertMessage: ui`Form`.of({
      display: ui`Switch`({
        default: false
      }),
      type: ui`Choices`.of({
        black: "Black",
        error: "Error",
        info: "Info",
        other: "Other",
        success: "Success",
        warning: "Warning",
      })({
        label: "Alert Type",
        default: "black",
        visible: when`../display`.is.equal.to(true)
      }),
      title: ui`Text`({
        label: "Title",
        visible: when`../display`.is.equal.to(true)
      }),
      message: ui`Text/Rich`({
        label: "Message Content",
        default: "This is a sticky alert message.",
        visible: when`../display`.is.equal.to(true)
      }),
      dismissable: ui`Switch`({
        label: "Dismissable",
        default: false,
        visible: when`../display`.is.equal.to(true)
      })
    })({
      label: 'Alert Message'
    }),
  }
});
