import { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import Theme from '../../../themes';
import moment from 'moment';

import { Loader } from '../../BuildYourRoof/loading';
import Table from '../../ComponentLibrary/oc-table';
import AlertMessage from '../../OC/oc-alert-message';
import Checkbox from './Checkbox';
import FormButton from '../../ComponentLibrary/input-elements/oc-form-button';

const StyledLoader = styled(Loader)`
  i {
    color: ${Theme.colors.brand};
  }
`;

const ResultsContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
`;

const StyledTR = styled.tr`
  th{
    padding: 8px;
    text-align: left;
  }

  td {
    padding: 8px 5px;
  }
`;

const CTAContainer = styled.div`
  display: inline-flex;
  gap: 16px;
  justify-content: flex-start;
`

const CompareContainer = styled.p`
  margin: 26px 0;
`;

const PreviousPublish = ({ lastPublish }) => {
  let message;
  if (!_.isEmpty(lastPublish))
    message = `Last publish was made by ${lastPublish.created_by_name} on ${moment(lastPublish.start_at).format('YYYY-MM-DD, h:mmA')}`;
  else
    message = "This content has not yet been published";
  return(
    <p>
      { message }
    </p>
  );
};

const PreviousVersions = ({ previousVersions }) => {
  if (previousVersions?.length > 0){
    return(
      <>
        <p>You'll also be publishing the changes below:</p>
        <PreviousVersionsTable previousVersions={previousVersions}/>
      </>
    );
  }

  return(
    <p>There are no new changes to publish.</p>
  );
};

const CompareVersions = ({ contentId, language }) => {
  return(
    <CompareContainer>
      <FormButton
        color="white"
        onClick={() => {
          // eslint-disable-next-line no-undef
          window.location.href = `/en-us/cms/contents/${PB_TYPE}/compare/${contentId}/${language}`;
        }}
      >
        Compare Versions
      </FormButton>
      <UI.Tip noTopMargin>
        Compare the current published version of your content with the draft you're working on.
      </UI.Tip>
    </CompareContainer>
  )
}

const PreviousVersionsTable =({ previousVersions = [] }) => {
  const maxTableItems = 10;
  const lastTenVersions = previousVersions.slice(-maxTableItems);
  const displayAlert = previousVersions.length > lastTenVersions.length;
  let alertMessage;
  if (displayAlert){
    alertMessage = `There are <strong>${previousVersions.length - maxTableItems}</strong>
                    other unpublished changes on this page history`;
  }
  return(
    <>
      <Table striped bordered>
        <thead>
          <StyledTR><th>Last Unpublished Changes</th></StyledTR>
        </thead>
        <tbody>
          { lastTenVersions.map(version => {
            return <StyledTR><td>{`${moment(version.start_at).format('YYYY-MM-DD, h:mmA')} - ${version.created_by_name}`}</td></StyledTR>
          })}
        </tbody>
      </Table>
      { displayAlert &&
        <p><AlertMessage dismissable={false} message={alertMessage} alertIcon type='other' title='Warning'/></p>
      }
    </>
  );
};

const PublishConfirmation = ({ contentId, language, onPublish }) => {
  const [loading, setLoading] = useState(true);
  const [versionHistory, setVersionHistory] = useState({});
  const [publishConfirm, setPublishConfirm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = `/api/v2/cms/content/${contentId}/${language}/version_history`;
      const data = await fetch(url).then(r => r.json());
      return data
    }

    fetchData().then(response => {
      setLoading(false);
      setVersionHistory(response.previous_versions);
    });
  }, []);

  return(
    <ResultsContainer>
      {loading ?
        <StyledLoader />
        :
        <>
          <PreviousPublish lastPublish={versionHistory.last_published}/>
          <PreviousVersions previousVersions={versionHistory.versions}/>
          { versionHistory.versions?.length > 0 && !_.isEmpty(versionHistory.last_published) &&
            <CompareVersions contentId={contentId} language={language}/>
          }
          <p>
            <Checkbox
              name='publish_confirm'
              value={publishConfirm}
              onChange={setPublishConfirm}
              label='I confirm that all changes to this page are approved and ready for go-live.'
            />
          </p>
          <CTAContainer>
            <FormButton disabled={!publishConfirm} onClick={onPublish}>
              Publish
            </FormButton>
            <FormButton color='white' onClick={Board.modal.close}>
              Cancel
            </FormButton>
          </CTAContainer>
        </>
      }
    </ResultsContainer>
  )
}

export default PublishConfirmation;
