import _ from 'lodash';
import styled from '@emotion/styled';
import { define, ui } from '@owenscorning/pcb.alpha';
import { useCallback, useEffect, useRef, useState } from 'react';

import { apiGet } from '../../PageBuilder/helpers/api';
import AjaxOverlay from '../../forms/ajax_overlay';
import stripBoardProps from "../props";

const StyledStatusIcon = styled.span`
  width: 30px;
  align-items: center;
  background-color: ${props => props.color};
  color: white;
  display: inline-flex;
  font-family: 'Oswald';
  font-size: 10px;
  line-height: 13px;
  padding: 0 2px;
  position: relative;

  svg {
    display: inline-block;
    margin-left: 2px;
  }
`;

const getStatusColor = status => {
  if (status >= 400 || status < 0) {
    return '#e74c3c';
  }
  if (status >= 300 && status <= 399) {
    return '#f1c40f';
  }
  return '#07bc0c';
};

const getStatusIcon = status => {
  if (status >= 400 || status < 0) {
    return 'times-circle';
  }
  if (status >= 300 && status <= 399) {
    return 'share';
  }
  return 'check-circle';
};

const getStatusText = state => {
  if (state.status < 0) {
    return 'ERR';
  }
  return state.status.toString();
};

const getStatusTitle = state => {
  if (state.status < 0) {
    return state.reason;
  }
  return null;
};

const StatusIconLink = ({ className, urlState }) => (
  <StyledStatusIcon
    className={className}
    title={getStatusTitle(urlState)}
    color={getStatusColor(urlState.status)}
  >
    {getStatusText(urlState)} <UI.Icon type={getStatusIcon(urlState.status)} />
  </StyledStatusIcon>
);

const StatusIcon = styled(StatusIconLink)`
  margin-right: 8px;
`;

const UrlStateRecord = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const fixUrl = val => {
  val.trim();
  val = val.replace(/^htp:\/\/?(\w)/, 'http://$1');
  val = val.replace(/^http\/\/?(\w)/, 'http://$1');
  val = val.replace(/^htps:\/\/?(\w)/, 'https://$1');
  val = val.replace(/^https\/\/?(\w)/, 'https://$1');
  if (!!val && !/:\/\//.test(val) && !/^https?:\/\//.test(val)) {
    val = `https://${val}`;
  }
  return val;
};

const UrlState = ({ urlState }) => (
  <UrlStateRecord title={urlState.url}>
    <StatusIcon urlState={urlState} />
    {urlState.url}
  </UrlStateRecord>
);

const UrlStateList = ({ urlState }) => (
  <div>
    {(urlState || []).map(url => (
      <UrlState key={url.url} urlState={url} />
    ))}
  </div>
);

export default define`Url`('0.0.1')({
  edit: ({ onChange, validate, detailed = false, external = true, ...props }) => {
    const [urlState, setUrlState] = useState();
    const [loadingUrlState, setLoadingUrlState] = useState();
    const setTimerRef = useRef();
    const checkUrl = val => {
      val = fixUrl(val);
      setLoadingUrlState(true);
      apiGet(
        `/api/v1/cms/sites/${PB_SITE}/url-check?url=${encodeURIComponent(
          val
        )}&external=${external}`
      ).then(response => {
        setLoadingUrlState(false);
        setUrlState(response);
        const detailedResult = {
          ..._.last(response),
          url: val,
        };
        val !== props.value ? onChange(detailed ? detailedResult : val) : null;
      });
    };
    const onChangeLocal = useCallback(
      val => {
        if (setTimerRef.current) {
          clearTimeout(setTimerRef.current);
          setTimerRef.current = null;
        }
        setTimerRef.current = setTimeout(() => {
          checkUrl(val);
        }, 1500);
      },
      [onChange]
    );

    useEffect(() => {
      if (props.value) {
        checkUrl(props.value)
      }
    }, []);

    return (
      <>
        <Subschema>
          {[
            ui`Text`,
            {
              onChange: validate ? onChangeLocal: onChange,
              ...stripBoardProps(props),
            },
          ]}
        </Subschema>
        {validate && (
          <AjaxOverlay fetching={loadingUrlState}>
            <UrlStateList urlState={urlState} />
          </AjaxOverlay>
        )}
      </>
    );
  },
  view: <div />,
});
