import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../themes/index';
import Picture from './oc-picture';
import Text from './PageBuilder/utilities/Text';

// Utilities
import { is_placeholder_url } from '../../placeholder';

const FullBleedContentContainer = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: 1200px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => Picture.Transform(props.background)});
    background-position: center center;
    width: 100%;
    max-width: 1340px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${props => (props.height === 'tall' ? '600px' : '440px')};
  }

  // Workaround for non-Page Builder pages which currently use .page wrapper div
  // We should probably try to stop using .page
  .page & {
    width: auto;
    margin-left: ${props => (props.noMargin ? 'auto' : '-15px')};
    margin-right: ${props => (props.noMargin ? 'auto' : '-15px')};

    @media (min-width: 1200px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Content = styled.div`
  background: white;
  padding: 15px;

  @media (min-width: 768px) {
  }

  @media (min-width: 1200px) {
    width: max(calc(40% - 85px), 700px);
    max-height: 80%;
    overflow: hidden;
    order: ${props => (props.contentSide === 'right' ? '1' : '0')};
    margin: ${props =>
      props.contentSide === 'right' ? '0 85px 0 0' : '0 0 0 85px'};
    padding: 30px;
  }
`;

const MyPicture = styled(Picture)`
  @media (min-width: 1200px) {
    display: none;
  }

  width: 100%;
  max-width: 100%;
`;

const HotSpotContainer = styled.div`
  /* height: 100%; */
  order: ${props => (props.contentSide === 'right' ? '0' : '1')};
  /* margin: 0 20px; */
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  width: 100%;
  position: relative;

  @media (max-width: 767px) {
    margin-bottom: 25px;
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1200px) {
    position: relative;
    width: 50%;
    height: 100%;
  }
`;

const HotSpotsWrapper = styled.div`
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: calc(100% - 4px);
    transform: translateY(-50%);
    left: 18px;
    right: 18px;
  }
`;

const HotSpot = styled.div`
  background: white;
  padding: 8px;
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  position: absolute;

  @media (max-width: 767px) {
    position: relative;
    min-width: 25%;
    max-width: 33%;
  }

  @media (min-width: 768px) {
    min-width: 120px;
    max-width: 220px;
    padding: 16px;

    ${props =>
      props.row == '1' &&
      css`
        top: 37px;
      `}

    ${props =>
      props.row == '2' &&
      css`
        top: calc(33% + 24px);
      `}

    ${props =>
      props.row == '3' &&
      css`
        bottom: 31px;
      `}

    &:first-child {
      left: 40px;
    }

    &:nth-child(2) {
      left: 33%;
    }

    &:nth-child(3) {
      left: 66%;
    }
  }

  &:before {
    content: '';
    background: ${Theme.colors.brand};
    display: block;
    position: absolute;
    height: 16px;
    width: 16px;
    top: -8px;
    left: -8px;
    border-radius: 16px;

    @media (min-width: 768px) {
      height: 24px;
      width: 24px;
      top: -12px;
      left: -12px;
      border-radius: 24px;
    }
  }
`;

const PrimaryText = styled.div`
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: right;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 20px;
  }

  html[lang='fr-CA'] &,
  html[lang='fr'] &,
  html[lang='pt'] &,
  html[lang='pt-BR'] &,
  html[lang='es'] &,
  html[lang='fn'] &,
  html[lang='ru'] &,
  html[lang='de'] &,
  html[lang='sv'] & {
    font-size: 12px;
    line-height: 15px;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 20px;
    }
  }
`;

const PinkText = styled.span`
  display: block;
  color: ${Theme.colors.brand};
`;

const DisclaimerText = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-align: right;

  @media (min-width: 1200px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

const tabletHeights = {
  tall: 343,
  short: 252,
};

const mobileHeights = {
  tall: 161,
  short: 118,
};

/*
  This component uses both a background image and an inline <picture> element
  so that the desktop theme can display hotspots and other content on top of
  the image, while tablet and mobile have that content adjacent to the image,
  permitting the use of an inline image.
*/
const FullBleedContent = ({
  img,
  imgAlt,
  height = 'tall',
  children,
  contentSide = 'right',
  noMargin = false,
  hotSpots,
  aspectRatio
}) => {

  if (aspectRatio) { aspectRatio['custom'] = aspectRatio['custom']?.toString(); }

  if (img?.file && !is_placeholder_url(img.file) && img?.crop) {
    img = Picture.Transform(img.file, { crop: "crop", ...img.crop })
  } else {
    img = img?.file
  }

  return (
    <FullBleedContentContainer
      background={img}
      height={height}
      noMargin={noMargin}
    >
      <HotSpotContainer contentSide={contentSide} height={height}>
        <HotSpotsWrapper>
          {hotSpots &&
            hotSpots.map((hotspot, i) => (
              <HotSpot
                className={`.row${hotspot.row}`}
                row={hotspot.row}
                col={i + 1}
              >
                <div>
                  <PrimaryText>
                    <Text content={hotspot.primaryText} />
                    {hotspot.pinkText && (
                      <PinkText>
                        <Text content={hotspot.pinkText} />
                      </PinkText>
                    )}
                  </PrimaryText>
                  <DisclaimerText>
                    <Text content={hotspot.disclaimerText} />
                  </DisclaimerText>
                </div>
              </HotSpot>
            ))}
        </HotSpotsWrapper>

        <MyPicture
          source={img}
          blankImage
          transform={{
            crop: 'fill',
            quality: 40,
          }}
          aspectRatio={ aspectRatio }
          variants={[
            {
              media: '(max-width: 767px)',
              width: 720,
              quality: 40,
            },
            {
              media: '(max-width: 1199px)',
              width: 768,
              quality: 50,
            },
            {
              media: '(max-width: 1199px) and (min-resolution: 2dppx)',
              width: 1536,
              quality: 30,
            },
          ]}
          alt={imgAlt}
        />
      </HotSpotContainer>
      <Content contentSide={contentSide}>{children}</Content>
    </FullBleedContentContainer>
  );
};

export default FullBleedContent;
