import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Provider } from 'react-redux';

import OtherPairing from './OtherPairing';
import { RoofingPreview } from './POCHeroSection/RoofingPreview';
import { RoofingShingles } from './POCHeroSection/RoofingShingles';
import PorchVignette from './PorchVignette';
import useReference from '../../../hooks/use_reference';
import connectStyleBoard from '../../../redux/connectors/StyleBoardConnector';
import CTAButton from '../../CTAButton';
import Grid from '../../OC/PageBuilder/Grid';
import Reusable from '../../OC/PageBuilder/Reusable';
import SocialMediaShareBanner from '../../OC/oc-social-media-share-banner';
import { store } from '../../Store';
import { PrefixWithLocale } from '../../Utilities';
import { connectLocation } from '../../location/LocationConnectors';

const colorPairingCSS = css`
  .title {
    margin-bottom: 32px;
  }

  .other-shingle-colors {
    font-family: 'OCRoboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    text-transform: none !important;
  }

  .about-section {
    display: flex;
    margin-top: 64px;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    primary .shingle-img-container {
      height: 162px !important;
    }
    .columns.mobile-columns,
    .primary-color-vertical-container,
    .secondary-color-vertical-container {
      height: 100% !important;
    }
    .secondary-color-top-vertical-container,
    .secondary-color-middle-vertical-container {
      height: 30% !important;
    }
    .secondary-color-bottom-vertical-container {
      height: 31% !important;
    }
  }
  @media (max-width: 767px) {
    .shingle-img-container {
      height: 400px !important;
    }
    .columns.mobile-columns,
    .primary-color-vertical-container,
    .secondary-color-vertical-container {
      height: 100% !important;
    }
    .secondary-color-top-vertical-container,
    .secondary-color-middle-vertical-container,
    .secondary-color-bottom-vertical-container {
      height: 32% !important;
    }
    .tier3 {
      padding-top: 40px;
    }
    .about-section {
      flex-direction: column-reverse;
    }
  }
  .shingle-img {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .reference-guide-img-container {
    margin: 32px 0;
  }

  .reference-guide-info-container {
    padding-left: 16px;
  }

  .see-on-my-house-btn {
    margin-top: 16px;
  }

  .hr-line {
    display: block;
    margin: 60px 0;
  }

  .explore-all-styles-btn {
    margin-bottom: 64px;
  }

  .other-pairings-container {
    margin: 0px;
  }

  .other-shingle-heigh {
    height: 100%;
  }

  .shingle-name-prehead {
    margin: 8px 0;
    > span {
      padding-right: 4px;
    }
  }

  .shingle-name-heading {
    margin-bottom: 0px;
  }
`;

const FullSection = styled.section`
  margin-left: 0;
  margin-right: 0;
  max-width: none;
`;

const CTAContainer = styled.div`
  display: inline-block;
  padding-top: 1.5em;
  position: relative;
  width: 100%;
`;

const exploreAllStyleboardsLink = ({ localeCode }) => {
  if (localeCode === 'en-CA' || localeCode === 'fr-CA') {
    return PrefixWithLocale('/roofing/design-inspire', localeCode);
  }

  return PrefixWithLocale(
    '/roofing/design-inspire/colors-of-the-season',
    localeCode
  );
};

const ConnectedColorPairing = connectStyleBoard(
  connectLocation(
    ({
      t,
      colorName,
      colorPalette,
      title,
      description,
      exampleImg,
      file,
      heroImage,
      locale: { code: localeCode },
      otherPairings,
      referenceGuide,
      otherShingleColors,
      shingleColor,
      subtitle,
      colorSelected,
    }) => {
      const mainShingle = {
        ...(useReference(shingleColor?.color) || {}),
      };
      const selectedShingleColor = colorSelected || mainShingle;

      const filteredOtherPairings = [
        otherPairings?.one,
        otherPairings?.two,
      ].filter(x => x);

      return (
        <>
          <section css={colorPairingCSS}>
            <RoofingPreview
              selectedShingle={selectedShingleColor}
              heroImage={heroImage}
              subtitle={subtitle}
              colorName={colorName}
            />
            <RoofingShingles
              mainShingleColor={shingleColor?.color}
              shingles={[shingleColor, ...otherShingleColors]}
            />
            <SocialMediaShareBanner
              text={t('design_inspire.share_this_pairing')}
              mode="dark"
              facebook="true"
              linkedin="true"
              pinterest="true"
              shareMessage="Check out this awesome MoodBoard featuring @OCRoofingLLC #Duration Designer roofing shingles!"
            />
            <PorchVignette
              title={title}
              description={description}
              colorPalette={colorPalette}
              colorName={colorName}
              mainShingle={mainShingle}
              shingleSelected={selectedShingleColor}
              porchSection={exampleImg}
              file={file}
              referenceGuide={referenceGuide}
            />
            {filteredOtherPairings.length > 0 && (
              <>
                <hr className="hr-line" />
                <div className="other-pairings-container">
                  <h1 className="tier3">
                    {t('design_inspire.other_color_pairings')}
                  </h1>
                  <p>{t('design_inspire.other_color_pairings_desc')}</p>
                </div>

                <Grid selectedSize="2">
                  {filteredOtherPairings.map((pairing, i) => (
                    <OtherPairing key={i} pairing={pairing} />
                  ))}
                </Grid>

                <CTAContainer>
                  <CTAButton href={exploreAllStyleboardsLink({ localeCode })}>
                    {t('design_inspire.explore_all_styleboards')}
                  </CTAButton>
                </CTAContainer>

                <hr className="hr-line" />

                <Reusable
                  language={localeCode}
                  name="Build Your Own Style Board"
                  path="roofing"
                />
              </>
            )}
          </section>

          <FullSection>
            <Reusable
              language={localeCode}
              name="Color Samples Promo Block"
              path="roofing"
            />
          </FullSection>

          <Reusable
            language={localeCode}
            name="Color Disclaimer"
            path="roofing"
          />
        </>
      );
    }
  )
);
const ColorPairing = props => (
  <Provider store={store}>
    <ConnectedColorPairing {...props} />
  </Provider>
);

export default ColorPairing;
