// Library Imports
import { useContext, useState, React, forwardRef } from 'react';
import { ReactReduxContext } from 'react-redux';
import { ui } from '@owenscorning/pcb.alpha';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import _ from 'lodash';

// Asset Imports
import IconZipFile from '../../ComponentLibrary/icons/icon-zip-file';

// Component Imports
import { openLocationModal } from '../../location/LocationConnectors';
import Cta from '../oc-cta';
import Data from '../../Builder/Data';
import Form from './Form';
import PriceSpider from '../../PriceSpider';
import Text from "./utilities/Text";

// Helper Imports
import Cookies from '../../PageBuilder/helpers/cookies';
import Linking from '../../PageBuilder/helpers/linking';
import getFormatFromMime from '../../helpers/get_format_from_mime';
import pathToContent from '../../Builder/Data/Providers/helpers/path_to_content';

// Styled Components
const WrapSpan = styled.span`
  i {
    margin-right: 10px;
  }
`;

const WrapParagraph = styled.p`
  align-items: center;
  display: inline-flex;
  gap: 8px;
  margin: 0;
  text-align: center;

  svg {
    display: inline-block;
    height: 100%;
    width: 16px;
  }

  span.zip-details {
    font-size: 14px;
    font-weight: 300;
  }
`;

const FILETYPES = {
  document: 'word',
  doc: 'word',
  docx: 'word',
  xls: 'excel',
  xlsx: 'excel',
  zip: 'zip',
  pdf: 'pdf',
}

const clickAction = (onClick, destination, modal, form, modalSource, reusable,  shareLink, shareTitle) => {
  if (onClick) {
    return onClick
  } else if (destination === 'modal' && !form) {
    form = {
      'PinkNextGenSampleRequest': {
        form_uid: 'next_gen_sample2'
      },
      'VidawoolEmailCollection': {
        form_uid: 'vidawool_email_collection'
      }
    }[modal]
  }
  if (destination === 'modal' && modalSource === 'form') {
    return () => Board.modal.open({
      body: <Form { ...form } />
    })
  }
  if (destination === 'locationModal') {
    const { store } = useContext(ReactReduxContext)
    return () => {
      store.dispatch(openLocationModal())
    }
  }
  if (destination === 'modal' && modalSource === 'reusable') {
    return () => Board.modal.open({
      ...(reusable.title && { title: reusable.title }),
      body: ui`Modules/Page/Reusable|view`({ value: reusable.reusable, gutterlessChild: true })
    })
  }
  if (destination === 'share') {
    return () => {
      if (navigator.share) {
        navigator.share({
          title: shareTitle,
          url: shareLink
        }).then(() => {
          console.log('Successful share');
        })
        .catch(error => {
          console.log('Error sharing:', error);
        });
      } else {
        Board.modal.open({
          body: ui`Modules/Page/Reusable|view`({ value: { name: 'ShareBanner' }, gutterlessChild: true })
        })
      }
    }
  }
}

const Wrapper = ({ enabled, spaced, centered, children, styles }) => {
  if (!enabled) return (children);

  let classNames = ["oc-cta-container"];
  if (centered) classNames.push("center");

  let paddingTop = spaced ? '32px' : '10px';
  if (styles && styles?.paddingTop) paddingTop = styles.paddingTop;

  return (
    <div className={classNames.join(" ")?.trim()} css={css`padding-top: ${paddingTop};`}>
      {children}
    </div>
  );
}

const getDocAttributes = (dataset, doc) => {
  if (dataset === 'documents') {
    return doc || {}
  } else {
    return doc ? {
      url: pathToContent(doc),
      mime_type: doc.contents?.type === "upload" ? doc.contents?.document?.mime_type : null
    } : {}
  }
}

const CMSCta = ({ destination, url, pricespider_upc, modal, shareLink, shareTitle, form, text, color, centered, spaced, analytics, standalone, modalSource, reusable, document, forwardRef, styles, fromStickyNav = false, onClick= null, ...props }) => {
  const link = Linking.Parse(url);
  if (link.url.includes('roofing/contractors') && Cookies.isReferred()) return null;

  if (destination === 'pricespider') {
    return (
      <Wrapper enabled={ !standalone } spaced={ spaced } centered={ centered } styles={styles}>
        <PriceSpider pricespider_upc={ pricespider_upc } />
      </Wrapper>
    );
  }
  const [doc, setDoc] = useState();

  if (destination === 'document' && document?.parameters) {
    const adapter = Data.for('CtaDocumentSet')[document.dataset];
    if (adapter) {
      adapter.old_view(document?.parameters).then(result => setDoc(result));
    }
    const { url = '', source, publication_id, mime_type, analytics } = getDocAttributes(document.dataset, doc);
    const docUrl = source == 'dms2' ? `https://www.owenscorning.com/dms/${encodeURIComponent(publication_id)}` : url

    return (
      <Wrapper enabled={!standalone} spaced={spaced} centered={centered} styles={styles}>
        <Cta
          href={docUrl}
          color={color}
          analytics={analytics || { 'element-location': 'CTA document' }}
          target="_blank"
          forwardRef={forwardRef}
        >
          <WrapSpan>
            <i class={`fa ${FILETYPES?.[getFormatFromMime(mime_type)] ? `fa-file-${FILETYPES?.[getFormatFromMime(mime_type)]}-o` : 'fa-file-o'} icon`}></i>
            <span>
              <Text content={text} />
            </span>
          </WrapSpan>
        </Cta>
      </Wrapper>
    );
  }

  if (destination === 'zipFile') {
    const zipLink = Board.Value?.zipFile?.url;
    let zipSize = 0.0;
    if (Board.Value?.zipFile?.size > 0) {
      zipSize = (Board.Value.zipFile.size / (1024*1024)).toFixed(2);
    }
    const zipText = ` ZIP | ${zipSize}MB`;
    const properName = Board.Value?.metadata?.settings?.general?.proper_name || `${Board.build.type} - ${Board.build.name}`;

    return (
      <Wrapper enabled={ !standalone } spaced={ spaced } centered={ centered } styles={styles}>
        <Cta
          href={zipLink}
          color={color}
          forwardRef={forwardRef}
          data-track="file-download"
          data-track-doc-type="Literature"
          data-track-file-type="zip"
          data-track-file-name={properName}
        >
          <WrapParagraph>
            {!fromStickyNav && <IconZipFile/>}
            <Text content={text} />
            {!fromStickyNav &&
              <span className='zip-details'>
                {zipText}
              </span>
            }
          </WrapParagraph>
        </Cta>
      </Wrapper>
    )
  }

  return (!destination || !_.isEmpty({ link: url, modal: form || modal, pricespider: pricespider_upc }[destination]) || !_.isEmpty(reusable) || !_.isEmpty(shareLink)) &&
    <Wrapper enabled={ !standalone } spaced={ spaced } centered={ centered } styles={styles}>
      <Cta
        href={ (!destination || destination === 'link') && link.url }
        onClick={ clickAction(onClick, destination, modal, form, modalSource, reusable, shareLink, shareTitle) }
        color={ color }
        analytics={ analytics || link.analytics || { 'element-location': 'main section CTA' } }
        forwardRef={forwardRef}
        formUid={form?.form_uid}
        target={ link.opensInBlank ? '_blank' : undefined }
      >
        <span><Text content={ text } /></span>
      </Cta>
    </Wrapper>
};

export default CMSCta;
