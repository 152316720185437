import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { BasicContent } from './BasicContent';
import RichText from './RichText';
import SprayFoamVentilationCalculator from '../../composites/SprayFoamVentilation/SprayFoamVentilationCalculator';

const CMSSprayFoamVentilationCalculator = ({ content, calculator }) => (
  <>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <BasicContent {...content.copy} />
    <SprayFoamVentilationCalculator
      laborRate={calculator.laborRate}
      underResultsContent={content.underResultsContent}
    />
    <RichText content={content.footnotes} />
  </>
);

CMSSprayFoamVentilationCalculator.propTypes = {
  content: PropTypes.shape({
    copy: PropTypes.shape({
      ops: PropTypes.arrayOf(
        PropTypes.shape({
          insert: PropTypes.string,
        })
      ),
    }),
    footnotes: PropTypes.shape({
      ops: PropTypes.arrayOf(
        PropTypes.shape({
          insert: PropTypes.string,
        })
      ),
    }),
    underResultsContent: PropTypes.shape({
      ops: PropTypes.arrayOf(
        PropTypes.shape({
          insert: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  calculator: PropTypes.shape({
    laborRate: PropTypes.number,
  }).isRequired,
};

export default CMSSprayFoamVentilationCalculator;
