/* eslint-disable no-param-reassign */
function transformErrorsInner({ errors, schema, uiSchema, t }) {
  // note: custom validation errors are appended AFTER this function runs, so we won't see them here.
  return errors.map(error => {
    const propertyPath = error.property.replace(/^./, ''); // Remove the leading dot
    const schemaProperty = getPropertyFromSchema(schema, propertyPath);
    let { name: errorType, params } = error;
    if (['const', 'oneOf'].includes(errorType)) {
      // some of these error types are because of conditionals we need to suppress unless we specify a message
      // directly in schema because they are misleading to user like ".field should match exactly one schema in oneOf"
      // in schema we can put a message that makes sense like:
      // messages: {
      //   oneOf: "You must select a reason or type a reason if selecting 'Other'"
      // }
      error.message =
        schema?.messages?.[errorType] || schemaProperty?.messages?.[errorType];
    } else {
      // special case for empty string "required"
      // a message like "should NOT be shorter than 1 characters" is awkward
      if (errorType === 'minLength' && error.params?.limit === 1) {
        errorType = 'required';
        params = { missingProperty: error.property.slice(1) };
      }
      error.message =
        schema?.messages?.[errorType] ||
        schemaProperty?.messages?.[errorType] ||
        t(`errors.forms.${errorType}`, params) ||
        error.message;
    }
    return error;
  });
}

// Function to get the property schema by path from the schema object
function getPropertyFromSchema(schema, path) {
  const keys = path.split('.');
  let current = schema;

  for (let key of keys) {
    if (current.properties && current.properties[key]) {
      current = current.properties[key];
    } else {
      return null; // Path doesn't exist in schema
    }
  }

  return current;
}

// RJSF only passes errors and uiSchema, so we'll have to closure t and schema into a callback.
export function makeErrorTransformer(t, schema) {
  return function transformErrors(errors, uiSchema) {
    return transformErrorsInner({ errors, schema, uiSchema, t });
  };
}
